/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCreators, beforeUser } from './../user/user.action';
import { ENV } from './../../config/config';
import FullPageLoader from '../full-page-loader/full-page-loader'
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Authors = (props) => {
    const [pagination, setPagination] = useState(null)
    const [creators, setCreators] = useState([])
    const [loader, setLoader] = useState(true)
    const [loadMoreBtn, setLoadMoreBtn] = useState(false)
    const [searchCheck, setSearchCheck] = useState(false)
    const [txtCheck, setTxtCheck] = useState(false)

    useEffect(() => {
        window.scroll(0, 0)
        setCreators([])
        // get creators
        getCreators()
        document.querySelectorAll('.nav-link').forEach((item) => {
            item.classList.remove('activeNav')
        })
        document.querySelector(`#link-navv-3`).classList.add('activeNav')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // set creators & pagination
    useEffect(() => {
        if (props.user.creatorsAuth) {
            const creatorsData = props.user.creators
            if (creatorsData) {
                if (searchCheck) {
                    setCreators(uniqueByKey(creatorsData.creators, '_id'))
                    setSearchCheck(false)
                }
                else {
                    setCreators(uniqueByKey(creators.concat(creatorsData.creators), '_id'))
                }
                setPagination(creatorsData.pagination)
                props.beforeUser()
                setLoader(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.creatorsAuth])

    // handle show load more button state when pagination is set
    useEffect(() => {
        if (pagination)
            setLoadMoreBtn((creators && pagination.total > 0 && pagination.total > creators.length && creators.length > 0))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    const loadMore = () => {
        const { page } = pagination

        // get more creators
        getCreators(page + 1)
    }

    const uniqueByKey = (array, key) => {
        return [...new Map(array.map((x) => [x[key], x])).values()];
    }

    const getCreators = (page = 1, limit = 12, txt = '') => {
        setLoader(true)
        let creatorQS = { page, limit }
        if (txt) {
            setSearchCheck(true)
            setTxtCheck(true)
            creatorQS = { name: txt, ...creatorQS }
        }
        const qs = ENV.objectToQueryString(creatorQS)
        props.getCreators(qs)
    }

    const search = (txt) => {
        if (txt) {
            setCreators([])
            getCreators(1, 12, txt)
        }
    }

    const clearSearch = () => {
        setCreators([])
        getCreators(1, 12)
        document.querySelector('#search-creator').value = ''
        setTxtCheck(false)
    }

    return (
        <section className="popular-collections-area">
            {loader && <FullPageLoader />}
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        <div className="intro text-center">
                            <h3 className="mt-3 mb-0">Our Creators</h3>
                        </div>
                    </div>
                </div>
                <div className='text-start'>
                    <div className="search-box mb-4">
                        <form onSubmit={(e) => { e.preventDefault(); search(e.target[0].value) }}>
                            <input type="text" placeholder="Search Creators By Name" className="search-box" name="search" id="search-creator" />
                            <a onClick={() => { search(document.querySelector('#search-creator').value) }}>
                                <span className="icon fa fa-search"></span>
                            </a>
                        </form>
                    </div>
                </div>
                {
                    txtCheck ?
                        <div><button className='btn btn-danger' onClick={clearSearch}>Clear Search</button></div>
                        : ''
                }
                <div className="row items">
                    {
                        creators && creators.length ?
                            creators.map((author, key) => {
                                return (
                                    <div key={`author_${key}`} className="col-12 col-sm-6 col-lg-3 item">
                                        <div className="card no-hover text-center">
                                            <div className="image-over">
                                                <Link to={`/author/${author._id}`}>
                                                    <img className="card-img-top" src={author.profileImageLink ? ENV.webUrl + author.profileImageLink : ENV.globalPlaceholderImage} alt="Author profile" />
                                                </Link>
                                            </div>
                                            <div className="card-caption col-12 p-0">
                                                <div className="card-body">
                                                    <Link to={`/author/${author._id}`}>
                                                        <h5 className='d-flex'>
                                                            {author.username ? author.username : author.address}
                                                            {
                                                                author.verificationCheck ?
                                                                    <>
                                                                        <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                            <FontAwesomeIcon icon={faCheck} />
                                                                        </span>
                                                                        <ReactTooltip />
                                                                    </>
                                                                    : ''
                                                            }
                                                        </h5>
                                                    </Link>
                                                    <div className="social-icons d-flex justify-content-center mb-0">
                                                        {
                                                            author.facebookLink && <a className="facebook" href={author.facebookLink} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Facebook">
                                                                <i className="fab fa-facebook-f" />
                                                            </a>
                                                        }
                                                        {
                                                            author.twitterLink && <a className="twitter" href={author.twitterLink} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Twitter">
                                                                <i className="fab fa-twitter" />
                                                            </a>
                                                        }
                                                        {
                                                            author.youTubeLink && <a className="youtube" href={author.youTubeLink} target="_blank" rel="noreferrer" data-toggle="tooltip" title="YouTube">
                                                                <i className="fab fa-youtube" />
                                                            </a>
                                                        }
                                                        {
                                                            author.tiktokLink && <a className="tiktok" href={author.tiktokLink} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Tiktok">
                                                                <i className="fab fa-tiktok" />
                                                            </a>
                                                        }
                                                        {
                                                            author.instagramLink && <a className="instagram" href={author.instagramLink} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Instagram">
                                                                <i className="fab fa-instagram" />
                                                            </a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                            :
                            <div className="no-data border"><p className="text-center">No Creators Found</p></div>
                    }
                </div>
                {
                    loadMoreBtn &&
                    <div className="row">
                        <div className="col-12 text-center">
                            <span id="load-btn" className="btn banner-btn mt-5" onClick={() => loadMore()} href>Load More</span>
                        </div>
                    </div>
                }
            </div>
        </section>
    );
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, { beforeUser, getCreators })(Authors);