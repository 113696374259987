import {
  BEFORE_ACTIVITY,
  GET_ACTIVITIES,
  GET_LEADERBOARD,
  BEFORE_LEADERBOARD,
  REFRESH_ACTIVITIES,
} from "../../redux/types";

const initialState = {
  activities: null,
  activitiesAuth: false,
  leadersboard: null,
  leadersboardAuth: false,
  refreshActivities: null,
  refreshActivitiesAuth: false,
};

export default function activityRed(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVITIES:
      return {
        ...state,
        activities: action.payload,
        activitiesAuth: true,
      };
    case BEFORE_ACTIVITY:
      return {
        ...state,
        activities: null,
        activitiesAuth: false,
        refreshActivities: null,
        refreshActivitiesAuth: false
      };
    case GET_LEADERBOARD:
      return {
        ...state,
        leadersboard: action.payload,
        leadersboardAuth: true,
      };
    case REFRESH_ACTIVITIES:
      return {
        ...state,
        refreshActivities: action.payload,
        refreshActivitiesAuth: true,
      };
    case BEFORE_LEADERBOARD:
      return {
        ...state,
        leadersboard: null,
        leadersboardAuth: false,
      };
    default:
      return {
        ...state,
      };
  }
}
