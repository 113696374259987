import { GET_ARTISTS, BEFORE_ARTIST, GET_ARTIST, GET_ERRORS } from '../../../redux/types';
import { emptyError } from '../../../redux/shared/error/error.action';
import { ENV } from '../../../config/config';


export const beforeArtist = () => {
    return {
        type: BEFORE_ARTIST
    }
}

export const getArtists = (qs = null) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}artist/list`;
    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_ARTISTS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const getArtist = (id = null) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}artist/get/${id}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_ARTIST,
                payload: data.artist
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}