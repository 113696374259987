import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from "react-bootstrap";
import { getOffers, beforeOffer } from '../offers/offers.action'
import { getBids, beforeBid } from '../bids/bids.action'
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { Link } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { ENV } from '../../config/config'
import FullPageLoader from '../full-page-loader/full-page-loader';
import moment from 'moment'
const { globalPlaceholderImage } = ENV

function ViewBidOffer(props) {

    const [offers, setOffers] = useState([])
    const [bids, setBids] = useState([])
    const [offersPagination, setOffersPagination] = useState('')
    const [bidsPagination, setBidsPagination] = useState('')
    const [loader, setLoader] = useState(true)


    useEffect(() => {
        const qs = ENV.objectToQueryString({ userId: true });
        props.getOffers(qs)
        props.getBids(qs)
    }, [])

    useEffect(() => {
        if (props.offer.getAuth) {
            setOffers(props.offer.offers)
            setOffersPagination(props.offer.pagination)
            props.beforeOffer()
        }
    }, [props.offer.getAuth])

    useEffect(() => {
        if (props.bid.getAuth) {
            setBids(props.bid.bids)
            setBidsPagination(props.bid.pagination)
            props.beforeBid()
        }
    }, [props.bid.getAuth])

    useEffect(() => {
        setLoader(false)
    }, [bids])

    useEffect(() => {
        setLoader(false)
    }, [offers])


    const onOfferPageChange = async (page) => {
        setLoader(true)
        const qs = ENV.objectToQueryString({ page, userId: true })
        props.getOffers(qs)
    }

    const onBidPageChange = async (page) => {
        setLoader(true)
        const qs = ENV.objectToQueryString({ page, userId: true })
        props.getBids(qs)
    }


    return (
        <div className='p-sm-5'>
            {
                loader ?
                    <FullPageLoader />
                    :
                    <section className="explore-area" style={{ paddingTop: `50px` }}>
                        <div className='pb-3'>
                            <Row>
                                <Col md="12" className='text-center'>
                                    <h3 className="my-4">Offers</h3>
                                </Col>
                            </Row>
                            <div>
                                <div className="table-responsive p-3">
                                    <Table hover id="bd-zero" className='activity-logs-table'>
                                        <thead>
                                            <tr>
                                                <th className="name-col">NFT</th>
                                                <th className="name-col">Name</th>
                                                <th className="name-col">Currency</th>
                                                <th className="name-col">Price</th>
                                                <th className="name-col">Created At</th>
                                                <th className="name-col">Expiry Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                offers && offers.length ?
                                                    offers.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="sort-col">
                                                                    <img
                                                                        className="avatar-sm rounded-circle mr-3"
                                                                        src={item.nftImageLink ? ENV.webUrl + item.nftImageLink : globalPlaceholderImage}
                                                                        alt="nftImg"
                                                                    />
                                                                </td>
                                                                <td className="sort-col custom-link">
                                                                    {
                                                                        item.nftName ?
                                                                            <Link to={`/item-details?item=${window.btoa(item.nftId)}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                                {item.nftName ? item.nftName : 'N/A'}
                                                                            </Link>
                                                                            : 'N/A'
                                                                    }
                                                                </td>
                                                                <td className="sort-col">
                                                                    {item.price && item.price.currency ? item.price.currency : 'N/A'}
                                                                </td>
                                                                <td className="sort-col">
                                                                    {item.price && item.price.amount ? item.price.amount : 'N/A'}
                                                                </td>
                                                                <td className="sort-col">
                                                                    {item.createdAt ? moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a') : 'N/A'}
                                                                </td>
                                                                <td className="sort-col">
                                                                    {item.expiryDate ? moment(item.expiryDate).format('MMMM Do YYYY, h:mm:ss a') : 'N/A'}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <tr>No Item To Show</tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            {
                                offersPagination ?
                                    <Pagination
                                        className="pt-3"
                                        defaultCurrent={1}
                                        pageSize // items per page
                                        current={offersPagination.page} // current active page
                                        total={offersPagination.pages} // total pages
                                        onChange={onOfferPageChange}
                                        locale={localeInfo}
                                    />
                                    : ''
                            }
                        </div>

                        <div>
                            <Row>
                                <Col md="12" className='text-center'>
                                    <h3 className="my-4">Bids</h3>
                                </Col>
                            </Row>
                            <div>
                                <div className="table-responsive p-3">
                                    <Table hover id="bd-zero" className='activity-logs-table'>
                                        <thead>
                                            <tr>
                                                <th className="name-col">NFT</th>
                                                <th className="name-col">Name</th>
                                                <th className="name-col">Currency</th>
                                                <th className="name-col">Price</th>
                                                <th className="name-col">Created At</th>
                                                <th className="name-col">Expiry Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bids && bids.length ?
                                                    bids.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="sort-col">
                                                                    <img
                                                                        className="avatar-sm rounded-circle mr-3"
                                                                        src={item.nftImageLink ? ENV.webUrl + item.nftImageLink : globalPlaceholderImage}
                                                                        alt="nftImg"
                                                                    />
                                                                </td>
                                                                <td className="sort-col custom-link">
                                                                    {
                                                                        item.nftName ?
                                                                            <Link to={`/item-details?item=${window.btoa(item.nftId)}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                                {item.nftName ? item.nftName : 'N/A'}
                                                                            </Link>
                                                                            : 'N/A'
                                                                    }
                                                                </td>
                                                                <td className="sort-col">
                                                                    {item.price && item.price.currency ? item.price.currency : 'N/A'}
                                                                </td>
                                                                <td className="sort-col">
                                                                    {item.price && item.price.amount ? item.price.amount : 'N/A'}
                                                                </td>
                                                                <td className="sort-col">
                                                                    {item.createdAt ? moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a') : 'N/A'}
                                                                </td>
                                                                <td className="sort-col">
                                                                    {item.expiryDate ? moment(item.expiryDate).format('MMMM Do YYYY, h:mm:ss a') : 'N/A'}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <tr>No Item To Show</tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            {
                                bidsPagination ?
                                    <Pagination
                                        className="pt-3"
                                        defaultCurrent={1}
                                        pageSize // items per page
                                        current={bidsPagination.page} // current active page
                                        total={bidsPagination.pages} // total pages
                                        onChange={onBidPageChange}
                                        locale={localeInfo}
                                    />
                                    : ''
                            }
                        </div>
                    </section>
            }

        </div>
    )
}

const mapStateToProps = state => ({
    offer: state.offer,
    bid: state.bid,
});

export default connect(mapStateToProps, { getOffers, beforeOffer, getBids, beforeBid })(ViewBidOffer);