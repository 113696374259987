import { useEffect, useState } from 'react'
import { beforeArtist } from './ArtistSlider.action'
import { connect } from "react-redux";
import FullPageLoader from '../../full-page-loader/full-page-loader';
import ReactPlayer from 'react-player/lazy';
import { ENV } from "../../../config/config";
import { Link } from "react-router-dom";
import Breadcrumb from "./../../../components/breadcrumb/breadcrumb";
const bannerBackground = "/img/about-banner.jpeg"
const { globalPlaceholderImage } = ENV;

const ArtistDetails = (props) => {

    const [artist, setArtist] = useState(null)
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    useEffect(() => {
        if (props.artist.artistAuth) {
            setArtist(props.artist.artist)
            props.beforeArtist()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.artist.artistAuth])

    useEffect(() => {
        if (artist) {
            setLoader(false)
        }
    }, [artist])


    return (
        <>
            <Breadcrumb title={"Artist Details"} banner={bannerBackground} />
            <div className="container-artist-detail py-5 artist-story-section">
                {loader ?
                    <FullPageLoader />
                    :
                    <div className='mb-4'>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className='artist-story-test text-center text-capitalize'>
                                        <p>{artist.title}</p>
                                    </div>
                                    <div className='artist-story-user'>
                                        <p>
                                            <Link
                                                className="owner-meta d-flex align-items-center"
                                                to={`/author/${artist.user._id}`}
                                            >
                                                <img
                                                    className="avatar-md rounded-circle mr-3"
                                                    src={
                                                        artist.user && artist.user.profileImageLink !== undefined ?
                                                            ENV.webUrl + artist.user.profileImageLink :
                                                            globalPlaceholderImage
                                                    }
                                                    alt="Owner Avatar"
                                                />
                                                <div className='d-block'>
                                                    <div>{artist.user.username}</div>
                                                </div>
                                            </Link>
                                        </p>
                                    </div>
                                    <div className="nfts-collection-wrapper p-3 text-center">
                                        <h4>Collections</h4>
                                    </div>
                                    <div className="row items explore-items text-center align-items-center justify-content-center">
                                        <div className={`col-12 item explore-item d-flex justify-content-center mt-2`}>
                                            {artist.collections &&
                                                artist.collections.length ?
                                                artist.collections.map((item, index) => {
                                                    return (
                                                        <div key={`edxwh_${index}`} className="d-inline-block align-top mx-2 mb-3 collection-div">
                                                            <div className="card card-artist flex flex-row align-items-center">
                                                                <div className="card-artist-img">
                                                                    <Link to={`/collection-details/${window.btoa(item._id)}`}>
                                                                        <img className="avatar-sm rounded-circle" src={ENV.webUrl + item.logoLink} alt="img" />
                                                                    </Link>
                                                                </div>
                                                                <div className="card-caption artist-card-caption">
                                                                    <div className="card-body">
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <Link to={`/collection-details/${window.btoa(item._id)}`}>
                                                                                <h5 className="mb-0">{item.name}</h5>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : 'No Collection Avaliable'
                                            }
                                        </div>
                                        <div className="p-3 text-center">
                                            {
                                                artist.video ?
                                                    <div>
                                                        <ReactPlayer
                                                            url={ENV.webUrl + artist.videoImageLink}
                                                            width='100%'
                                                            height='500px'
                                                            controls={true}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="text-center">
                                                        <img width="80%" height="100px" src={ENV.webUrl + artist.videoImageLink} className="img-fluid artist-detail-img" alt='img' />
                                                    </div>
                                            }
                                        </div>
                                        <div className="p-3 text-center">
                                            <div>
                                                <span dangerouslySetInnerHTML={{ __html: artist.desc }}></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

        </>
    )
}

const mapStateToProps = (state) => ({
    artist: state.artist
});

export default connect(mapStateToProps, {
    beforeArtist
})(ArtistDetails)
