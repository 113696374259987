import React from "react";
import "video-react/dist/video-react.css";
import bannerimghead from '../../../../src/assets/images/banner-img-two.png';
import bannerimg from '../../../../src/assets/images/banner-img-one.png';

const CollectionBanner = () => {
  return (
    <section className="banner new">
      <div className="container-fluid">
        <div className="banner-content">
          <div className="mt-0 mb-0 text-uppercase banner-heading">
            <div className="bannerimghead-img mb-2">
              <img src={bannerimghead} alt="" title="" className="img-fluid"/>
            </div>
            <div className="bannerimg-img">
              <img src={bannerimg} alt="" title="" className="img-fluid"/>
            </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default CollectionBanner;
