/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
	beforeCategory,
	getCategories,
} from "../../categories/categories.action";
import { beforeArtist, getArtists } from './ArtistSlider.action'
import { ENV } from "../../../config/config";
import { connect } from "react-redux";
import FullPageLoader from "../../../components/full-page-loader/full-page-loader";
import Slider from "react-slick";
import LeftArrow from "./../../../assets/images/left-arrow.png";
import RightArrow from "./../../../assets/images/right-arrow.png";


var settings = {
	dots: false,
	infinite: false,
	speed: 500,
	arrows: true,
	prevArrow: <MostViewPrevArrow />,
	nextArrow: <MostViewNextArrow />,
	slidesToShow: 3,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: 2,
			},
		},
	],
};
function MostViewNextArrow(props) {
	const { onClick } = props;
	return (
		<div onClick={onClick} className="sliderRightArrow">
			<div className="_bgcircle">
				<img
					onClick={onClick}
					src={RightArrow}
					alt="right-arrow.png"
					className="img-fluid slick-arrow next"
				/>
			</div>
		</div>
	);
}

function MostViewPrevArrow(props) {
	const { onClick } = props;
	return (
		<div onClick={onClick} className="sliderLeftArrow">
			<div className="_bgcircle">
				<img
					onClick={onClick}
					src={LeftArrow}
					alt="left-arrow.png"
					className="img-fluid slick-arrow prev"
				/>
			</div>
		</div>
	);
}
const ArtistSlider = (props) => {
	const history = useHistory();
	const [loader, setLoader] = useState(true);
	const [artists, setArtists] = useState(null);

	useEffect(() => {
		props.getCategories();
		props.getArtists();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (props.artist.artistsAuth) {
			const { artists } = props.artist.artists
			setArtists(artists)
			props.beforeArtist()
			setLoader(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.artist.artistsAuth])

	const activator = (index) => {
		document.querySelectorAll(".dip-cat").forEach((item) => {
			item.classList.remove("active");
		});
		document.querySelector(`#cat-dip-${index}`).classList.add("active");
	};

	return (
		<div className="investor-tabs-section">
			<div className="container-fluid">
				<div className="trending-cont">
					<div className="trend">
						<h3>Artist Stories And Video</h3>
					</div>
					<div className="trending-btn">
						<a id={"cat-dip-first"} className="dip-cat active" style={{ cursor: "pointer" }} onClick={() => { activator('first'); setLoader(true); setArtists(null); props.getArtists(); }}>
							All items
						</a>
						<a id={`cat-dip-1`} style={{ cursor: "pointer" }} className="dip-cat" onClick={() => { activator('1'); setLoader(true); setArtists(null); let qs = ENV.objectToQueryString({ video: false }); props.getArtists(qs); }}>
							Stories
						</a>
						<a id={`cat-dip-2`} style={{ cursor: "pointer" }} className="dip-cat" onClick={() => { activator('2'); setLoader(true); setArtists(null); let qs = ENV.objectToQueryString({ video: true }); props.getArtists(qs); }}>
							Videos
						</a>
					</div>
				</div>
				{loader ? (
					<FullPageLoader />
				) : (
					<div className="investor-tabs-slider">
						<Slider {...settings}>
							{artists && artists.length ?
								artists.map((item, index) => {
									return (
										<div className="slide-holder" key={index}>
											<div className="img-contain3 cursor-pointer" onClick={() => { history.push(`/artist-details/${item._id}`) }}>
												<img className="img-fluid" src={ENV.webUrl + item.thumbnailLink} style={{ objectFit: "contain", height: "100%", width: "100%" }} alt='img' />
												<button className="detail-btn2">View Details</button>
												<Link to={`/artist-details/${item._id}`} className="side-icon">
													<span className={item.video ? `fa fa-play` : `fa fa-file-alt`}></span>
												</Link>
											</div>
											<div className="slider-cont">
												<h4>{item.title}</h4>
											</div>
										</div>
									)
								})
								:
								<div className="text-center p-3">
									No Artist Story Found
								</div>
							}
						</Slider>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	category: state.category,
	nft: state.nft,
	artist: state.artist
});

export default connect(mapStateToProps, {
	beforeCategory,
	getCategories,
	beforeArtist,
	getArtists
})(ArtistSlider);
