import { useEffect } from 'react';
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
const bannerBackground = "/img/about-banner.jpeg"
const About = () => {
    useEffect(() => {
        document.querySelectorAll('.nav-link').forEach((item) => {
            item.classList.remove('activeNav')
        })
        document.querySelector(`#link-navv-7`).classList.add('activeNav')
    }, [])

    return (
        <>
            <Breadcrumb title={"About"} banner={bannerBackground} fullHeader={true} backgroundCover={true}/>
            <section className="author-area" style={{ paddingTop:'50px'}}>
                <div className="container">
                    <div className="intro text-center mb-4">
                        <h3 className="mt-0 mb-1 text-upeprcase">About Us</h3>
                    </div>
                    <div className="p-3 pt-5">
                        <h4 className="mt-0 mb-1 text-uppercase">AQUA CULTURE, COMMUNITY, DISCORD NFTS WEB 3.0</h4>
                    </div>
                    <div className="p-3">
                        <p>Your AQUA CULTURE Marketplace and infrastructure will allow you to connect like never before with your favourite aquarium artists, influencers, stores and brands. It’s a forever, connected community in the digital world but also with huge real world utility. </p>
                    </div>
                    <div className="p-3">
                        <h4 className="mt-0 mb-1"><em>Our 3 main missions</em></h4>
                    </div>
                    <div className="p-3">
                        <ul className="list-unstyled content-points">
                            <li>1) To help educate you on NFT’s, web 3.0, digital wallets and tokens to increase your knowledge of this new technology which will in turn be more and more useful in other areas of your life, as they too become more and more digital</li>
                            <li>2) To build an even more connected aquarium community. To make our aquarium culture an asset that everyone can benefit from both emotionally and financially.</li>
                            <li>3) To help artists, aquarium businesses, brands, influencers and every other industry insider to better connect and expand their potential community. </li>
                        </ul>
                    </div>
                    <div className="p-3">
                        <h4 className="mt-0 mb-1 text-uppercase"><em>With NFT’s, culture is now tangible.</em></h4>
                    </div>
                    <div className="p-3">
                        <p>And finally, a touch on decentralization. Everything purchased in the Aqua Culture marketplace is not stored on any amazon, google or big tech server. It’s your data and it is now FOREVER stored on the block-chain.  No cancelling, no banning, no deleting or confiscating and most importantly no hacking!</p>
                    </div>
                    <div className="p-3">
                        <h4 className="mt-0 mb-1 text-uppercase"><em>You are now owner of your own data online, and that is huge.</em></h4>
                    </div>
                    <div className="p-3">
                        <p>Please remember, if you’re not comfortable with crypto, NFT’s or Discord it’s absolutely no problem. We are here to guide you at your own pace into this new and exciting world. And when we say “We” we actually mean that. There is already a massive community welcoming you with open arms and ready to help each and every one of you.</p>
                    </div>
                    <div className="p-3">
                        <p>Contact us through any of our socials or contact form, we also would love you to join our discord community where all announcements and giveaways take place there first. Simply download discord and then click this link https://discord.gg/5sj7WwkYx4</p>
                    </div>
                    <div className="p-3">
                        <p>For a more detailed analysis please also view our WHITEPAPER to deep dive every aspect of AQUA CULTURE in web 3,0. </p>
                    </div>
                    <div className="p-3">
                        <p>Thank You</p>
                    </div>
                    <div className="p-3">
                        <p>- <em> The Aqua Culture Team</em></p>
                    </div>
                </div>
            </section>
        </>
    );
}
export default About;