import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { beforeFaq, getFaqs } from "./faq.action"
import { useHistory } from 'react-router-dom';
import './faq.css'
const Faq = (props) => {

    const history = useHistory();
    const [data, setData] = useState(null)
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    useEffect(() => {
        if (props.faqs.faqsAuth) {
            const { faqs } = props.faqs.faqs
            setData(faqs)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.faqs.faqsAuth])

    return (
        <section className={`faq-area ${props.location?.pathname?.includes('/faq') ? 'wrap-faq' : ''}`}>
            <div className="container">
                <div className="intro faq-intro">
                    <h3 className="mb-0">{"Frequently Asked Questions"}</h3>
                    <button type='button' className='btn btn-primary banner-btn btn-lg faq-view-all' onClick={() => { history.push('/faqs') }}>View All</button>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12">
                        {/* FAQ Content */}
                        <div className="faq-content">
                            {/* Netstorm Accordion */}
                            <div className="accordion" id="netstorm-accordion">
                                <div>
                                    <div>
                                        {/* Single Accordion Item */}
                                        {data ? data.map((item, idx) => {
                                            if (idx < 5 && (window.location.href.split("/")[3] === '' || window.location.href.split("/")[3] === 'investor'))
                                                return (
                                                    <div key={`fd_${idx}`} className="single-accordion-item py-3">
                                                        {/* Card Header */}
                                                        <div className="card-header bg-inherit border-0 p-0">
                                                            <h2 className="m-0">
                                                                <button className={"btn d-block text-left w-100 collapsed py-4 px-0"} type="button" data-toggle="collapse" data-target={`#helpOption-${idx}`}>
                                                                   <p className='pr-3'> {item.title}</p>
                                                                </button>
                                                            </h2>
                                                        </div>
                                                        <div id={`helpOption-${idx}`} className={"collapse in"} data-parent="#netstorm-accordion">
                                                            {/* Card Body */}
                                                            <div className="card-body py-3 px-2 in" data-toggle="collapse" dangerouslySetInnerHTML={{ __html: item.desc }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            else if (window.location.href.split("/")[3] === 'faqs')
                                                return (
                                                    <div key={`fd_${idx}`} className="single-accordion-item py-3">
                                                        {/* Card Header */}
                                                        <div className="card-header bg-inherit border-0 p-0">
                                                            <h2 className="m-0">
                                                                <button className={"btn d-block text-left w-100 collapsed py-4 px-0"} type="button" data-toggle="collapse" data-target={`#helpOption-${idx}`}>
                                                                <p className='pr-3'> {item.title}</p>
                                                                </button>
                                                            </h2>
                                                        </div>
                                                        <div id={`helpOption-${idx}`} className={"collapse in"} data-parent="#netstorm-accordion">
                                                            {/* Card Body */}
                                                            <div className="card-body py-3 px-2 in" data-toggle="collapse" dangerouslySetInnerHTML={{ __html: item.desc }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            return false
                                        }) : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const mapStateToProps = (state) => ({
    faqs: state.faqs,
    error: state.error,
});

export default connect(mapStateToProps, {
    beforeFaq,
    getFaqs
})(Faq);