import React, { Component } from "react";
import { connect } from "react-redux";
import NFTPreview from "../nft-preview/nft-preview";
import { axiosPostFormData } from "./../../utils/functions";
import { upsertNFT, beforeNFT } from "./../nfts/nfts.action";
import { createNFT, createAssetPublic } from "./../../utils/web3";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import SimpleReactValidator from "simple-react-validator";
import FullPageLoader from "../../components/full-page-loader/full-page-loader";
import {
  beforeCollection,
  getCollections,
} from "../collections/collections.actions";
import { ENV } from "../../config/config";
const { integerNumberValidator } = ENV;
const placeholderImg = "";
class CreateNFT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      formValid: true,
      loader: true,
      errors: "",
      nft: {
        image: "",
        name: "",
        description: "",
        currentPrice: "",
        royalty: "",
        copies: "",
        collectionId: "",
        status: 1, // 1 = put on sale, 2 = instant sale price, 3 = unlock purchased
      },
      collections: null,
      copiesCheck: true,
      collectionId: null,
      settings: null
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "This field is required.", // will override all messages
      },
    });
  }

  componentDidMount() {
    window.scroll(0, 0);
    const filter = {
      userId: ENV.getUserKeys()?._id,
      all: true,
    };
    const qs = ENV.objectToQueryString(filter);
    this.props.getCollections(qs);
  }

  componentDidUpdate() {
    const { _id } = ENV.getUserKeys("_id");

    if (_id) {
      if (this.props.collection.getAuth) {
        const { collections } = this.props.collection;
        this.props.beforeCollection();
        if (!collections) {
          toast.info("Please add a collection first");
          return this.props.history.push("/collection/create");
        } else
          this.setState({
            collections,
            loader: false,
          });
      }

      if (this.props.nft.upsertAuth) {
        this.setState({ loader: false });
        this.reset();
        this.props.history.push(
          `/my-nft-gallery`
        );
        this.props.beforeNFT();
      }
    } else {
      this.props.history.push("/login");
    }

    if (this.props.settings.settingsAuth && !this.state.settings) {
      this.setState({ settings: this.props.settings.settings })
    }
  }

  onFileChange(e) {
    let file = e.target.files[0];
    let fileId = e.target.id;
    if (file)
      if (file.type.includes("image")) {
        let { nft } = this.state;
        nft = { ...nft, [e.target.name]: file };
        this.setState(
          {
            nft,
          },
          () => {
            if (file) {
              var reader = new FileReader();

              reader.onload = function (e) {
                $(`#nft-${fileId}`).attr("src", e.target.result);
                $("#nft-image-label").html("File selected");
              };
              reader.readAsDataURL(file);
            }
          }
        );
      } else {
        $(`#nft-${fileId}`).attr("src", placeholderImg);
        file = {};
      }
  }

  onChange(e, status = null) {
    let { name, value } = e.target;

    // if status is provided
    if (status) value = status;

    let { nft, formValid } = this.state;
    nft = { ...nft, [name]: value };

    let copies = parseInt(nft.copies) || 0;
    let maxImages = 999
    if (this.state.settings && this.state.settings.noOfImages)
      maxImages = this.state.settings.noOfImages
    let copiesCheck = copies >= 1 && copies <= maxImages;
    formValid = this.validator.allValid() && copiesCheck;

    this.setState({ nft, formValid, copiesCheck });
  }

  reset = () => {
    const nft = {
      image: "",
      name: "",
      description: "",
      currentPrice: "",
      royalty: "",
      copies: "",
      collectionId: "",
      status: 1, // 1 = put on sale, 2 = instant sale price, 3 = unlock purchased
    };
    this.setState({ nft, copiesCheck: false, loader: false });
  };
  submit = async (e) => {
    e.preventDefault();

    const { nft } = this.state;
    let { copiesCheck } = this.state;
    copiesCheck = nft.copies ? copiesCheck : true;
    this.setState(
      {
        isSubmitted: true,
        formValid: this.validator.allValid() && copiesCheck,
      },
      () => {
        const { formValid } = this.state;
        if (formValid) {
          this.setState(
            {
              loader: true,
            },
            async () => {
              var formData = new FormData();
              for (const key in nft)
                if (nft[key]) formData.append(key, nft[key]);

              const res = await axiosPostFormData(
                "nfts/create",
                formData,
                true
              );
              if (res.success) {
                if (res.nft && res.nft.metaData) {
                  let _nftData = {
                    metaData: res.nft.metaData,
                    copies: res.nft.maxAllowed,
                  };
                  let mintRes = ''
                  if (_nftData.copies === 1 || _nftData.copies === '')
                    mintRes = await createNFT(_nftData, res.nft._id);
                  else
                    mintRes = await createAssetPublic(_nftData, res.nft._id);
                  let { tokenId, txHash, assetId } = mintRes;
                  if (tokenId || assetId) {
                    var fData = new FormData();
                    if (tokenId)
                      fData.append("tokenId", JSON.stringify(tokenId));
                    if (assetId)
                      fData.append("assetId", JSON.stringify(assetId));
                    fData.append("txHash", txHash);
                    fData.append("_id", res.nft._id);
                    fData.append("currentPrice", res.nft.currentPrice);
                    this.setState({ collectionId: res.nft.collectionId });
                    this.props.upsertNFT("update-token-ids", fData, "PUT");
                  }
                  this.setState({ loader: false });
                }
              } else this.setState({ errors: res.message, loader: false });
            }
          );
        } else {
          this.validator.showMessages();
          this.setState(
            {
              errors: "Please fill all required fields in valid format.",
              formValid: false,
            },
            () => {
              $("#create-nft").scrollTop(0, 0);
            }
          );
        }
      }
    );
  };

  render() {
    const { nft, errors, loader, isSubmitted, collections, copiesCheck } =
      this.state;

    return (
      <section className="author-area">
        {loader && <FullPageLoader />}
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-4">
              <NFTPreview {...nft} />
            </div>
            <div className="col-12 col-md-7">
              <div className="mt-5 mt-lg-0 mb-1 mb-lg-1">
                {/* Intro */}
                <div className="intro">
                  <div className="intro-content">
                    <h3 className="mt-3 mb-0">Create NFT</h3>
                  </div>
                </div>
                {/* Form Error */}
                {isSubmitted && errors && (
                  <div className="row">
                    <div className="col-12">
                      <span
                        id="create-nft-err"
                        className="form-error-msg text-danger"
                      >
                        {errors}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {/* Item Form */}
              <form id="create-nft" className="item-form card no-hover">
                <div className="row">
                  <div className="col-12">
                    <div className="input-group form-group text-alignment">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="image"
                          accept=".png,.jpeg,.jpg"
                          onChange={(e) => this.onFileChange(e)}
                          name="image"
                        />
                        <label
                          id="nft-image-label"
                          className="custom-file-label"
                          htmlFor="image"
                        >
                          Choose file *
                        </label>
                      </div>
                      <div>
                        {
                          this.state.settings && this.state.settings.imageSize ?
                            <div className="text-info note-text mt-3">
                              Maximum recommended size of image is {this.state.settings.imageSize} MB(s)
                            </div>
                            : ''
                        }
                        <div className="text-danger mt-3">
                          {this.validator.message("image", nft.image, "required")}
                        </div>
                      </div>


                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group ">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Item Name *"
                        required="required"
                        onChange={(e) => this.onChange(e)}
                        defaultValue={nft.name}
                      />
                      <span className="text-danger">
                        {this.validator.message("name", nft.name, "required")}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="description"
                        placeholder="Description *"
                        cols={30}
                        rows={3}
                        onChange={(e) => this.onChange(e)}
                        defaultValue={nft.description}
                      />
                      <span className="text-danger">
                        {this.validator.message(
                          "description",
                          nft.description,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="collection"
                        name="collectionId"
                        onChange={(e) => this.onChange(e)}
                      >
                        <option className="" value="">
                          Select Collection
                        </option>
                        {collections &&
                          collections.map((collection, index) => {
                            return (
                              <option
                                className=""
                                key={index}
                                value={collection._id}
                              >
                                {collection.name}
                              </option>
                            );
                          })}
                      </select>
                      <span className="text-danger">
                        {this.validator.message(
                          "collection",
                          nft.collectionId,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="copies"
                        placeholder="No. of Copies"
                        onChange={(e) => this.onChange(e)}
                        defaultValue={nft.copies}
                        onKeyDown={(e) => integerNumberValidator(e)}
                      />
                    </div>
                    {nft.copies && !copiesCheck && isSubmitted && (
                      <span className="text-danger">
                        Allowed count of copies is from 1-{this.state.settings.noOfImages ? this.state.settings.noOfImages : 999} only.
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <button
                      disabled={loader}
                      className="btn w-100 mt-3 mt-sm-4"
                      type="button"
                      onClick={(e) => this.submit(e)}
                    >
                      Create NFT
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.error,
  nft: state.nft,
  collection: state.collection,
  settings: state.settings
});

export default connect(mapStateToProps, {
  beforeCollection,
  getCollections,
  upsertNFT,
  beforeNFT,
})(CreateNFT);