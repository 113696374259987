// ERRORS
export const EMPTY_ERRORS = 'EMPTY_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

// HOME
export const HOME_PAGE_DATA = 'HOME_PAGE_DATA';
export const BEFORE_HOME = 'BEFORE_HOME';

// AUCTIONS
export const BEFORE_AUCTION = 'BEFORE_AUCTION';
export const GET_AUCTIONS = 'GET_AUCTIONS';

// NFTS
export const BEFORE_NFT = 'BEFORE_NFT';
export const GET_NFTS = 'GET_NFTS';
export const GET_NFT = 'GET_NFT';
export const UPSERT_NFT = 'UPSERT_NFT';
export const SEARCH_NFT = 'SEARCH_NFT';
export const TRANSFER_NFT = 'TRANSFER_NFT';
export const TRANSFER_ADMIN_NFT = 'TRANSFER_ADMIN_NFT';
export const CANCEL_LISTING = 'CANCEL_LISTING';
export const REFRESH_META_DATA = 'REFRESH_META_DATA';

// WALLET
export const BEFORE_WALLET = 'BEFORE_WALLET';
export const SET_WALLET = 'SET_WALLET';
export const GET_WALLET = 'GET_WALLET';
export const SET_WALLET_ERROR = 'SET_WALLET_ERROR';
export const REDIRECT_TO_WALLET = 'REDIRECT_TO_WALLET';

// USERS
export const BEFORE_USER = 'BEFORE_USER';
export const SET_USER = 'SET_USER';
export const GET_USER = 'GET_USER';
export const SET_CREATORS = 'SET_CREATORS';
export const TOP_SELLERS = 'TOP_SELLERS';
export const SET_INDIVIDUAL_USER = 'SET_INDIVIDUAL_USER';
export const SET_BANNER = 'SET_BANNER';
export const GET_INVESTORS = 'GET_INVESTORS';
export const GET_INVESTOR = 'GET_INVESTOR';

// CATEGORIES
export const BEFORE_CATEGORY = 'BEFORE_CATEGORY';
export const GET_CATEGORIES = 'GET_CATEGORIES';

// COLLECTION
export const BEFORE_COLLECTION = 'BEFORE_COLLECTION';
export const GET_COLLECTION = 'GET_COLLECTION';
export const UPSERT_COLLECTION = 'UPSERT_COLLECTION';
export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const SET_LANDING_COLLECTIONS = 'SET_LANDING_COLLECTIONS';

// FOOTER
export const BEFORE_FOOTER = 'BEFORE_FOOTER';
export const GET_FOOTER = 'GET_FOOTER';

// FAQ
export const BEFORE_FAQ = 'BEFORE_FAQ';
export const GET_FAQS = 'GET_FAQS'

// OFFER
export const BEFORE_OFFER = 'BEFORE_OFFER';
export const GET_OFFERS = 'GET_OFFERS';
export const DELETE_OFFER = 'DELETE_OFFER';
export const CREATE_OFFER = 'CREATE_OFFER';
export const ACCEPT_OFFER = 'ACCEPT_OFFER';

// BID
export const BEFORE_BID = 'BEFORE_BID';
export const GET_BIDS = 'GET_BIDS';
export const DELETE_BID = 'DELETE_BID';
export const CREATE_BID = 'CREATE_BID';
export const ACCEPT_BID = 'ACCEPT_BID';

// NAVBAR
export const BEFORE_NAVBAR = 'BEFORE_BAR';
export const GET_NAVBAR = 'GET_NAVBAR';

// EXPLORE
export const BEFORE_EXPLORE = 'BEFORE_EXPLORE';
export const GET_EXPLORE = 'GET_EXPLORE';
export const SET_EXPLORE_ID = 'SET_EXPLORE_ID';
export const SET_ALL = 'SET_ALL';
export const BEFORE_ALL = 'BEFORE_ALL';
export const BEFORE_SET_ID = 'BEFORE_SET_ID'

// CONTACT
export const BEFORE_CONTACT = 'BEFORE_CONTACT';
export const CREATE_CONTACT = 'CREATE_CONTACT';

// SETTINGS
export const BEFORE_SETTINGS = 'BEFORE_SETTINGS';
export const GET_SETTINGS = 'GET_SETTINGS';
export const CREATE_NEWSLETTER = 'CREATE_NEWSLETTER';
export const BEFORE_NEWSLETTER = 'BEFORE_NEWSLETTER';

// DISCOVER
export const BEFORE_DISCOVER = 'BEFORE_DISCOVER';
export const GET_DISCOVER_NAV = 'GET_DISCOVER_NAV';

// ACTIVITY
export const BEFORE_ACTIVITY = 'BEFORE_ACTIVITY';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const REFRESH_ACTIVITIES = 'REFRESH_ACTIVITIES'; 

// RANKINGS
export const BEFORE_RANKING = 'BEFORE_RANKING';
export const GET_RANKINGS = 'GET_RANKINGS';

// APP
export const BEFORE_APP = 'BEFORE_APP';
export const SET_RATE = 'SET_RATE';
export const SET_ANN = 'SET_ANN';

// BANNER
export const BEFORE_BANNER = 'BEFORE_BANNER';
export const GET_BANNER = 'GET_BANNER';

// INTEGRATION
export const BEFORE_INTEGRATION = 'BEFORE_INTEGRATION';
export const CREATE_INTEGRATION = 'CREATE_INTEGRATION';

// HISTORY
export const GET_HISTORY = 'GET_HISTORY';
export const BEFORE_HISTORY = 'BEFORE_HISTORY';

// ARTIST
export const GET_ARTISTS = 'GET_ARTISTS';
export const GET_ARTIST = 'GET_ARTIST';
export const BEFORE_ARTIST = 'BEFORE_ARTIST';

// LEADERBOARD
export const BEFORE_LEADERBOARD = 'BEFORE_LEADERBOARD';
export const GET_LEADERBOARD = 'GET_LEADERBOARD';

// SELLING_NFTS
export const BEFORE_SELLING_NFTS = 'BEFORE_SELLING_NFTS';
export const GET_SELLING_NFT = 'GET_SELLING_NFT';
export const FIND_SELLING_NFT_ID = 'FIND_SELLING_NFT_ID';

// WEB3_CHECK
export const BEFORE_WEB3_CHECK = 'BEFORE_WEB3_CHECK';
export const SET_WEB3_CHECK = 'SET_WEB3_CHECK';