import React, { useState, useEffect } from "react";
import Explore from "../explore/explore-all";
import FullPageLoader from "../../components/full-page-loader/full-page-loader";
import { connect } from "react-redux";
import { beforeCollection, getCollection } from "../collections/collections.actions";
import { Link } from "react-router-dom";
import { ENV } from "../../config/config";
import FilterSearch from "../filter-search/filtersearch";
import { toast } from 'react-toastify';
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
const { userDefaultImg, categoryDefaultImg, globalPlaceholderImage } = ENV;
const bannerImg = "/img/placeholder.png";

const CollectionDetails = (props) => {
  const [collection, setCollection] = useState({});
  const [collectionLoader, setColLoader] = useState(true); // collections loader
  const [nftsLoader, setNftsLoader] = useState(true); // NFTs loader
  const [nftFilter, setNftFitler] = useState(null);
  const [Filter, setFilter] = useState(false);
  const { _id } = ENV.getUserKeys('_id');
  const [itemCounter, setItemCounter] = useState(0);

  useEffect(() => {
    window.scroll(0, 0);
    const { collectionId } = props.match.params;
    if (collectionId) props.getCollection(window.atob(collectionId));
    else props.history.push("/collections");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when collection data is received
  useEffect(() => {
    if (props.collection.getAuth) {
      const { collection } = props.collection;
      setCollection(collection);
      props.beforeCollection();
      setColLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.collection.getAuth]);

  const itemCounterSetter = (num) => {
    setItemCounter(num)
  }

  const getNftsLoader = (loader) => {
    setNftsLoader(loader);
  };

  const applyFilters = (filter) => {
    if (filter) {
      setNftsLoader(true)
      setNftFitler(filter)
    }
  }

  const copy = () => {
    var copyText = document.getElementById("collection-det-add");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    toast.success("Address Copied To Clipboard.")
  }

  return (
    <>
      <Breadcrumb banner={collection.bannerLink ? ENV.webUrl + collection.bannerLink : bannerImg} />
      <section
        className="author-area explore-area  popular-collections-area"
        style={{ padding: "0px" }}
      >
        {(collectionLoader || nftsLoader) && <FullPageLoader />}
        <div className="">
          {collection && (
            <div className="d-flex collection-detail-container">
              <div className="collection-preview collection-card">
                <div className="seller">
                  <div className="seller-thumb avatar-lg">
                    <img className="rounded-circle collection-logo" src={collection.logoLink ? ENV.webUrl + collection.logoLink : globalPlaceholderImage} alt="Collection Logo" />
                  </div>
                </div>
              </div>
              <div className="card-caption p-0 text-center">
                <div className="card-body pt-0 pb-0">
                  <div>
                    <h5 className="mt-3 mb-3">{collection.name}</h5>
                    <p className="mt-3 mb-3">
                      {collection.url ? (
                        <div className="input-group">
                          <input type="text" id="collection-det-add" className="form-control" placeholder="Collection URL" readOnly value={collection.url}
                            style={{
                              borderRadius: "100px",
                              backgroundColor: "transparent",
                              border: "2px solid",
                              height: "40px",
                              padding: "1.5rem",
                              boxShadow: "none",
                              outline: "none",
                              textAlign: "center"
                            }}
                          />
                          <div className="input-group-append"
                            style={{
                              position: "absolute",
                              height: "100%",
                              top: "0",
                              right: "0",
                              borderRadius: "100px",
                              overflow: "hidden",
                            }}
                          >
                            <button style={{
                              marginRight: "5px",
                              padding: "12px 16px",
                              backgroundColor: "transparent",
                              color: "#000",
                              border: "0",
                              outline: "none",
                            }}
                              data-toggle="tooltip" title="copy"
                              onClick={copy}
                            ><i className="icon-docs" /></button>
                          </div>
                        </div>
                      ) : (
                        "- no URL found -"
                      )}
                    </p>
                    <p className="mt-3 mb-3">
                      {collection.description ? collection.description : "- no description found -"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="items four-box-column">
                <div className="px-0 mt-3 mr-3">
                  <div className="card no-hover">
                    <div className="single-seller d-flex align-items-center">
                      <img
                        className="avatar-md rounded-circle"
                        src={collection.category && collection.category.imageLink !== undefined ? ENV.webUrl + collection.category.imageLink : categoryDefaultImg}
                        alt="img"
                      />
                      <div className="seller-info ml-3">
                        <div className="seller">{collection.category?.name || "N/A"}</div>
                        <span>Category</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-0 my-3 mr-3">
                  <div className="card no-hover">
                    <div className="single-seller d-flex align-items-center">
                      <div>
                        <img
                          className="avatar-md rounded-circle"
                          src={collection.user && collection.user.profileImageLink !== undefined ? ENV.webUrl + collection.user.profileImageLink : userDefaultImg}
                          alt="User Avatar"
                        />
                      </div>
                      <div className="seller-info ml-3">
                        <Link className="seller mb-2 d-flex" to={`/author/${collection.user?._id}`}>
                          {collection.user?.username}{
                            collection.user && collection.user.verificationCheck ?
                              <>
                                <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                  <FontAwesomeIcon icon={faCheck} />
                                </span>
                                <ReactTooltip />
                              </>
                              : ''
                          }
                        </Link>
                        <span>Creator</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-0 mt-3 mr-3">
                  <div className="card no-hover">
                    <div className="single-seller d-flex align-items-center">
                      <div className="avatar-md rounded-circle" data-toggle="tooltip" title="Clone">
                        <i className="fas fa-clone" />
                      </div>
                      <div className="seller-info ml-3">
                        <div className="seller">{itemCounter}</div>
                        <span>Items</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-0 my-3">
                  <div className="card no-hover">
                    <div className="single-seller d-flex align-items-center">
                      <Link to="/authors">
                        <div className="avatar-md rounded-circle" data-toggle="tooltip" title="User">
                          <i className="fas fa-users" />
                        </div>
                      </Link>
                      <div className="seller-info ml-3">
                        <Link className="seller mb-2" to="/authors">
                          {collection.owners || 0}
                        </Link>
                        <span>Owners</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Edit  */}
                {_id && (collection?.user?._id === _id) && <Link className="edit seller" to={`/collection/edit/${collection?._id}`}>
                  <span className="edit-icon fa fa-edit"></span>Edit</Link>}
              </div>

              <div className={` ${Filter ? 'active' : ''} d-flex collection-wrapper`}>
                <button className="side-toggle-btn" onClick={() => setFilter(!Filter)}><i className="fas fa-sort-amount-down-alt" /></button>
                <FilterSearch applyFilters={applyFilters} />
                <div className="nfts-collection-wrapper w-100">
                  {
                    collection._id ?
                      <Explore collectionId={collection._id} collectionCheck={true} setLoader={getNftsLoader} filter={nftFilter} itemCounterSetter={itemCounterSetter} />
                      : ''
                  }
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.error,
  collection: state.collection,
});

export default connect(mapStateToProps, { beforeCollection, getCollection })(CollectionDetails);
