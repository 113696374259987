import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { useWeb3React } from '@web3-react/core';
import connectors from './../../components/wallet/connector';
import { connect } from "react-redux";
import Header from "../../components/home/header/Header";
import Footer from "../../components/home/footer/Footer";
import Scrollup from "../../components/scroll-up/sroll-up";
import { beforeUser } from "../../components/user/user.action";
import { getArtist } from "../../components/home/investor/ArtistSlider.action";
import { ENV } from "../../config/config";
const bannerImg = "/img/placeholder.png";


const Layout1 = (props) => {
  const { account, activate, library } = useWeb3React();
  const [banner, setBanner] = useState(null)
  useEffect(() => {
    const key = ENV.getUserKeys("_id");
    if (key._id) {
      let user = ENV.getUserKeys("");
      let banner = user.bannerImage ? user.bannerImage : bannerImg;
      setBanner(banner)
    }
    if (window.location.href.split("/")[3] === "artist-details") {
      props.getArtist(window.location.href.split("/")[4]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (localStorage.getItem('connectedWalletName')) {
      window.ethereum.setSelectedProvider(window.ethereum.providers.find(({ isMetaMask }) => isMetaMask))
    }
    const provider = window.localStorage.getItem("provider");
    if (provider) {
      const connector = connectors[provider].provider;
      activate(connector, undefined, true)
        .then(async (res) => {
          const web3Provider = await connector.getProvider();
          const web3 = new Web3(web3Provider);
          window.walletPO = web3
        })
        .catch((error) => {
          console.log(error)
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (account) {
      localStorage.setItem('connectedAddress', account);
    }
  }, [account])

  useEffect(() => {
    if (library) {
      window.library = library;
    }
  }, [library])

  useEffect(() => {
    if (window.location.href.split("/")[3] === "collection-details") {
      if (props.collection.getAuth) {
        const { collection } = props.collection;
        if (collection && collection.banner)
          setBanner(collection.banner);
        else
          setBanner(bannerImg);
        props.beforeUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.collection.getAuth])

  useEffect(() => {
    if (window.location.href.split("/")[3] === "author") {
      if (props.user.individualUserAuth) {
        if (banner !== props.user.individualUser.bannerImage) {
          const user = props.user.individualUser;
          if (user.bannerImage)
            setBanner(user.bannerImage);
          else
            setBanner(bannerImg)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.individualUserAuth])

  useEffect(() => {
    setBanner(props.user.banner)
    props.beforeUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.bannerAuth])

  return (
    <div className="main collection">
      <div className="bg-white">
        <Header />
        {props.children}
        <Footer />
        <Scrollup />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  error: state.error,
  collection: state.collection,
  user: state.user,
  artist: state.artist,
});

export default connect(mapStateToProps, { beforeUser, getArtist })(Layout1);
