import { useState, useEffect } from "react";
import { Images } from "../../../assets/asset";
import { beforeContact, submitContact } from './contact.action'
import { connect } from "react-redux";
import validator from 'validator';
import FullPageLoader from '../../../components/full-page-loader/full-page-loader';
import FAQ from './../../faq/faq'
import { toast } from 'react-toastify';

const Requiry = (props) => {

  const [data, setData] = useState({
    name: '',
    email: '',
    number: '',
    message: ''
  })

  const [msg, setMsg] = useState({
    name: '',
    email: '',
    number: '',
    message: ''
  })

  const [submitCheck, setSubmitCheck] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (props.contact.contactAuth) {
      toast.success("Contact message sent successfully.")
      setLoader(false)
      props.beforeContact()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contact.contactAuth])

  const formSubmission = (e) => {
    e.preventDefault()
    setSubmitCheck(true)
    if (!validator.isEmpty(data.name) && validator.isEmail(data.email)
      && validator.isMobilePhone(data.number) && !validator.isEmpty(data.message)) {
      let formData = new FormData()
      for (const key in data)
        formData.append(key, data[key])
      props.submitContact(formData)
      setData({ name: '', email: '', number: '', message: ''})
      setMsg({ name: '', email: '', number: '', message: ''})
      e.target[0].value = ''
      e.target[1].value = ''
      e.target[2].value = ''
      e.target[3].value = ''
      e.target[4].value = ''
      setLoader(true)
    }
    else {
      let name = ''
      let email = ''
      let number = ''
      let message = ''
      if (validator.isEmpty(data.name)) {
        name = 'Name Is Required.'
      }
      if (!validator.isEmail(data.email)) {
        email = 'Email Is Invalid.'
      }
      if (!validator.isMobilePhone(data.number)) {
        number = 'Valid Phone Number Is Required.'
      }
      if (validator.isEmpty(data.message)) {
        message = 'Message Is Required.'
      }
      setMsg({ name, email, number, message })
    }
  }

  return (
    <>
    <div className="inquiry">
      {
        loader ?
          <FullPageLoader />
          :
          <div className="row">
            <div className="col-lg-7 p-0">
              <FAQ/>
            </div>
            <div className="col-lg-5 p-0">
              <div className="container">
                <div className="inquire">
                  <h2>Question? Artist? Brand Collaboration? Hit us up below.</h2>
                  <form onSubmit={(e) => { formSubmission(e) }}>
                    <div className="row">
                      <div className="col-sm-12  mb-3 namaste nameicon">
                        <img
                          src={Images.user}
                          className="img-fluid"
                          alt="img"
                        />
                        <input
                          type="text"
                          className="form-control username mb-0"
                          placeholder="Name"
                          value={data.name}
                          onChange={(e) => {
                            setData({ ...data, name: e.target.value });
                            if (submitCheck) {
                              if (e.target.value) {
                                setMsg({ ...msg, name: '' })
                              }
                              else {
                                setMsg({ ...msg, name: 'Name Is Required.' })
                              }

                            }
                          }
                          }
                        />
                        <label className={`text-danger pl-1 ${msg.name ? `` : `d-none`}`}>{msg.name}</label>
                      </div>
                      <div className="col-sm-12  mb-3 namaste emailicon">
                        <img
                          src={Images.email}
                          className="img-fluid"
                          alt="img"
                        />
                        <input
                          type="text"
                          className="form-control email mb-0"
                          placeholder="Email"
                          value={data.email}
                          onChange={(e) => {
                            setData({ ...data, email: e.target.value });
                            if (submitCheck) {
                              if (validator.isEmail(e.target.value)) {
                                setMsg({ ...msg, email: '' })
                              }
                              else {
                                setMsg({ ...msg, email: 'Email Is Invalid.' })
                              }
                            }
                          }
                          }
                        />
                        <label className={`text-danger pl-1 ${msg.email ? `` : `d-none`}`}>{msg.email}</label>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-sm-12  mb-3 namaste phoneicon">
                        <img
                          src={Images.phone}
                          className="img-fluid"
                          alt="img"
                        />
                        <input
                          type="text"
                          className="form-control phone mb-0"
                          placeholder="Phone number"
                          value={data.number}
                          onChange={(e) => {
                            setData({ ...data, number: e.target.value });
                            if (submitCheck) {
                              if (validator.isMobilePhone(e.target.value)) {
                                setMsg({ ...msg, number: '' })
                              }
                              else {
                                setMsg({ ...msg, number: 'Valid Phone Number Is Required.' })
                              }
                            }
                          }
                          }
                        />
                        <label className={`text-danger pl-1 ${msg.number ? `` : `d-none`}`}>{msg.number}</label>
                      </div>
                    </div>
                    <div className="col-sm-12  mb-3 namaste texticon">
                      <img
                        src={Images.message}
                        className="img-fluid"
                        alt="img"
                      />
                      <textarea
                        className="form-control message mb-0"
                        id="exampleFormControlTextarea1"
                        placeholder="Your message"
                        rows="5"
                        value={data.message}
                        onChange={(e) => {
                          setData({ ...data, message: e.target.value });
                          if (submitCheck) {
                            if (e.target.value) {
                              setMsg({ ...msg, message: '' })
                            }
                            else {
                              setMsg({ ...msg, message: 'Message Is Required.' })
                            }
                          }
                        }
                        }
                      ></textarea>
                      <label className={`text-danger pl-1 ${msg.message ? `` : `d-none`}`}>{msg.message}</label>
                    </div>
                    <div className="ct-btn">
                      <button
                        type="submit"
                        className="btn banner-btn btn-primary"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>          
      }
    </div>
    
  </>
  );
};
const mapStateToProps = (state) => ({
  error: state.error,
  contact: state.contact,
});

export default connect(mapStateToProps, { beforeContact, submitContact })(Requiry);
