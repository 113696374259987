import { GET_ARTISTS, BEFORE_ARTIST, GET_ARTIST } from '../../../redux/types';

const initialState = {
    artists: null,
    artistsAuth: false,
    artist: null,
    artistAuth: false
}

export default function artistRed(state = initialState, action) {
    switch (action.type) {
        case GET_ARTISTS:
            return {
                ...state,
                artists: action.payload,
                artistsAuth: true
            }
        case GET_ARTIST:
            return {
                ...state,
                artist: action.payload,
                artistAuth: true
            }
        case BEFORE_ARTIST:
            return {
                ...state,
                artists: null,
                artistsAuth: false,
                artist: null,
                artistAuth: false
            }
        default:
            return {
                ...state
            }
    }
}