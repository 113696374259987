import { BEFORE_APP, SET_RATE, SET_ANN, BEFORE_WEB3_CHECK, SET_WEB3_CHECK } from './redux/types';

const initialState = {
    rate: null,
    rateAuth: false,
    annRate: null,
    annRateAuth: false,
    web3Check: false
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case SET_RATE:
            return {
                ...state,
                rate: action.payload,
                rateAuth: true
            }
        case SET_ANN:
            return {
                ...state,
                annRate: action.payload,
                annRateAuth: true
            }
        case BEFORE_APP:
            return {
                ...state,
                rate: null,
                rateAuth: false,
                annRate: null,
                annRateAuth: false
            }
        case BEFORE_WEB3_CHECK:
            return {
                ...state,
                web3Check: false
            }
        case SET_WEB3_CHECK:
            return {
                ...state,
                web3Check: true
            }
        default:
            return {
                ...state
            }
    }
}