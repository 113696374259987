import React from "react";
import "video-react/dist/video-react.css";
import Video from '../../../assets/video/banner-video.mp4';
import Bannerimg from '../../../assets/images/bannerimg.png'
import Block from '../../home/blocks/Blocks';

const Banner = () => {
  return (
    <section className="banner">
      <div className="container-fluid banner-container">
        <div className="row align-items-center">
          <div className="col-sm-6">
          <div className="banner-img-content">
            <div className="banner-img">
              <img src={Bannerimg} alt="img"/>
            </div>
          </div>
          </div>
          <div className="col-sm-6">
          <div className="banner-content">
          <div className="banner-video">
            <span className="left top"></span>
            <span className="left bottom"></span>
            <span className="right top"></span>
            <span className="right bottom"></span>
            <video width="100%" height="100%" loop={true}
              autoPlay={true}
              muted={true}
              controls={false}>
              <source src={Video} />
            </video>
          </div>
        </div>
          </div>
        </div>
      </div>
      <Block />
    </section>
  );
};

export default Banner;
