
import { BEFORE_SELLING_NFTS, GET_SELLING_NFT, FIND_SELLING_NFT_ID } from '../../redux/types';

const initialState = {
    sellingNft: null,
    sellingNftAuth: false,
    sellingNftId: null,
    sellingNftIdAuth: false
}

export default function sellingNftsRed(state = initialState, action) {
    switch (action.type) {
        case GET_SELLING_NFT:
            return {
                ...state,
                sellingNft: action.payload,
                sellingNftAuth: true
            }
        case FIND_SELLING_NFT_ID:
            return {
                ...state,
                sellingNftId: action.payload,
                sellingNftIdAuth: true
            }
        case BEFORE_SELLING_NFTS:
            return {
                ...state,
                sellingNft: null,
                sellingNftAuth: false,
                sellingNftId: null,
                sellingNftIdAuth: false
            }
        default:
            return {
                ...state
            }
    }
}
