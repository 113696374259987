import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSettings, createNewsLetter, beforeNewsLetter } from './footer.action'
import { connect } from 'react-redux';
import validator from 'validator';
import FullPageLoader from '../../full-page-loader/full-page-loader'
import logo from '../../../assets/images/logo.svg'

const Footer = (props) => {

    const [settings, setSettings] = useState(null)
    const [date, setDate] = useState(null)
    const [email, setEmail] = useState('')
    const [emailMsg, setEmailMsg] = useState('')
    const [submitCheck, setSubmitCheck] = useState(false)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setDate(new Date().getFullYear())
    }, [])

    useEffect(() => {
        if (props.settings.settingsAuth) {
            setSettings(props.settings.settings)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.settings.settingsAuth])

    useEffect(() => {
        if (props.settings.newsLetterAuth) {
            setLoader(false)
            props.beforeNewsLetter()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.settings.newsLetterAuth])

    useEffect(() => {
        if (props.error) {
            setLoader(false)
            props.beforeNewsLetter()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.error]);

    const formSubmission = (e) => {
        e.preventDefault()
        setSubmitCheck(true)
        if (validator.isEmail(email)) {
            let formData = new FormData()
            formData.append('email', email)
            props.createNewsLetter(formData)
            setEmail('')
            setEmailMsg('')
            e.target[0].value = ''
            setLoader(true)
        }
        else {
            let emlMsg = ''
            if (!validator.isEmail(email)) {
                emlMsg = 'Email Is Invalid.'
            }
            setEmailMsg(emlMsg)
        }
    }

    return (
        <>
            {
                loader ?
                    <FullPageLoader />
                    :
                    <footer className="footer-area">
                        {/* Footer Top */}
                        <div className="footer-top">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3">
                                        {/* Footer Items */}
                                        <div className="footer-items">
                                            {/* Logo */}
                                            <Link className="navbar-brand" to="/">
                                                <img src={logo} alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="footer-links">
                                            <ul className="list-unstyled">
                                                <li>
                                                    <Link to="/explore-all">
                                                        Marketplace
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/investor">
                                                        Investors
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/authors">
                                                        Creators
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/privacy-and-terms">
                                                        Privacy Policy
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/about-us">
                                                        About Us
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/faqs">
                                                        FAQs
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="copyrights">
                                            <p>
                                                Aqua Culture (NFT Art Minting) {'@' + date} {settings && settings.desc ? "| " + settings.desc : ''}
                                            </p>
                                        </div>
                                        {/* Social Icons */}
                                        <div className="social-icons">
                                            {
                                                settings && settings.facebook ?
                                                    <a href={settings.facebook} target="_blank" rel="noreferrer">
                                                        <div className="change-icon-color">
                                                            <i className={"fab fa-facebook-f"} />
                                                        </div>
                                                    </a> : ''
                                            }
                                            {
                                                settings && settings.twitter ?
                                                    <a href={settings.twitter} target="_blank" rel="noreferrer">
                                                        <div className="change-icon-color">
                                                            <i className={"fab fa-twitter"} />
                                                        </div>
                                                    </a> : ''
                                            }
                                            {
                                                settings && settings.twitter ?
                                                    <a href={settings.discord} target="_blank" rel="noreferrer">
                                                        <div className="change-icon-color">
                                                            <i className={"fab fa-discord"} />
                                                        </div>
                                                    </a>
                                                    : ''
                                            }
                                            {
                                                settings && settings.instagram ?
                                                    <a href={settings.instagram} target="_blank" rel="noreferrer">
                                                        <div className="change-icon-color">
                                                            <i className={"fab fa-instagram"} />
                                                        </div>
                                                    </a> : ''
                                            }
                                            {
                                                settings && settings.tiktok ?
                                                    <a href={settings.tiktok} target="_blank" rel="noreferrer">
                                                        <div className="change-icon-color">
                                                            <i className={"fab fa-tiktok"} />
                                                        </div>
                                                    </a> : ''
                                            }
                                            {
                                                settings && settings.telegram ?
                                                    <a href={settings.telegram} target="_blank" rel="noreferrer">
                                                        <div className="change-icon-color">
                                                            <i className={"fab fa-telegram"} />
                                                        </div>
                                                    </a> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-subscribe">
                            <div className="subscribe-container">
                                <div className="subscribe-main">
                                    <div className="subscribe-head">
                                        <h3>Subscribe our email newsletter.</h3>
                                    </div>
                                    <div className="subscribe-box">
                                        <form className="d-flex align-items-center" onSubmit={formSubmission}>
                                            <div className="namaste subscribe-select">
                                                <input type={"text"}
                                                    style={{
                                                        color: "grey",
                                                        border: "1px solid gainsboro",
                                                        borderRadius: "3px",
                                                        appearance: "auto",
                                                        background: "#fff",
                                                    }}
                                                    value={email}
                                                    placeholder="Email"
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                        if (submitCheck) {
                                                            if (validator.isEmail(e.target.value)) {
                                                                setEmailMsg('')
                                                            }
                                                            else {
                                                                setEmailMsg('Email Is Invalid.')
                                                            }
                                                        }
                                                    }
                                                    }
                                                />
                                            </div>
                                            <label className={`invalid-email text-danger pl-1 ${emailMsg ? `` : `d-none`}`}>{emailMsg}</label>
                                            <div className="ct-btn subscribe-btn">
                                                <button type="submit" className="btn banner-btn btn-primary">
                                                    Subscribe
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
            }
        </>
    );
}

const mapStateToProps = state => ({
    settings: state.settings,
    error: state.error
});

export default connect(mapStateToProps, { getSettings, createNewsLetter, beforeNewsLetter })(Footer);