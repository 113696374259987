/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ENV } from "../../../config/config";
import { listInvestorNfts, beforeNFT } from "../../nfts/nfts.action";
import { useHistory } from 'react-router-dom';
import FullPageLoader from "../../../components/full-page-loader/full-page-loader";
import Slider from "react-slick";



const Banner = (props) => {

	const history = useHistory();

	const [nfts, setNfts] = useState(null);
	const [loader, setLoader] = useState(true);

	const settings = {
		customPaging: function (index) {
			let nft = nfts[index]
			return (
				<a className="investor-tabs-slider-pagination">
					<img src={ENV.webUrl + nft.nftImageLink} className="img-fluid" alt='img' />
				</a>
			);
		},
		dots: true,
		dotsClass: "slick-dots slick-thumb",
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 3000,
		autoplaySpeed: 6000,
	};

	useEffect(() => {
		props.listInvestorNfts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (props.nft.nftsAuth) {
			setNfts(props.nft.nftsData);
			setLoader(false)
			props.beforeNFT();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.nft.nftsAuth]);

	return (
		<div className="container-fluid">
			{
				loader ?
					<FullPageLoader />
					:
					<div className="investor-tabs-slider-2">
						<Slider {...settings}>
							{
								nfts && nfts.length ?
									nfts.map((item, index) => {
										return (
											<div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
												<img className="img-fluid" src={ENV.webUrl + item.nftImageLink} alt={item.name} />
												<div className="content cursor-pointer" onClick={() => { history.push(`/item-details?item=${window.btoa(item._id)}&adminCheck=true&sellingNftId=${item.sellingNftId}`); }}>
													<div className="content-h d-flex justify-content-between align-items-center">
														<div className="content-intro">
															<h4>{item.name}</h4>
														</div>
														<div className="price-holder"> {/* add class  className="content-para" if design disturbs*/}
															<span>
																{item.currentPrice} {ENV.currency}
															</span>
														</div>
													</div>
													<div className="content-para">
														<p>
															{item.description ? item.description : 'Description Not Avaliable.'}

														</p>
													</div>
													<div className="item-count-total">
														{item.purchaseCount} of {item.maxAllowed}
													</div>

												</div>
											</div>
										)
									})
									:
									''
							}
						</Slider>
					</div>
			}
		</div>
	);
};

const mapStateToProps = (state) => ({
	nft: state.nft
});

export default connect(mapStateToProps, {
	listInvestorNfts,
	beforeNFT
})(Banner);
