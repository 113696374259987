import React from "react";
import { ENV } from '../../../config/config'

const BannerCommunity = () => {
  return (
    <div className="banner2">
      <div className="">
        <div className="main-banner2 position-relative">
          <div className="d-md-flex mm-aquarium-banner justify-content-between align-items-center">
            <div className="mm-aquaruin-content-1">
				<img src="/img/mm-aquaruin-content-1.png" className="img-fluid" alt="aquarium"/>
            </div>
			<div className="mm-aquaruin-content-2">
				<img src="/img/mm-aquaruin-content-2.png" className="img-fluid" alt="aquarium"/>
            </div>
          </div>
          {/* <img
            className="img-fluid banner-two-img"
            src="/img/community.jpg"
            alt="img"
          /> */}
          <div className="banner-content2 mm-aquarium-banner-content">
            <button
              type="button"
              className="btn banner-btn btn-primary"
              onClick={() => { ENV.scrollToQuery() }}
            >
              Join Our Community
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerCommunity;
