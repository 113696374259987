import { useEffect } from 'react'
import { connect } from "react-redux";
import { beforeApp, getRate, getAnnRate } from './App.action';
import { beforeSettings, getSettings } from './components/home/footer/footer.action'
import { getFaqs } from './components/faq/faq.action'
import { beforeWallet } from './components/wallet/wallet.action';
import { useHistory } from 'react-router-dom';

const Getter = (props) => {
    const history = useHistory();

    useEffect(() => {
        if (props.wallet.redirectW) {
            props.beforeWallet()
            history.push('/login')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.wallet.redirectW])

    useEffect(() => {
        props.getSettings()
        props.getFaqs()
        props.getRate('80')
        props.getAnnRate()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // when an error is received
        if (props.error) {
            // if user is not found, clear storage and redirect to connect wallet screen
            if (props.error.user404) {
                let connectedWalletName = localStorage.getItem('connectedWalletName')
                localStorage.clear()
                if(connectedWalletName)
                    localStorage.setItem('connectedWalletName', connectedWalletName)
                history.push('/login')
            }
        }
        // eslint-disable-next-line
    }, [props.error])

    return (
        <>
        </>
    )
}

const mapStateToProps = (state) => ({
    app: state.app,
    wallet: state.wallet,
    error: state.error,
    settings: state.settings
});

export default connect(mapStateToProps, { getFaqs, beforeApp, getRate, getAnnRate, beforeWallet, beforeSettings, getSettings })(Getter);
