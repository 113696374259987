import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { beforeNFT, getNFT, buyNft, transferNft, transferAdminNft, cancelListing, refreshMetaData } from "../nfts/nfts.action";
import { beforeWeb3Check } from '../../App.action'
import { beforeSellingNft, getSellingNft, getSellingNftId } from '../nfts/sellingNfts.action'
import FullPageLoader from "../full-page-loader/full-page-loader";
import { ENV } from "../../config/config";
import {
  makeOfferWeb3,
  placeBidWeb3,
  cancelOfferBidWeb3,
  checkBalance,
  buyNow,
  buyAndMintAsset,
  buyAndMintAssetByAccept,
  transferByAccept,
  putOffSale
} from "./../../utils/web3";
import moment from "moment";
import Countdown from "react-countdown";
import SimpleReactValidator from "simple-react-validator";
import { Modal, Button, Row, Col } from "react-bootstrap";
import {
  beforeOffer,
  makeOffer,
  getOffers,
  deleteOffer,
  acceptOffer,
} from "../offers/offers.action";
import {
  beforeBid,
  placeBid,
  getBids,
  deleteBid,
  acceptBid,
} from "../bids/bids.action";
import { beforeHistory, getHistory } from "../history/history.action";
import { emptyError } from './../../redux/shared/error/error.action';
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTooltip from "react-tooltip";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const {
  globalPlaceholderImage,
  countDownRenderer,
  decimalNumberValidator,
  bscExplorerURL,
} = ENV;
const currencies = ENV.currencies.filter((elem) => elem.showInBuy === true);

const initData = {
  bidBtn: "Place a Bid",
  offerBtn: "Make Offer",
  sellBtn: "Sell",
};

// expiry options
const expiryOptions = [
  { label: "5 Days", value: moment().add(5, "days").format("DD/MM/YYYY") },
  { label: "7 Days", value: moment().add(7, "days").format("DD/MM/YYYY") },
  { label: "A Month", value: moment().add(1, "months").format("DD/MM/YYYY") },
  { label: "Custom Date", value: "-1" },
];

// make an offer / bid config.
const config1 = {
  price: {
    currency: currencies && currencies.length ? currencies[0].value : "WETH",
    amount: "",
  },
  expiry: {
    date: expiryOptions[0].value,
    time: moment(new Date()).format("HH:mm"),
    type: 1, // 1 for time & 2 for datetime-local
    datetime: "", // for payload
  },
  cabi: JSON.stringify(
    currencies && currencies.length ? currencies[0].abi : []
  ),
  cAddress: currencies && currencies.length ? currencies[0].address : "",
};
class ItemDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: ENV.getUserKeys()?._id,
      offers: [],
      offersPagination: null,
      moreCheck: true,
      bids: [],
      bidsPagination: null,
      page: 2,
      nftHistory: [],
      nftHistoryPagination: null,
      rendType: 1,
      errors: "",
      isSubmitted: false,
      formValid: true,
      loader: true,
      nft: null,
      isOpen: false,
      nftConfig: config1, // make an offer / bid config.
      nftId: null,
      dateMsg: '',
      ethCheck: false,
      adminCheck: true,
      adminPayment: false,
      adminNftLimit: false,
      imageModal: false,
      selectedNft: '',
      numbersModal: false,
      modelNumber: 0,
      sellingNftId: '',
      sellingNftIdCheck: '',
      windowWeb3Check: true,
      cancelListingModal: false,
      cancelListingNftId: '',
      cancelListingType: '',
      bidModal: false,
      bidParams: {
        bidId: '',
        offerByAddress: '',
        price: '',
        payThrough: '',
        nftNumber: ''
      },
      offerModal: false,
      cancelOfferModal: false,
      cancelOfferItem: '',
      cancelBidModal: false,
      cancelBidItem: '',
      offerParams: {
        bidId: '',
        offerByAddress: '',
        price: '',
        payThrough: '',
        nftNumber: ''
      },
      sellingNftData: null
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      messages: {
        required: "This field is required.", // will override all messages
      },
    });
  }

  componentDidMount() {
    window.scroll(0, 0);
    const query = new URLSearchParams(window.location.search);
    if (query.get("activity")) {
      if (window.atob(query.get("item"))) {
        this.props.getSellingNftId(window.atob(query.get("item")))
      }
    }

    if (query.get('sellingNftId')) {
      this.setState({ sellingNftIdCheck: query.get('sellingNftId') })
      this.props.getSellingNft(query.get('sellingNftId'))
    }

    if (query.get("item")) {
      const nftId = window.atob(query.get("item"));
      if (query.get("adminCheck") === 'true') {
        this.setState({ nftId, adminCheck: false, adminPayment: true });
      }
      else {
        this.setState({ nftId });
      }

      if (nftId) {
        this.props.getNFT(nftId);

        const bidQS = ENV.objectToQueryString({ nftId });
        this.props.getBids(bidQS);

        const offerQS = ENV.objectToQueryString({ nftId });
        this.props.getOffers(offerQS);

        const historyQS = ENV.objectToQueryString({ nftId });
        this.props.getHistory(historyQS);
      }
      if (query.get("paymentId")) {
        this.setState({ loader: true });
      }

    } else this.props.history.push("/explore-all");
  }

  componentDidUpdate() {

    if (this.props.nft.refreshedDataAuth) {
      let owner = this.props.nft.refreshedData
      let updatedNft = this.state.nft
      updatedNft.owner = owner
      this.setState({ nft: updatedNft, loader: false })
      this.props.beforeNFT()
    }

    if (this.props.sellingNft.sellingNftIdAuth) {
      if (this.props.sellingNft.sellingNftId) {
        const query = new URLSearchParams(window.location.search);
        this.setState({ loader: true })
        document.location.replace(`item-details?item=${query.get('item')}&sellingNftId=${this.props.sellingNft.sellingNftId}`)
      }
      this.props.beforeSellingNft()
    }
    if (this.props.nft.nftsAuth) {
      const nft = this.props.nft.nftsData;
      const query = new URLSearchParams(window.location.search);

      if (this.props.sellingNft.sellingNftAuth) {
        let itemData = this.props.sellingNft.sellingNft
        nft.sellingMethod = itemData.type
        nft.allNfts = itemData.allNfts
        nft.currentPrice = itemData.currentPrice
        if (itemData.type === 1) {
          nft.sellingNfts = itemData.sellingNfts
        }
        else {
          nft.onAuctionSellingNfts = itemData.onAuctionSellingNfts
        }
        this.setState({ sellingNftId: itemData._id, sellingNftData: itemData })
        this.props.beforeSellingNft()
      }
      let data = {
        nft,
        loader: false
      }
      if (query.get("adminCheck") === 'true') {
        if (nft.soldNfts === nft.maxAllowed) {
          data.adminNftLimit = true
        }
      }

      this.setState(
        data,
        () => {
          if (this.state.nft.ownerCheck)
            this.setState({ adminCheck: false, adminPayment: true });
        }
      );
      this.props.beforeNFT();
    }

    if (this.props.app.web3Check) {
      if (this.state.nft) {
        if (this.state.windowWeb3Check) {
          const query = new URLSearchParams(window.location.search);
          if (query.get("paymentId")) {
            if (query.get("adminCheck") === 'true') {
              this.buyNowAdminNft(2, query.get("paymentId"), query.get("token"), query.get("PayerID"))
            }
            else {
              this.buyNowNft(2, query.get("paymentId"), query.get("token"), query.get("PayerID"))
            }
            this.setState({ windowWeb3Check: false })
            this.props.beforeWeb3Check()
          }
        }
      }
    }

    if (this.props.nft.transNftAuth) {
      this.props.beforeNFT();
      toast.success(
        "NFT Transferred successfully."
      );
      // window.location.href = ('/explore-all')
      this.props.history.push(`/explore-all`);
    }

    if (this.props.nft.transAdminNftAuth) {
      this.props.beforeNFT();
      // window.location.href = ('/explore-all')
      this.props.history.push(`/explore-all`);
    }

    if (this.props.nft.cancelListingAuth) {
      this.props.beforeNFT();
      // window.location.href = ('/explore-all')
      this.props.history.push(`/explore-all`);
    }

    if (this.props.offer.createAuth) {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          this.props.beforeOffer();
          // window.location.reload();
          this.props.history.push(`/explore-all`);
        }
      );
    }

    if (this.props.offer.getAuth) {
      const { offers, pagination } = this.props.offer;
      this.props.beforeOffer();

      if (offers.length) {
        this.setState(
          {
            loader: false,
            offers: [...this.state.offers, ...offers],
            offersPagination: pagination,
            moreCheck: true,
          },
          () => { }
        );
      } else {
        this.setState(
          { loader: false, offersPagination: pagination, moreCheck: false },
          () => { }
        );
      }
    }

    if (this.props.offer.deleteAuth) {
      this.props.beforeOffer();
      // window.location.reload();
      this.props.history.push(`/explore-all`);
    }

    if (this.props.bid.createAuth) {
      this.setState(
        {
          isOpen: false,
        },
        () => {
          this.props.beforeBid();
          // window.location.reload();
          this.props.history.push(`/explore-all`);
        }
      );
    }

    if (this.props.bid.getAuth) {
      const { bids, pagination, highestBid } = this.props.bid;
      let nft = { ...this.state.nft };
      nft.highestBid = highestBid;

      this.props.beforeBid();
      if (bids && bids.length) {
        this.setState(
          {
            loader: false,
            bids: [...this.state.bids, ...bids],
            bidsPagination: pagination,
            nft,
            moreCheck: false,
          },
          () => { }
        );
      } else {
        this.setState(
          { loader: false, bidsPagination: pagination, nft, moreCheck: false },
          () => { }
        );
      }
    }

    if (this.props.bid.deleteAuth) {
      this.props.beforeBid();
      this.props.history.push(`/explore-all`);
      // window.location.reload();
    }

    if (this.props.offer.acceptAuth) {
      this.setState({ loader: false }, () => {
        this.props.beforeOffer();
        const { nft } = this.state;
        this.props.history.push(
          `collection-details/${window.btoa(nft.collection._id)}`
        );
      });
    }

    if (this.props.bid.acceptAuth) {
      this.setState({ loader: false }, () => {
        this.props.beforeBid();
        const { nft } = this.state;
        this.props.history.push(
          `collection-details/${window.btoa(nft.collection._id)}`
        );
      });
    }

    if (this.props.nftHistory.getAuth) {
      const { history, pagination } = this.props.nftHistory;
      this.props.beforeHistory();

      if (history && history.length) {
        this.setState(
          {
            loader: false,
            nftHistory: [...this.state.nftHistory, history],
            nftHistoryPagination: pagination,
            moreCheck: true,
          },
          () => { }
        );
      } else {
        this.setState(
          { loader: false, nftHistoryPagination: pagination, moreCheck: false },
          () => { }
        );
      }
    }

    if (this.state.selectedNft && this.state.numbersModal) {
      document.querySelectorAll('.selling-nfts-count').forEach((item) => {
        item.classList.remove('sold-btn')
      })
      document.querySelector(`#selling-nft-${this.state.selectedNft}`).classList.add('sold-btn')
    }

    if (this.props.error) {
      this.setState({ loader: false })
      if (this.props.error.message === "getSellingNft Cannot read property '_id' of null") {
        const query = new URLSearchParams(window.location.search);
        document.location.replace(`item-details?item=${query.get('item')}`)
        return
      }
      toast.error(this.props.error.message)
      this.props.emptyError()
    }
  }

  getPrice = (currency, amount) => {
    if (currency === "BNB" || currency === "WBNB") {
      if (amount && this.props.app.rateAuth) {
        return ENV.convertBnbToUsd(amount, this.props.app.rate);
      } else {
        return "0.00";
      }
    } else if (currency === "ANN") {
      if (amount && this.props.app.annRateAuth) {
        return ENV.convertAnnToUsd(amount, this.props.app.annRate);
      } else {
        return "0.00";
      }
    }
  };

  openModal = () => {
    if (localStorage.getItem("encuse")) {
      if ((this.state.nft.sellingNfts && this.state.nft.sellingNfts.length) || (this.state.nft.onAuctionSellingNfts && this.state.nft.onAuctionSellingNfts.length)) {
        if (this.state.selectedNft)
          this.setState({ isOpen: true, nftConfig: config1, errors: "" });
        else
          toast.error("Please Select An NFT From, Choose Your Number")
      }
      else {
        this.setState({ isOpen: true, nftConfig: config1, errors: "" });
      }
    } else {
      this.props.history.push("/login");
    }
  };

  buyWithPayPal = () => {
    if (localStorage.getItem("encuse")) {
      if ((this.state.nft.sellingNfts && this.state.nft.sellingNfts.length) || (this.state.nft.onAuctionSellingNfts && this.state.nft.onAuctionSellingNfts.length)) {
        if (!this.state.selectedNft && !localStorage.getItem('selectedNft')) {
          toast.error("Please Select An NFT From, Choose Your Number")
          return
        }
      }
      const query = new URLSearchParams(window.location.search);
      const nftId = window.atob(query.get("item"));
      if (this.state.nft.currentPrice && this.props.app.rateAuth) {
        this.setState({ loader: true })
        let dollarPrice = ENV.convertBnbToUsd(
          this.state.nft.currentPrice,
          this.props.app.rate
        )
        localStorage.setItem("priceInDollars", dollarPrice)
        var formData = new FormData()
        formData.append('nftId', nftId)
        formData.append('dollarPrice', dollarPrice)
        if (query.get("adminCheck") === 'true')
          formData.append('adminCheck', 'true')
        if (query.get('sellingNftId'))
          formData.append('sellingNftId', query.get('sellingNftId'))
        if (this.state.selectedNft)
          localStorage.setItem('selectedNft', this.state.selectedNft)
        if ((this.state.nft.sellingNfts && this.state.nft.sellingNfts.length) || (this.state.nft.onAuctionSellingNfts && this.state.nft.onAuctionSellingNfts.length)) {
          if (this.state.nft.sellingMethod === 1) {
            localStorage.setItem('remainingNfts', JSON.stringify(this.state.nft.sellingNfts.filter((item) => { if (item !== this.state.selectedNft) { return (item) } else { return false } })))
          }
          else if (this.state.nft.sellingMethod === 2) {
            localStorage.setItem('remainingNfts', JSON.stringify(this.state.nft.onAuctionSellingNfts.filter((item) => { if (item !== this.state.selectedNft) { return (item) } else { return false } })))
          }
        }
        this.props.buyNft(formData)
      }
      else {
        toast.error("Something Went Wrong With Nft Price, Try Again")
      }
    } else {
      this.props.history.push("/login");
    }
  }

  closeModal = () => this.setState({ isOpen: false });

  onChange = (e) => {
    const { nftConfig } = this.state;
    const { name, value } = e.target;
    let data = nftConfig;

    if (value === 'ETH') {
      document.querySelector('input[name="nftConfig.price.amount"]').value = this.state.nft.currentPrice
      this.setState({
        ethCheck: true
      })
    }
    else if (value === 'WETH') {
      this.setState({
        ethCheck: false
      })
    }

    const keys = name.split("."); // nftConfig, price, currency
    if (keys && keys[2]) data[keys[1]][keys[2]] = value;
    else if (keys && keys[1]) data[keys[1]] = value;

    if (keys && keys[1] === "price" && keys[2] === "amount")
      data[keys[1]]["amount"] = Number(value);

    if (keys && keys[1] === "expiry" && keys[2] === "date")
      data[keys[1]]["type"] = Number(value) === -1 ? 2 : 1;

    // // set datetime for days / months
    if (data.expiry.type === 1) {
      if (!data.expiry.datetime) {
        data.expiry.datetime = moment(
          data.expiry.date + " " + data.expiry.time,
          "DD/MM/YYYY HH:mm"
        );
      }
    }
    if (e.target.id === "currency") {
      data.cAddress = e.target.selectedOptions[0].getAttribute("caddress");
      data.cabi = e.target.selectedOptions[0].getAttribute("cabi");
    }
    this.setState({
      nftConfig: {
        ...data,
      },
    });
  };

  // submit when a bid is placed or when an offer is made
  submit = async () => {
    this.setState(
      { isSubmitted: true, formValid: this.validator.allValid() },
      () => {
        const { formValid } = this.state;
        if (formValid) {
          if (this.state.nftConfig.expiry.type !== 1) {
            if (this.state.nftConfig.expiry.datetime === "") {
              const d = new Date();
              this.nftConfig.expiry.time = d.toUTCString();
              this.nftConfig.expiry.datetime = moment()
            }
          }
          if (this.state.nft.sellingMethod === 2) {
            if (this.state.nft.currentPrice > this.state.nftConfig.price.amount) {
              toast.error(
                "Your bid must be greater than or equal to current price of NFT."
              );
              return
            }
          }
          this.setState(
            {
              loader: true,
            },
            async () => {
              const { nft, nftConfig } = this.state;

              const query = new URLSearchParams(window.location.search);
              let sellingNftId = query.get('sellingNftId')

              let payload = {
                ownerId: nft.owner._id,
                expiryDate: nftConfig.expiry.datetime,
                price: nftConfig.price,
                nftId: nft._id,
                sellingNftId
              };

              if (this.state.selectedNft)
                payload.nftNumber = this.state.selectedNft

              let _nftData = {
                tokenId: nft.tokenId,
                price: nftConfig.price.amount,
                cabi: nftConfig.cabi,
                cAddress: nftConfig.cAddress,
              };

              let balanceOf = await checkBalance(_nftData);
              if (parseFloat(balanceOf) < nftConfig.price.amount) {
                toast.error(
                  "You account does not have sufficient amount of the selected token"
                );
                this.setState({ loader: false });
                return;
              }
              // if selling method is 2 then go for bid
              if (nft.sellingMethod === 2) {
                // place a bid
                let bidResponse = await placeBidWeb3(_nftData);
                if (bidResponse && bidResponse !== "web3Err") {
                  payload.txHash = bidResponse;
                  toast.success("Your bid is placed successfully");
                  this.props.placeBid(payload);
                } else if (bidResponse === "web3Err") {
                  this.setState({ loader: false });
                }
                else {
                  toast.error("Failed to place a bid");
                  this.setState({ loader: false });
                }
              } else if (nft.sellingMethod === 1) {
                // make an offer
                let offerResponse = await makeOfferWeb3(_nftData);
                if (offerResponse && offerResponse !== "web3Err") {
                  payload.txHash = offerResponse;
                  toast.success("Your offer is submitted successfully");
                  this.props.makeOffer(payload);
                } else if (offerResponse === "web3Err") {
                  this.setState({ loader: false });
                } else {
                  toast.error("Failed to make an offer");
                  this.setState({ loader: false });
                }
              }
            }
          );
        } else {
          this.validator.showMessages();
          this.validator.purgeFields();
          this.setState(
            {
              errors: "Please fill all required fields in valid format.",
              formValid: false,
            },
            () => {
              window.scroll(0, 0);
            }
          );
        }
      }
    );
  };

  deleteOffer = (offerId) => {
    this.setState(
      {
        loader: true,
      },
      async () => {
        const { nft } = this.state;
        let _nftData = {
          tokenId: nft.tokenId,
        };
        let cancelResponse = await cancelOfferBidWeb3(_nftData);
        if (cancelResponse && cancelResponse !== "web3Err") {
          toast.success("Your offer has been cancelled successfully");
          this.props.deleteOffer(offerId);
        } else if (cancelResponse === "web3Err") {
          this.setState({ loader: false });
        }
        else {
          toast.error("Failed to cancel the offer");
          this.setState({ loader: false });
        }
      }
    );
  };

  deleteBid = (bidId) => {
    this.setState(
      {
        loader: true,
      },
      async () => {
        const { nft } = this.state;
        let _nftData = {
          tokenId: nft.tokenId,
        };
        let cancelResponse = await cancelOfferBidWeb3(_nftData);
        if (cancelResponse && cancelResponse !== "web3Err") {
          toast.success("Your bid has been cancelled successfully");
          this.props.deleteBid(bidId);
        } else if (cancelResponse === "web3Err") {
          this.setState({ loader: false });
        } else {
          toast.error("Failed to cancel the bid");
          this.setState({ loader: false });
        }
      }
    );
  };

  acceptOffer = async (offerId, offerByAddress, price, payThrough, nftNumber) => {
    this.setState(
      {
        loader: true,
      },
      async () => {
        const { nft } = this.state;

        let _nftData = {
          metaData: nft.metaData
        }
        let buy = ''
        let txHash = ''
        const query = new URLSearchParams(window.location.search);
        if (nft.assetId) {
          let fData = new FormData()
          fData.append('name', this.state.nft.name + ' #' + String(this.state.selectedNft).padStart(3, '0'))
          fData.append('copies', this.state.nft.copies)
          fData.append('image', this.state.nft.image)
          fData.append('description', this.state.nft.description)
          _nftData['nftNumber'] = nftNumber
          _nftData['bidId'] = ''
          _nftData['offerId'] = offerId
          _nftData['sellingNftId'] = query.get('sellingNftId')

          let ipfs = await this.updateIpfs(fData)
          buy = await buyAndMintAssetByAccept(_nftData, offerByAddress, nft.assetId, price, ipfs)
          if (!buy) {
            this.setState({ loader: false });
          }

          txHash = buy.txHash

          if (txHash) {
            toast.success(
              "You have accepted the offer. The NFT will be transferred to the user shortly.              "
            );
            this.props.history.push("/explore-all");
          }
        }
        if (nft.tokenId) {
          _nftData.tokenId = nft.tokenId;
          _nftData['bidId'] = ''
          _nftData['offerId'] = offerId
          _nftData['sellingNftId'] = query.get('sellingNftId')
          buy = await transferByAccept(_nftData, offerByAddress, payThrough, price)
          if (!buy) {
            this.setState({ loader: false });
          }

          txHash = buy.txHash
          buy.tokenId = ''
          if (txHash) {
            toast.success(
              "You have accepted the offer. The NFT will be transferred to the user shortly.              "
            );
            this.props.history.push("/explore-all");
          }
        }

        if (!txHash) {
          toast.error("Failed to accept the offer");
          this.setState({ loader: false });
        }
      }
    );
  };

  acceptBid = async (bidId, offerByAddress, price, payThrough, nftNumber) => {
    this.setState(
      {
        loader: true,
      },
      async () => {
        const { nft } = this.state;

        let _nftData = {
          metaData: nft.metaData
        }
        let buy = ''
        let txHash = ''
        const query = new URLSearchParams(window.location.search);
        if (nft.assetId) {
          let fData = new FormData()
          fData.append('name', this.state.nft.name + ' #' + String(this.state.selectedNft).padStart(3, '0'))
          fData.append('copies', this.state.nft.copies)
          fData.append('image', this.state.nft.image)
          fData.append('description', this.state.nft.description)
          _nftData['nftNumber'] = nftNumber
          _nftData['bidId'] = bidId
          _nftData['offerId'] = ''
          _nftData['sellingNftId'] = query.get('sellingNftId')

          let ipfs = await this.updateIpfs(fData)
          buy = await buyAndMintAssetByAccept(_nftData, offerByAddress, nft.assetId, price, ipfs)
          if (!buy) {
            this.setState({ loader: false });
          }
          txHash = buy.txHash

          if (txHash) {
            toast.success(
              "You have accepted the bid. The NFT will be transferred to the user shortly.              "
            );
            this.props.history.push("/explore-all");
          }
        }
        if (nft.tokenId) {
          _nftData.tokenId = nft.tokenId;
          _nftData['bidId'] = bidId
          _nftData['offerId'] = ''
          _nftData['sellingNftId'] = query.get('sellingNftId')
          buy = await transferByAccept(_nftData, offerByAddress, payThrough, price)
          if (!buy) {
            this.setState({ loader: false });
          }
          txHash = buy.txHash
          buy.tokenId = ''

          if (txHash) {
            toast.success(
              "You have accepted the bid. The NFT will be transferred to the user shortly.              "
            );
            this.props.history.push("/explore-all");
          }
        }
        if (!txHash) {
          toast.error("Failed to accept the offer");
          this.setState({ loader: false });
        }
      }
    );
  };

  fetchData = () => {
    if (this.state.nftId) {
      if (this.state.rendType === 1) {
        const bidQS = ENV.objectToQueryString({
          nftId: this.state.nftId,
          page: this.state.page,
        });
        this.props.getBids(bidQS);
      } else if (this.state.rendType === 2) {
        const qs = ENV.objectToQueryString({
          nftId: this.state.nftId,
          page: this.state.page,
        });
        this.props.getOffers(qs);
      } else if (this.state.rendType === 3) {
        const qs = ENV.objectToQueryString({
          nftId: this.state.nftId,
          page: this.state.page,
        });
        this.props.getHistory(qs);
      }
    }
    let page = this.state.page + 1;
    this.setState({
      page,
    });
  };

  selNft = (itm) => {
    if (this.state.nft.sellingNfts.includes(itm)) {
      document.querySelectorAll('.selling-nfts-count').forEach((item) => {
        item.classList.remove('sold-btn')
      })
      document.querySelector(`#selling-nft-${itm}`).classList.add('sold-btn')
      this.setState({ selectedNft: itm })
    }
  }

  selAuctNft = (itm) => {
    if (this.state.nft.onAuctionSellingNfts.includes(itm)) {
      document.querySelectorAll('.selling-nfts-count').forEach((item) => {
        item.classList.remove('sold-btn')
      })
      document.querySelector(`#selling-nft-${itm}`).classList.add('sold-btn')
      this.setState({ selectedNft: itm })
    }
  }

  rendContent() {
    let { bids, offers, nftHistory, moreCheck, userId, nft } = this.state;
    if (this.state.adminCheck && this.state.rendType === 1 && new URLSearchParams(window.location.search).get("sellingNftId")) {
      return (
        <div className="tab-pane show active" id="bids">
          <ul className="list-unstyled">
            {bids && bids.length > 0 ? (
              <InfiniteScroll
                dataLength={bids.length}
                next={this.fetchData}
                hasMore={moreCheck}
                loader={<h4>Loading...</h4>}
              >
                {bids.map((item, idx) => {
                  return (
                    <li
                      key={`bid_${idx}`}
                      className="single-tab-list d-flex justify-content-between align-items-start w-100"
                    >
                      <img
                        className="avatar-sm rounded-circle mr-3"
                        src={item.bidBy.profilePhoto}
                        alt=""
                      />
                      <div className="d-flex justify-content-between flex-fill">
                        <p className="m-0 mr-3 flex-fill">
                          Bid listed for{" "}
                          <strong className="text-black">
                            {item.price.amount} {item.price.currency}
                            {
                              item.nftNumber ? ' ON NFT #' + String(item.nftNumber).padStart(3, '0') + ' ' : ''
                            }
                          </strong>{" "}
                          {moment(item.createdAt).fromNow()} <br />
                          by{" "}
                          <Link className="text-black d-flex" to={`/author/${item.bidBy._id}`}>
                            @{item.bidBy.username}
                            {
                              item.bidBy && item.bidBy.verificationCheck ?
                                <>
                                  <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </span>
                                  <ReactTooltip />
                                </>
                                : ''
                            }
                          </Link>
                        </p>
                        <div className="d-flex">
                          {userId === item.bidBy._id && (
                            <div
                              className="ml-auto"
                              onClick={() => this.setState({ cancelBidModal: true, cancelBidItem: item._id })}
                              data-toggle="tooltip"
                              title="Delete"
                            >
                              <i
                                className="cursor-pointer fa fa-times text-danger"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                          {userId === nft.owner?._id && (
                            <>
                              <div
                                className="ml-auto"
                                onClick={() => {
                                  this.setState({
                                    bidModal: true,
                                    bidParams: {
                                      bidId: item._id,
                                      offerByAddress: item.bidBy.address,
                                      price: item.price.amount,
                                      payThrough: ENV.tokenNameToValue[item.price.currency]
                                        ? ENV.tokenNameToValue[
                                        item.price.currency
                                        ]
                                        : 2,
                                      nftNumber: item.nftNumber
                                    }
                                  });
                                }}
                                data-toggle="tooltip"
                                title="Accept"
                              >
                                <i
                                  className="cursor-pointer fa fa-check text-success"
                                  aria-hidden="true"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </InfiniteScroll>
            ) : (
              <div className="mt-3">No bids found</div>
            )}
          </ul>
        </div>
      );
    } else if (this.state.adminCheck && this.state.rendType === 2 && new URLSearchParams(window.location.search).get("sellingNftId")) {
      return (
        <div className="tab-pane show active" id="offers">
          <ul className="list-unstyled">
            {offers && offers.length > 0 ? (
              <InfiniteScroll
                dataLength={offers.length}
                next={this.fetchData}
                hasMore={moreCheck}
                loader={<h4>Loading...</h4>}
              >
                {offers.map((item, idx) => {
                  return (
                    <li
                      key={`offer_${idx}`}
                      className="single-tab-list d-flex align-items-start w-100"
                    >
                      <img
                        className="avatar-sm rounded-circle mr-3"
                        src={item.offerBy.profilePhoto}
                        alt=""
                      />
                      <p className="m-0">
                        Offer listed for{" "}
                        <strong className="text-black">
                          {item.price.amount} {item.price.currency}
                          {
                            item.nftNumber ? ' ON NFT #' + String(item.nftNumber).padStart(3, '0') + ' ' : ''
                          }
                        </strong>{" "}
                        {moment(item.createdAt).fromNow()} <br />
                        by{" "}
                        <Link className="seller-author-name d-flex" to={`/author/${item.offerBy._id}`}>
                          @{item.offerBy.username}
                          {
                            item.offerBy && item.offerBy.verificationCheck ?
                              <>
                                <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                  <FontAwesomeIcon icon={faCheck} />
                                </span>
                                <ReactTooltip />
                              </>
                              : ''
                          }
                        </Link>
                      </p>
                      {userId === item.offerBy._id && (
                        <div
                          className="ml-auto"
                          onClick={() => {
                            this.setState({ cancelOfferModal: true, cancelOfferItem: item._id })
                          }}
                          data-toggle="tooltip"
                          title="Remove"
                        >
                          <i
                            className="cursor-pointer fa fa-times text-danger"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                      {userId === nft.owner?._id && (
                        <div
                          className="ml-auto"
                          onClick={() => {
                            this.setState({
                              offerModal: true,
                              offerParams: {
                                bidId: item._id,
                                offerByAddress: item.offerBy.address,
                                price: item.price.amount,
                                payThrough: ENV.tokenNameToValue[item.price.currency]
                                  ? ENV.tokenNameToValue[
                                  item.price.currency
                                  ]
                                  : 2,
                                nftNumber: item.nftNumber
                              }
                            });
                          }}
                          data-toggle="tooltip"
                          title="Accept"
                        >
                          <i
                            className="cursor-pointer fa fa-check text-success"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </li>
                  );
                })}
              </InfiniteScroll>
            ) : (
              <div className="mt-3">No offers found</div>
            )}
          </ul>
        </div>
      );
    } else if (this.state.adminCheck && this.state.rendType === 3) {
      return (
        <div className="tab-pane show active" id="history">
          <ul className="list-unstyled">
            {nftHistory[0] && nftHistory[0].length > 0 ? (
              <InfiniteScroll
                dataLength={nftHistory[0].length}
                next={this.fetchData}
                hasMore={moreCheck}
                loader={<h4>Loading...</h4>}
              >
                {nftHistory[0].map((item, idx) => {
                  return (
                    <li
                      key={`history_${idx}`}
                      className="single-tab-list d-flex align-items-start w-100"
                    >
                      {item.seller && (
                        <p className="m-0">
                          Ownership {item.copyCheck ? 'of one copy' : ''} transferred from{" "}
                          <Link to={`/author/${item.seller._id}`} className="text-black d-flex">
                            {item.seller.username} {
                              item.seller && item.seller.verificationCheck ?
                                <>
                                  <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </span>
                                  <ReactTooltip />
                                </>
                                : ''
                            }
                          </Link>{" "}
                          to{" "}
                          <Link to={`/author/${item.buyer._id}`} className="text-black d-flex">
                            {item.buyer.username} {
                              item.buyer && item.buyer.verificationCheck ?
                                <>
                                  <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </span>
                                  <ReactTooltip />
                                </>
                                : ''
                            }
                          </Link>{" "}
                          for{" "}
                          <strong className="text-black">
                            {item.price && item.price.amount ? item.price.currency === 'USD' ? ENV.convertBnbToUsd(item.price.amount, this.props.app.rate) : item.price.amount : ''} {item.price && item.price.currency ? item.price.currency : ''}
                          </strong>
                          {
                            item.txHash ?
                              <a target="_blank" className='text-theme' href={`${bscExplorerURL}${item.txHash}`} rel="noreferrer">
                                View Transaction Details
                              </a>
                              : ''
                          }
                        </p>
                      )}
                    </li>
                  );
                })}
              </InfiniteScroll>
            ) : (
              <div className="mt-3">No history found</div>
            )}
          </ul>
        </div>
      );
    } else if (this.state.adminCheck && this.state.rendType === 4) {
      return (
        <div className="tab-pane  show active" id="details">
          <div className="owner-meta d-flex align-items-center mt-3">
            <span>Owner</span>
            <Link
              className="owner d-flex align-items-center ml-2"
              to={`/author/${nft.owner?._id}`}
            >
              <img
                className="avatar-sm rounded-circle"
                src={
                  nft.owner?.profilePhotoLink
                    ? ENV.webUrl + nft.owner.profilePhotoLink
                    : globalPlaceholderImage
                }
                alt="img"
              />
              <h6 className="ml-2 d-flex">
                {nft.owner?.username}
                {
                  nft.owner && nft.owner.verificationCheck ?
                    <>
                      <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                      <ReactTooltip />
                    </>
                    : ''
                }
              </h6>
            </Link>
          </div>
          <p className="mt-2">
            Created : {moment(nft.createdAt).format("DD MMM YYYY")}
          </p>
          {nft.txHash && (
            <a target="_blank" href={`${bscExplorerURL}${nft.txHash}`} rel="noreferrer">
              View Transaction Details
            </a>
          )}
        </div>
      );
    }
  }

  rendIcon() {
    if (this.state.nftConfig.expiry.type === 1) {
      return (
        <span className="calendar-icon">
          <i className="fa fa-clock"></i>
        </span>
      )
    }
    else {
      return (
        <>
          <span className="calendar-icon">
            <i className={'fa fa-calendar'}></i>
          </span>
        </>
      )
    }
  }

  updateIpfs(body) {
    return axios.put(`${ENV.url}nfts/edit-ipfs`, body, {
      headers: {
        'Authorization': ENV.Authorization,
        'x-auth-token': ENV.x_auth_token,
        'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
      }
    })
      .then((res) => {
        if (res) {
          return res.data.ipfs
        }
      })
  }

  async buyNowNft(type, paymentId = '', token = '', payerId = '') {
    if (localStorage.getItem('encuse')) {
      if ((this.state.nft.sellingNfts && this.state.nft.sellingNfts.length) || (this.state.nft.onAuctionSellingNfts && this.state.nft.onAuctionSellingNfts.length)) {
        if (!this.state.selectedNft && !localStorage.getItem('selectedNft')) {
          toast.error("Please Select An NFT From, Choose Your Number")
          return
        }
      }
      this.setState({ loader: true }, () => { });
      let _nftData = {
        tokenId: this.state.nft.tokenId,
        price: this.state.nft.currentPrice,
        owner: this.state.nft.owner.address
      }

      if (type === 2)
        delete _nftData['price']

      const query = new URLSearchParams(window.location.search);
      let buy = ''
      let txHash = ''
      if (this.state.nft.tokenId) {
        _nftData['nftId'] = this.state.nft._id
        _nftData['currency'] = type
        _nftData['sellingMethod'] = this.state.nft.sellingMethod
        _nftData['sellingNftId'] = query.get('sellingNftId')
        let amount = localStorage.getItem('priceInDollars')
        if (amount) {
          _nftData['usdAmount'] = amount
        }
        txHash = await buyNow(_nftData, type)
        if (txHash) {
          toast.success(
            "Congratulations on the purchase of your shiny new NFT. It's on it's way, please check your gallery in 15 minutes"
          )
          this.props.history.push('/explore-all');
        }
      }
      if (this.state.nft.assetId) {
        _nftData['nftId'] = this.state.nft._id
        _nftData['ownerId'] = this.state.nft.owner._id
        _nftData['buyerId'] = ENV.getUserKeys("_id")._id
        _nftData['currency'] = type
        if (type == 2) {
          _nftData['selectedNft'] = parseInt(localStorage.getItem('selectedNft'))
          _nftData['assetNumber'] = parseInt(localStorage.getItem('selectedNft'))
        }
        else {
          _nftData['selectedNft'] = this.state.selectedNft
          _nftData['assetNumber'] = this.state.selectedNft
        }
        _nftData['sellingMethod'] = this.state.nft.sellingMethod
        _nftData['sellingNftId'] = query.get('sellingNftId')
        let amount = localStorage.getItem('priceInDollars')
        if (amount) {
          _nftData['usdAmount'] = amount
        }
        let fData = new FormData()
        fData.append('name', this.state.nft.name + ' #' + String(this.state.selectedNft).padStart(3, '0'))
        fData.append('copies', this.state.nft.copies)
        fData.append('image', this.state.nft.image)
        fData.append('description', this.state.nft.description)
        let ipfs = await this.updateIpfs(fData)
        buy = await buyAndMintAsset(_nftData, this.state.nft.assetId, type, ipfs, this.state.selectedNft)
        txHash = buy.txHash
        if (txHash) {
          toast.success(
            "Congratulations on the purchase of your shiny new NFT. It's on it's way, please check your gallery in 15 minutes"
          )
          this.props.history.push('/explore-all');
        }
      }
      if (!txHash || txHash === "web3Err") {
        this.setState({ loader: false }, () => {
          toast.error("Failed To Buy NFT");
        });
      }
    }
    else {
      this.props.history.push('/login');
    }
  }

  async buyNowAdminNft(type, paymentId = '', token = '', payerId = '') {
    if (localStorage.getItem('encuse')) {
      this.setState({ loader: true }, () => { });
      let _nftData = {
        assetId: this.state.nft.assetId,
        price: this.state.nft.currentPrice,
        owner: this.state.nft.owner.address
      }
      if (type === 2)
        delete _nftData['price']
      let buy = ''
      let txHash = ''
      if (this.state.nft.tokenId) {
        txHash = await buyNow(_nftData, type)
      }
      if (this.state.nft.assetId) {
        let fData = new FormData()
        fData.append('name', this.state.nft.name + ' #' + String(this.state.selectedNft).padStart(3, '0'))
        fData.append('copies', this.state.nft.copies)
        fData.append('image', this.state.nft.image)
        fData.append('description', this.state.nft.description)
        let ipfs = await this.updateIpfs(fData)
        buy = await buyAndMintAsset(_nftData, this.state.nft.assetId, type, ipfs, this.state.selectedNft)
        txHash = buy.txHash
      }

      const query = new URLSearchParams(window.location.search);

      if (txHash && txHash !== "web3Err") {
        let formData = new FormData()
        formData.append('nftId', this.state.nft._id)
        formData.append('sellingMethod', this.state.nft.sellingMethod)
        formData.append('sellingNftId', query.get('sellingNftId'))
        formData.append('buyerId', ENV.getUserKeys("_id")._id)
        formData.append('ownerId', this.state.nft.owner._id)
        formData.append('txHash', txHash)
        if (buy.tokenId)
          formData.append('tokenId', buy.tokenId)
        if (paymentId && token && payerId) {
          formData.append('paymentId', paymentId)
          formData.append('token', token)
          formData.append('payerId', payerId)
          let amount = localStorage.getItem('priceInDollars')
          formData.append('amount', amount)
          formData.append('currency', 'USD')
        }
        else {
          formData.append('amount', this.state.nft.currentPrice)
          formData.append('currency', ENV.currency)
        }

        if (this.state.selectedNft)
          formData.append('selectedNft', this.state.selectedNft)

        if ((this.state.nft.sellingNfts && this.state.nft.sellingNfts.length) || (this.state.nft.onAuctionSellingNfts && this.state.nft.onAuctionSellingNfts.length)) {
          if (!localStorage.getItem('remainingNfts')) {
            if (this.state.nft.sellingMethod === 1) {
              formData.append('remainingNfts', JSON.stringify(this.state.nft.sellingNfts.filter((item) => { if (item !== this.state.selectedNft) { return (item) } else { return false } })))
            }
            else if (this.state.nft.sellingMethod === 2) {
              formData.append('remainingNfts', JSON.stringify(this.state.nft.onAuctionSellingNfts.filter((item) => { if (item !== this.state.selectedNft) { return (item) } else { return false } })))
            }
          }
        }
        if (localStorage.getItem('selectedNft')) {
          formData.append('selectedNft', localStorage.getItem('selectedNft'))
          localStorage.removeItem('selectedNft')
        }
        if (localStorage.getItem('remainingNfts')) {
          formData.append('remainingNfts', localStorage.getItem('remainingNfts'))
          localStorage.removeItem('remainingNfts')
        }

        toast.success(
          "NFT Transferred Successfully."
        );
        this.props.transferAdminNft(formData);
      } else if (txHash === "web3Err") {
        this.setState({ loader: false });
      } else {
        this.setState({ loader: false }, () => {
          toast.error("Failed To Buy NFT");
        });
      }
    }
    else {
      this.props.history.push('/login');
    }
  }

  showImageModal() {
    this.setState({ imageModal: true })
  }

  hideImageModal() {
    this.setState({ imageModal: false })
  }

  hideCancelModal() {
    this.setState({ cancelListingModal: false })
  }

  showNumbersModal() {
    this.setState({ numbersModal: true })
  }

  hideNumbersModal() {
    this.setState({ numbersModal: false })
    switch (this.state.modelNumber) {
      case 1:
        this.openModal()
        break;
      case 2:
        this.buyNowNft(1);
        break;
      case 3:
        this.buyWithPayPal()
        break;
      default: { }
    }
  }

  hideChooseNumberModal() {
    this.setState({ numbersModal: false })
  }

  async cancelListing(nftId, type) {
    this.setState({ loader: true, cancelListingModal: false })
    if (this.state.nft.assetId) {
      let _nftData = {
        assetId: this.state.nft.assetId,
      }
      let PutOffSale = await putOffSale(_nftData)
      if (PutOffSale) {
        let formData = new FormData();
        formData.append('nftId', nftId);
        formData.append('type', type);
        this.props.cancelListing(formData)
      }
      else {
        this.setState({ loader: false })
      }
    }
    else {
      let formData = new FormData();
      formData.append('nftId', nftId);
      formData.append('type', type);
      this.props.cancelListing(formData)
    }
  }

  refreshMetaData(tokenId) {
    this.setState({ loader: true })
    let formData = new FormData();
    const query = new URLSearchParams(window.location.search);
    formData.append('nftId', query.get('item'));
    formData.append('tokenId', tokenId);
    this.props.refreshMetaData(formData)
  }


  render() {
    const { loader, nft, nftConfig, isSubmitted, errors, userId } = this.state;

    const currentDate = moment(new Date(), 'YYYY-MM-DD HH:mm:ss:SSS')
    const nftEndDate = moment(new Date(nft?.auctionEndDate), 'YYYY-MM-DD HH:mm:ss:SSS')
    const isAuctionPast = nftEndDate.isBefore(currentDate)

    return (
      <>
        <section className="item-details-area padding-wrapper pb-0">
          {loader ? (
            <FullPageLoader />
          ) : (
            <>
              {nft && (
                <div className="container">
                  <div className="row justify-content-between">
                    <div className="col-12 col-lg-5">
                      <div className="item-info">
                        <div className="item-thumb text-center cursor-pointer" onClick={() => { this.showImageModal() }}>
                          <img src={ENV.webUrl + nft.nftImageLink} alt="img" />
                        </div>
                        {
                          nft.auctionEndDate && (new Date() < (Date.parse(nft.auctionEndDate) + 10000)) &&
                          <div className="card no-hover countdown-times mt-4">
                            <Countdown
                              date={Date.parse(nft.auctionEndDate) + 10000}
                              renderer={countDownRenderer}
                            />
                          </div>
                        }
                        {/* Tabs */}
                        {
                          <ul
                            className="netstorm-tab nav nav-tabs mt-4"
                            id="nav-tab"
                          >
                            {
                              this.state.adminCheck && new URLSearchParams(window.location.search).get("sellingNftId") &&
                              <li>
                                <a
                                  className={
                                    this.state.rendType === 1 ? "active" : ""
                                  }
                                  id="bids-tab"
                                  data-toggle="pill"
                                  href="#bids"
                                  onClick={() => {
                                    this.setState({
                                      rendType: 1,
                                      page: 2,
                                      moreCheck: true,
                                    });
                                  }}
                                >
                                  <h5 className="m-0">Bids</h5>
                                </a>
                              </li>
                            }
                            {
                              this.state.adminCheck && new URLSearchParams(window.location.search).get("sellingNftId") &&
                              <li>
                                <a
                                  className={
                                    this.state.rendType === 2 ? "active" : ""
                                  }
                                  id="offers-tab"
                                  data-toggle="pill"
                                  href="#offers"
                                  onClick={() => {
                                    this.setState({
                                      rendType: 2,
                                      page: 2,
                                      moreCheck: true,
                                    });
                                  }}
                                >
                                  <h5 className="m-0">Offers</h5>
                                </a>
                              </li>
                            }
                            {
                              this.state.adminCheck &&
                              <li>
                                <a
                                  className={
                                    this.state.rendType === 3 ? "active" : ""
                                  }
                                  id="history-tab"
                                  data-toggle="pill"
                                  href="#history"
                                  onClick={() => {
                                    this.setState({
                                      rendType: 3,
                                      page: 2,
                                      moreCheck: true,
                                    });
                                  }}
                                >
                                  <h5 className="m-0">History</h5>
                                </a>
                              </li>
                            }
                            {
                              this.state.adminCheck &&
                              <li>
                                <a
                                  className={
                                    this.state.rendType === 4 ? "active" : ""
                                  }
                                  id="details-tab"
                                  data-toggle="pill"
                                  href="#details"
                                  onClick={() => {
                                    this.setState({ rendType: 4 });
                                  }}
                                >
                                  <h5 className="m-0">Details</h5>
                                </a>
                              </li>
                            }
                          </ul>
                        }
                        {/* Tab Content */}
                        {
                          <div className="tab-content" id="nav-tabContent">
                            {this.rendContent()}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="content mt-5 mt-lg-0">
                        <div className="d-flex align-items-start justify-content-between flex-column flex-md-row">
                          <h3 className="mx-0 mt-0 mb-3 mb-md-0">{nft.name}{nft.nftNumber ? ' #' + String(nft.nftNumber).padStart(3, '0') : ''}</h3>
                          {
                            nft && nft.tokenId !== undefined ?
                              <Button
                                className="d-flex btn btn-bordered-white2 btn-refresh-metadata justify-content-center"
                                onClick={() => {
                                  this.refreshMetaData(nft.tokenId)
                                }}
                              >
                                <FontAwesomeIcon className="mr-2" icon={faSyncAlt} />
                                <span className="d-block">Refresh metaData</span>
                              </Button>
                              : ''
                          }
                        </div>
                        <p>{nft.description}</p>

                        {/* Owner */}
                        <div className="owner d-flex align-items-center">
                          <span>Owned By</span>
                          <Link
                            className="owner-meta d-flex align-items-center ml-3"
                            to={`/author/${nft.owner?._id}`}
                          >
                            <img
                              className="avatar-sm rounded-circle"
                              src={
                                nft.owner && nft.owner.profilePhotoLink !== undefined ?
                                  ENV.webUrl + nft.owner.profilePhotoLink
                                  : globalPlaceholderImage
                              }
                              alt="Owner Avatar"
                            />
                            <h6 className="ml-2">{nft.owner?.username}</h6>
                            {
                              nft.owner && nft.owner.verificationCheck ?
                                <>
                                  <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </span>
                                  <ReactTooltip />
                                </>
                                : ''
                            }
                          </Link>
                        </div>
                        {/* Token Id */}
                        {
                          nft && nft.tokenId !== undefined ?
                            <div className="owner d-flex align-items-center">
                              <span>Token Id:</span>
                              <h6 className="ml-2">{nft.tokenId}</h6>
                            </div>
                            : ''
                        }
                        {/* Item Info List */}
                        <div className="item-info-list mt-4">
                          <ul className="list-unstyled">
                            <li className="price d-flex justify-content-between">
                              <span>
                                Current Price {nft.currentPrice || 0}{" "}
                                {ENV.currency}
                              </span>
                              <span>
                                US${nft.currentPrice && this.props.app.rateAuth
                                  ? ENV.convertBnbToUsd(
                                    nft.currentPrice,
                                    this.props.app.rate
                                  )
                                  : "0.00"}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="items-det row items">
                          {/* Creator */}
                          <div className="col-12 col-md-6 item px-lg-2">
                            <div className="card no-hover">
                              <div className="single-seller d-flex align-items-center">
                                <Link to={`/author/${nft.creator?._id}`}>
                                  <img
                                    className="avatar-md rounded-circle"
                                    src={
                                      nft.creator && nft.creator.profilePhotoLink !== undefined ?
                                        ENV.webUrl + nft.creator.profilePhotoLink :
                                        globalPlaceholderImage
                                    }
                                    alt="Creator Avatar"
                                  />
                                </Link>
                                <div className="seller-info ml-3">
                                  <Link
                                    className="seller mb-2 d-flex"
                                    to={`/author/${nft.creator?._id}`}
                                    data-effect="float"
                                    data-tip={nft.creator?.username}
                                  >
                                    {nft.creator?.username}
                                    {
                                      nft.creator && nft.creator.verificationCheck ?
                                        <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                          <FontAwesomeIcon icon={faCheck} />
                                        </span>
                                        : ''
                                    }
                                  </Link>
                                  <span>Creator</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Collection */}
                          {
                            // this.state.nft.collectionCheck ?
                            <div className="col-12 col-md-6 item px-lg-2">
                              <div className="card no-hover">
                                <div className="single-seller d-flex align-items-center">
                                  <Link
                                    to={`collection-details/${window.btoa(
                                      nft.collection?._id
                                    )}`}
                                  >
                                    <img
                                      className="avatar-md rounded-circle"
                                      src={nft.collection && nft.collection.imageLink !== undefined ? ENV.webUrl + nft.collection.imageLink : globalPlaceholderImage}
                                      alt="img"
                                    />
                                  </Link>
                                  <div className="seller-info ml-3">
                                    <Link
                                      className="seller mb-2"
                                      to={`collection-details/${window.btoa(
                                        nft.collection?._id
                                      )}`}
                                      data-effect="float"
                                      data-tip={nft.collection?.name}
                                    >
                                      {nft.collection?.name}
                                    </Link>
                                    <span>Collection</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            // : ''
                          }
                          {
                            this.state.adminCheck ?
                              <div className="col-12 item px-lg-2">
                                <div className="card no-hover">
                                  <h4 className="mt-0 mb-2">Highest Bid</h4>
                                  <div className="price d-flex justify-content-between align-items-center">
                                    <span>
                                      {nft.highestBid
                                        ? `${nft.highestBid?.amount} ${nft.highestBid?.currency}`
                                        : "N/A"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              : ''
                          }
                          <ReactTooltip />
                        </div>
                        {nft.owner?._id === userId ? (
                          <>
                            {(!nft.sellingMethod || (nft.avaliableNfts && nft.avaliableNfts.length)) ? (
                              <Link
                                className="d-block btn btn-bordered-white mt-4 mb-4 w-100"
                                to={`/sell-item/${window.btoa(nft._id)}`}
                              >
                                {initData.sellBtn}
                              </Link>
                            ) : ''}
                            {
                              new URLSearchParams(window.location.search).get('sellingNftId') &&
                              <Button
                                className="d-block sold-btn btn btn-bordered-white2 mt-4 w-100"
                                onClick={() => {
                                  this.setState({
                                    cancelListingNftId: nft._id,
                                    cancelListingType: nft.sellingMethod,
                                    cancelListingModal: true
                                  });
                                }}
                              >
                                Cancel Listing
                              </Button>
                            }
                          </>
                        ) : (
                          <>
                            {nft.sellingMethod && this.state.sellingNftIdCheck && !this.state.adminNftLimit &&
                              !isAuctionPast && this.state.adminCheck ?
                              (
                                <>
                                  <button
                                    className="d-block btn btn-bordered-white2 mt-4 w-100"
                                    onClick={() => {
                                      if ((nft.sellingNfts && nft.sellingNfts.length) || (nft.onAuctionSellingNfts && nft.onAuctionSellingNfts.length)) {
                                        this.setState({ modelNumber: 1 })
                                        this.showNumbersModal()
                                      }
                                      else {
                                        this.openModal()
                                      }
                                    }}
                                  >
                                    {
                                      ((nft.sellingNfts && nft.sellingNfts.length) || ((nft.onAuctionSellingNfts && nft.onAuctionSellingNfts.length))) ?
                                        'Choose Your #Number & '
                                        : ''
                                    }
                                    {nft.sellingMethod === 2
                                      ? initData.bidBtn
                                      : initData.offerBtn}
                                  </button>
                                </>
                              ) : ''}
                          </>
                        )}
                        <>
                          {nft.owner?._id !== userId && nft.sellingMethod === 1 && this.state.sellingNftIdCheck && !this.state.adminNftLimit && this.state.adminCheck ? (
                            <>
                              <button
                                className="d-block btn btn-bordered-white2 mt-4 w-100"
                                onClick={() => {
                                  if ((nft.sellingNfts && nft.sellingNfts.length) || ((nft.onAuctionSellingNfts && nft.onAuctionSellingNfts.length))) {
                                    this.setState({ modelNumber: 2 })
                                    this.showNumbersModal()
                                  }
                                  else {
                                    this.buyNowNft(1)
                                  }
                                }}
                              >
                                {
                                  ((nft.sellingNfts && nft.sellingNfts.length) || ((nft.onAuctionSellingNfts && nft.onAuctionSellingNfts.length))) ?
                                    'Choose Your #Number & '
                                    : ''
                                }
                                Buy Now ({this.state.nft.currentPrice.toFixed(10) + ' ' + ENV.currency})
                              </button>
                            </>
                          ) : ''}
                        </>
                        <>
                          {this.state.adminPayment && this.state.sellingNftIdCheck && !this.state.adminNftLimit ? ( //Belongs To Admin
                            <>
                              <button
                                className="d-block btn btn-bordered-white2 mt-4 w-100"
                                onClick={() => {
                                  if ((nft.sellingNfts && nft.sellingNfts.length) || ((nft.onAuctionSellingNfts && nft.onAuctionSellingNfts.length))) {
                                    this.setState({ modelNumber: 2 })
                                    this.showNumbersModal()
                                  }
                                  else {
                                    this.buyNowAdminNft(1)
                                  }
                                }}
                              >
                                {
                                  ((nft.sellingNfts && nft.sellingNfts.length) || ((nft.onAuctionSellingNfts && nft.onAuctionSellingNfts.length))) ?
                                    'Choose Your #Number & '
                                    : ''
                                }
                                Buy Now ({this.state.nft.currentPrice ? this.state.nft.currentPrice.toFixed(10) + ' ' + ENV.currency : ''})
                              </button>
                            </>
                          ) : ''}
                        </>
                        <>
                          {nft.owner?._id !== userId && nft.sellingMethod === 1 && this.state.sellingNftIdCheck && !this.state.adminNftLimit && this.state.adminCheck ? (
                            <>
                              <button
                                className="d-block btn btn-bordered-white2 mt-4 w-100"
                                onClick={() => {
                                  if ((nft.sellingNfts && nft.sellingNfts.length) || ((nft.onAuctionSellingNfts && nft.onAuctionSellingNfts.length))) {
                                    this.setState({ modelNumber: 3 })
                                    this.showNumbersModal()
                                  }
                                  else {
                                    this.buyWithPayPal()
                                  }
                                }}
                              >
                                {
                                  ((nft.sellingNfts && nft.sellingNfts.length) || ((nft.onAuctionSellingNfts && nft.onAuctionSellingNfts.length))) ?
                                    'Choose Your #Number & '
                                    : ''
                                }
                                Buy With Credit Card/PayPal
                              </button>
                            </>
                          ) : ''}
                        </>
                        <>
                          {this.state.adminPayment && this.state.sellingNftIdCheck && !this.state.adminNftLimit ? ( //Belongs To Admin
                            <>
                              <button
                                className="d-block btn btn-bordered-white2 mt-4 w-100"
                                onClick={() => {
                                  if ((nft.sellingNfts && nft.sellingNfts.length) || ((nft.onAuctionSellingNfts && nft.onAuctionSellingNfts.length))) {
                                    this.setState({ modelNumber: 3 })
                                    this.showNumbersModal()
                                  }
                                  else {
                                    this.buyWithPayPal()
                                  }
                                }}
                              >
                                {
                                  ((nft.sellingNfts && nft.sellingNfts.length) || ((nft.onAuctionSellingNfts && nft.onAuctionSellingNfts.length))) ?
                                    'Choose Your #Number & '
                                    : ''
                                }
                                Buy With Credit Card/PayPal
                              </button>
                            </>
                          ) : ''}
                        </>
                        <>
                          {this.state.adminNftLimit ? (
                            <>
                              <button
                                className="d-block sold-btn btn btn-bordered-white2 mt-4 w-100"
                              >
                                Sold
                              </button>
                            </>
                          ) : ''}
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </section>
        <div className="modal-wrapper">
          {!this.state.loader && nft && (
            <Modal
              centered
              size="lg"
              className="offer-modal placebid-model"
              show={this.state.isOpen}
              onHide={this.closeModal}
            >
              <Modal.Header className="text-center modal-title-wrapper" closeButton>
                <Modal.Title>
                  {nft.sellingMethod === 2 ? "Place A Bid" : "Make An Offer"}
                </Modal.Title>
              </Modal.Header>
              {isSubmitted && errors && (
                <Modal.Body className="row pt-2 pb-0">
                  <div className="col-12">
                    <span id="nft-err" className="form-error-msg text-danger">
                      {errors}
                    </span>
                  </div>
                </Modal.Body>
              )}
              <Modal.Body>
                <div className="price-wrapper d-flex">
                  <div className="price-text position-relative">
                    <div className="mb-2 absolute-wrapper">
                      <b>Price</b>
                    </div>
                    <div
                      className="text-right mb-2"
                      data-toggle="tooltip"
                      title="lorem"
                    >
                      <i className="fas fa-exclamation-circle ml-2" />
                    </div>
                    <div className="d-flex flex-column flex-sm-row">
                      <div className="custom-select-holder offer-modal-select">
                        <select
                          className="form-control"
                          id="currency-select"
                          name="nftConfig.price.currency"
                          onChange={(e) => this.onChange(e)}
                        >
                          {currencies &&
                            currencies.map((currency, index) => {
                              return (
                                <option
                                  key={index}
                                  value={currency.symbol}
                                  caddress={currency.address}
                                  cabi={JSON.stringify(currency.abi)}
                                >
                                  {currency.symbol}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="offer-amount-holder">
                        <input
                          type="text"
                          placeholder="Amount"
                          className="amount-btn ml-0 ml-sm-3"
                          name="nftConfig.price.amount"
                          style={{ borderRadius: "4px" }}
                          onChange={(e) => this.onChange(e)}
                          onKeyDown={(e) => decimalNumberValidator(e)}
                          defaultValue={nftConfig.price.amount}
                          required
                        />
                        <div className="text-right mb-2 dollar-wrapper">
                          US$
                          {
                            nftConfig.price.amount && this.props.app.rateAuth ?
                              ENV.convertBnbToUsd(nftConfig.price.amount, this.props.app.rate) :
                              "0.00"
                          }
                          {
                            this.getPrice(
                              nftConfig.price.currency,
                              nftConfig.price.amount
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Body className="text-danger">
                {this.validator.message(
                  "amount",
                  nftConfig.price.amount,
                  "required"
                )}
              </Modal.Body>
              <Modal.Body
                className=""
                style={{
                  flexDirection: "column",
                  display: "block",
                  height: "auto",
                }}
              >
                <div className="offer-expiration-wrapper mb-2">
                  <b>Offer Expiration</b>
                </div>
                <div>
                  <div className="d-flex flex-column flex-sm-row">
                    <div className="custom-select-holder offer-modal-select">
                      <select className="form-control w-100" id="expiryDate" name="nftConfig.expiry.date" onChange={(e) => this.onChange(e)}
                      >
                        {expiryOptions &&
                          expiryOptions.map((expiry, index) => {
                            return (
                              <option key={index} value={expiry.value}>
                                {expiry.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="mr-auto budle-wrapper date-time position-relative">
                      {this.rendIcon()}
                      {
                        nftConfig.expiry.type === 1 ?
                          <Datetime
                            value={moment(nftConfig.expiry.time, 'hh:mm A').format('hh:mm A')}
                            onChange={(e) => {
                              const { nftConfig } = this.state;
                              nftConfig.expiry.time = e._d
                              nftConfig.expiry.datetime = e
                              this.setState({
                                nftConfig
                              })
                            }}
                            dateFormat={false}
                            inputProps={{ placeholder: "Select Time" }}
                          />
                          :
                          <Datetime
                            isValidDate={(current) => current.isAfter(moment().subtract(1, "day"))}
                            onChange={(e) => {
                              if (new Date() < e._d) {
                                const { nftConfig } = this.state;
                                nftConfig.expiry.time = e._d
                                nftConfig.expiry.datetime = e
                                this.setState({
                                  nftConfig,
                                  dateMsg: ''
                                })
                              }
                              else {
                                this.setState({
                                  dateMsg: 'Please enter valid date & time.'
                                })
                              }
                            }}
                            closeOnSelect={true}
                            inputProps={{ placeholder: "Select Date & Time" }}
                          />
                      }
                    </div>
                    <span className="text-danger message">
                      {this.validator.message(
                        "expiryTime",
                        nftConfig.expiry.time,
                        "required"
                      )}
                    </span>
                    <span className="text-danger message">
                      {this.state.dateMsg}
                    </span>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Body className="justify-content-center align-items-center">
                <div className="d-flex justify-content-around">
                  {
                    <button
                      className="btn btn-primary mr-3 mt-1 mb-3"
                      disabled={loader}
                      onClick={() => this.submit()}
                    >
                      {
                        nft.sellingMethod === 2
                          ? initData.bidBtn
                          : initData.offerBtn
                      }
                    </button>
                  }
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="d-block btn close-button"
                  onClick={this.closeModal}
                >
                  Close
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
        <div className="modal-wrapper">
          {
            !this.state.loader && nft &&
            <Modal
              centered
              size="lg"
              className="offer-modal placebid-model"
              show={this.state.imageModal}
              onHide={() => { this.hideImageModal() }}
            >
              <Modal.Header className="text-center modal-title-wrapper" closeButton>
                <Modal.Title>
                  {nft.name}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  flexDirection: "column",
                  display: "block",
                  height: "auto",
                }}
              >
                <div className="offer-expiration-wrapper mb-2">
                  <div className="p-3 item-thumb text-center">
                    <img src={ENV.webUrl + nft.nftImageLink} alt="img" />
                  </div>
                  <div className="p-3">
                    {nft.description}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="d-block btn close-button"
                  onClick={() => { this.hideImageModal() }}
                >
                  Close
                </button>
              </Modal.Footer>
            </Modal>
          }
        </div>
        <div className="modal-wrapper">
          {
            !this.state.loader && nft && this.state.sellingNftData &&
            <Modal
              centered
              size="lg"
              className="offer-modal placebid-model"
              show={this.state.numbersModal}
              onHide={() => { this.hideChooseNumberModal() }}
            >
              <Modal.Header className="text-center modal-title-wrapper" closeButton>
                <Modal.Title>
                  Choose Your Number
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  flexDirection: "column",
                  display: "block",
                  height: "auto",
                }}
              >
                {
                  nft.sellingMethod === 1 ?
                    this.state.sellingNftData.sellingNfts && this.state.sellingNftData.sellingNfts.length ?
                      <div className="pt-3 pb-5">
                        <div className="pb-5">
                          Select NFT Number
                        </div>
                        {
                          this.state.sellingNftData.allNfts.map((item, index) => {
                            return (
                              <span key={index}
                                className={`mb-2 mr-2 cursor-pointer 
                                    selling-nfts-count d-inline-block align-top 
                                    ${this.state.sellingNftData.sellingNfts.includes(item) ? `` : 'disabled'}
                                    `}
                                id={`selling-nft-${item}`}
                                onClick={() => { this.selNft(item); }}
                              >
                                {item}
                              </span>
                            )
                          })
                        }
                      </div>
                      :
                      'Please Reload The Page.'
                    : ''
                }
                {
                  nft.sellingMethod === 2 ?
                    this.state.sellingNftData.onAuctionSellingNfts && this.state.sellingNftData.onAuctionSellingNfts.length ?
                      <div className="pt-3 pb-5">
                        <div className="pb-5">
                          Select NFT Number
                        </div>
                        {
                          this.state.sellingNftData.allNfts.map((item, index) => {
                            return (
                              <span key={index}
                                className={`mb-2 mr-2 cursor-pointer 
                                  selling-nfts-count d-inline-block align-top 
                                  ${this.state.sellingNftData.onAuctionSellingNfts.includes(item) ? `` : 'disabled'}
                                  `}
                                id={`selling-nft-${item}`}
                                onClick={() => { this.selAuctNft(item); }}
                              >
                                {item}
                              </span>
                            )
                          })
                        }
                      </div>
                      :
                      'Please Reload The Page.'
                    : ''
                }
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="d-block btn close-button"
                  onClick={() => { this.hideNumbersModal() }}
                >
                  Select
                </button>
              </Modal.Footer>
            </Modal>
          }
        </div>
        <div className="modal-wrapper">
          {
            <Modal
              centered
              size="lg"
              className="placebid-model delete-modal"
              show={this.state.cancelListingModal}
              onHide={() => { this.hideCancelModal() }}
            >
              <Modal.Header className="text-center modal-title-wrapper" closeButton>
                <Modal.Title className="text-white">
                  {'Are You Sure, You Want To Cancel Listing Of This NFT ?'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  flexDirection: "column",
                  display: "block",
                  height: "auto",
                }}
              >
                <div className="p-3">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm">
                        <button
                          className="d-block btn close-button"
                          onClick={() => { this.hideCancelModal(); }}
                        >
                          No
                        </button>
                      </div>
                      <div class="col-sm">
                        <button
                          className="d-block btn close-yes-button"
                          onClick={() => {
                            this.cancelListing(this.state.cancelListingNftId, this.state.cancelListingType);
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          }
        </div>
        <div className="modal-wrapper">
          {
            <Modal
              centered
              size="lg"
              className="placebid-model delete-modal"
              show={this.state.bidModal}
              onHide={() => { this.setState({ bidModal: false }) }}
            >
              <Modal.Header className="text-center modal-title-wrapper" closeButton>
                <Modal.Title className="text-white">
                  {'Are You Sure, You Want To Accept This Bid ?'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  flexDirection: "column",
                  display: "block",
                  height: "auto",
                }}
              >
                <div className="p-3">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm">
                        <button
                          className="d-block btn close-button"
                          onClick={() => { this.setState({ bidModal: false }) }}
                        >
                          No
                        </button>
                      </div>
                      <div class="col-sm">
                        <button
                          className="d-block btn close-yes-button"
                          onClick={() => { this.setState({ bidModal: false }); this.acceptBid(this.state.bidParams.bidId, this.state.bidParams.offerByAddress, this.state.bidParams.price, this.state.bidParams.payThrough, this.state.bidParams.nftNumber); }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          }
        </div>
        <div className="modal-wrapper">
          {
            <Modal
              centered
              size="lg"
              className="placebid-model delete-modal"
              show={this.state.offerModal}
              onHide={() => { this.setState({ offerModal: false }) }}
            >
              <Modal.Header className="text-center modal-title-wrapper" closeButton>
                <Modal.Title className="text-white">
                  {'Are You Sure, You Want To Accept This Offer ?'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  flexDirection: "column",
                  display: "block",
                  height: "auto",
                }}
              >
                <div className="p-3">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm">
                        <button
                          className="d-block btn close-button"
                          onClick={() => { this.setState({ offerModal: false }) }}
                        >
                          No
                        </button>
                      </div>
                      <div class="col-sm">
                        <button
                          className="d-block btn close-yes-button"
                          onClick={() => { this.setState({ offerModal: false }); this.acceptOffer(this.state.offerParams.bidId, this.state.offerParams.offerByAddress, this.state.offerParams.price, this.state.offerParams.payThrough, this.state.offerParams.nftNumber); }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          }
        </div>
        <div className="modal-wrapper">
          {
            <Modal
              centered
              size="lg"
              className="placebid-model delete-modal"
              show={this.state.cancelOfferModal}
              onHide={() => { this.setState({ cancelOfferModal: false }) }}
            >
              <Modal.Header className="text-center modal-title-wrapper" closeButton>
                <Modal.Title className="text-white">
                  {'Are You Sure, You Want To Cancel This Offer ?'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  flexDirection: "column",
                  display: "block",
                  height: "auto",
                }}
              >
                <div className="p-3">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm">
                        <button
                          className="d-block btn close-button"
                          onClick={() => { this.setState({ cancelOfferModal: false }) }}
                        >
                          No
                        </button>
                      </div>
                      <div class="col-sm">
                        <button
                          className="d-block btn close-yes-button"
                          onClick={() => { this.deleteOffer(this.state.cancelOfferItem); this.setState({ cancelOfferModal: false }); }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          }
        </div>
        <div className="modal-wrapper">
          {
            <Modal
              centered
              size="lg"
              className="placebid-model delete-modal"
              show={this.state.cancelBidModal}
              onHide={() => { this.setState({ cancelBidModal: false }) }}
            >
              <Modal.Header className="text-center modal-title-wrapper" closeButton>
                <Modal.Title className="text-white">
                  {'Are You Sure, You Want To Cancel This Bid ?'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  flexDirection: "column",
                  display: "block",
                  height: "auto",
                }}
              >
                <div className="p-3">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm">
                        <button
                          className="d-block btn close-button"
                          onClick={() => { this.setState({ cancelBidModal: false }) }}
                        >
                          No
                        </button>
                      </div>
                      <div class="col-sm">
                        <button
                          className="d-block btn close-yes-button"
                          onClick={() => { this.deleteBid(this.state.cancelBidItem); this.setState({ cancelBidModal: false }); }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          }
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => ({
  nft: state.nft,
  error: state.error,
  offer: state.offer,
  bid: state.bid,
  app: state.app,
  nftHistory: state.nftHistory,
  sellingNft: state.sellingNft
});

export default connect(mapStateToProps, {
  beforeNFT,
  getNFT,
  beforeOffer,
  makeOffer,
  getOffers,
  deleteOffer,
  beforeBid,
  placeBid,
  getBids,
  deleteBid,
  acceptOffer,
  acceptBid,
  beforeHistory,
  getHistory,
  buyNft,
  transferNft,
  emptyError,
  transferAdminNft,
  beforeSellingNft,
  getSellingNft,
  beforeWeb3Check,
  getSellingNftId,
  cancelListing,
  refreshMetaData
})(ItemDetails);
