import React, { useState, useEffect } from 'react';
import Web3 from "web3";
import { Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { signRequest } from '../../utils/web3'
import { beforeWallet, setWalletAddress } from '../wallet/wallet.action';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { login, signup } from '../user/user.action';
import validator from 'validator';
import connectors from './connector';
import { toast } from 'react-toastify';
import { ENV } from './../../config/config';
const Wallet = (props) => {
    const { account, activate, deactivate, library } = useWeb3React();
    const [connectedAddress, setConnectedAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showSignUp, setShowSignUp] = useState(false);
    const [showSignUpComponent, setShowSignUpComponent] = useState(false);
    const [username, setUsername] = useState('');
    const [valuesValidation, setValuesValidation] = useState(false);
    const [submitCheck, setSubmitCheck] = useState(false)
    const [signUpCheck, setSignUpCheck] = useState(false)
    const history = useHistory();

    const [msg, setMsg] = useState({
        email: '',
        username: '',
        status: ''
    })

    useEffect(() => {
        if (localStorage.getItem('encuse')) {
            history.push(`${signUpCheck ? '/profile' : '/explore-all'}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (account) {
            localStorage.setItem('connectedAddress', account);
        }
        setConnectedAddress(account);
        setWalletAddress(account);
    }, [account])

    useEffect(() => {
        if (library) {
            window.library = library;
        }
    }, [library])

    useEffect(() => {
        if (props.error) {
            setErrorMessage(props.error.message);
            if (props.error.notExist) {
                setShowSignUp(true);
            } else {
                setShowSignUp(false);
            }
        }
    }, [props.error]);

    const signup = async (e) => {
        e.preventDefault();
        setSubmitCheck(true)
        if (!validator.isEmpty(username)
            && (valuesValidation)) {

            let sign = await signRequest();
            let payload = {
                address: connectedAddress,
                password: sign,
                username: username
            }
            setSignUpCheck(true)
            props.signup(payload);
        }
        else {
            let data = {
                username: '',
                status: ''
            }
            if (validator.isEmpty(username)) {
                data.username = 'Username Is Required.'
            }
            if (!(valuesValidation)) {
                data.status = 'Agree To Privacy Policy Is Required.'
            }
            setMsg(data)
        }

    }

    const login = async () => {
        let sign = await signRequest();
        let payload = {
            address: connectedAddress,
            password: sign
        }
        props.login(payload);
    }

    const switchSignup = () => {
        setShowSignUpComponent(true);
    }

    const walletActivation = (walletInjector, name) => {
        const connector = walletInjector.provider;
        if (walletInjector.name === 'MetaMask' && !window.ethereum) {
            alert('Please install Metamask first!');
            return false;
        }
        if (connector instanceof WalletConnectConnector && connector.walletConnectProvider?.wc?.uri) {
            connector.walletConnectProvider = undefined;
        }

        connector &&
            activate(connector, undefined, true)
                .then(async (res) => {
                    const web3Provider = await connector.getProvider();
                    const web3 = new Web3(web3Provider);
                    window.walletPO = web3
                    localStorage.setItem('connectedAddress', connectedAddress);
                    localStorage.setItem("provider", name);
                })
                .catch(async (error) => {
                    console.log(error)
                    if (error instanceof UnsupportedChainIdError) {
                        toast.error(`Please switch to ${ENV.requiredChainName} in order to use all features of Marketplace`)
                        activate(connector);
                    } else {
                        // setPendingError(error?.message || 'Something went wrong');
                    }
                });
    };

    const disconnect = () => {
        deactivate();
    };

    if (props.user.userAuth) {
        return <Redirect to={`${signUpCheck ? '/profile' : '/explore-all'}`} />
    }

    return (
        <section className={!showSignUpComponent ? `wallet-connect-area` : `author-area`}>
            {
                localStorage.getItem('encuse') ? '' :
                    <div className="container">
                        {
                            connectedAddress && !showSignUpComponent ?
                                <>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-8 col-lg-7">
                                            {/* Intro */}
                                            <div className="intro text-center">
                                                <h3 className="mt-3 mb-0">Sign in to your account</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center items">
                                        <div className="col-12 col-md-6 item">
                                            <div className="card single-wallet">
                                                <span className="d-block text-center" href="/login">
                                                    <h4 className="mb-0">Address</h4>
                                                    <p id="connected-address">{connectedAddress}</p>
                                                    {
                                                        errorMessage && <p className="">{errorMessage}</p>
                                                    }
                                                    <button className="btn w-100 mt-3 mt-sm-4" type="button" onClick={() => login()}>Sign In</button>
                                                    {
                                                        showSignUp && <button className="btn w-100 mt-3 mt-sm-4" type="button" onClick={() => switchSignup()}>Would you like to Signup?</button>
                                                    }
                                                    <p className='text-theme cursor-pointer' onClick={() => disconnect()}>Want to change wallet? Disconnect Now! </p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                showSignUpComponent ?
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-8 col-lg-7">
                                            {/* Intro */}
                                            <div className="intro text-center">
                                                <h3 className="mt-3 mb-0">Create an Account</h3>
                                                <p>Create the account with the address</p>
                                            </div>
                                            {/* Item Form */}
                                            <form className="item-form card no-hover" onSubmit={(e) => signup(e)}>
                                                <div className="row">
                                                    {
                                                        errorMessage && <p className="text-dark">{errorMessage}</p>
                                                    }
                                                    <div className="col-12">
                                                        <div className="form-group mt-3">
                                                            <input type="text" className="form-control" name="address" placeholder="Address" disabled={true} value={connectedAddress} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mt-3">
                                                            <input type="text" className="form-control" name="username" placeholder="Enter your Username" defaultValue={username}
                                                                onChange={(e) => {
                                                                    setUsername(e.target.value);
                                                                    if (submitCheck) {
                                                                        if (e.target.value) {
                                                                            setMsg({ ...msg, username: '' })
                                                                        }
                                                                        else {
                                                                            setMsg({ ...msg, username: 'Username Is Required.' })
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <label className={`text-danger pl-1 ${msg.username ? `` : `d-none`}`}>{msg.username}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mt-3">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio1" defaultValue="policy-checkbox"
                                                                    onChange={(e) => {
                                                                        setValuesValidation(e.target.checked);
                                                                        if (submitCheck) {
                                                                            if (e.target.checked) {
                                                                                setMsg({ ...msg, status: '' })
                                                                            }
                                                                            else {
                                                                                setMsg({ ...msg, status: 'Agree To Privacy Policy Is Required.' })
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                                <label className="form-check-label d-flex" htmlFor="inlineRadio1">I agree to <a href="/privacy-and-terms">Privacy Policy</a></label>
                                                            </div>
                                                            <label className={`text-danger pl-1 ${msg.status ? `` : `d-none`}`}>{msg.status}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <button className="btn w-100 mt-3 mt-sm-4" type="submit">Sign Up</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-8 col-lg-7">
                                                {/* Intro */}
                                                <div className="intro text-center">
                                                    <h3 className="mt-3 mb-0">Connect your Wallet</h3>
                                                    <p>Please select the wallet you want to connect to.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center items">
                                            <div className="col-lg-4 col-sm-6 item" onClick={() => { if (window.ethereum.providers !== undefined) { window.ethereum.setSelectedProvider(window.ethereum.providers.find(({ isMetaMask }) => isMetaMask)); localStorage.setItem('connectedWalletName', 'MetaMask'); } walletActivation(connectors.injected, "injected"); }}>
                                                {
                                                    errorMessage && <p className="text-white">{errorMessage}</p>
                                                }
                                                {/* Single Wallet */}
                                                <div className="card single-wallet whole-hover">
                                                    <span className="d-block text-center" href="/login">
                                                        <img className="avatar-lg d-inline-block align-top" src="/img/metamask2.png" alt="" />
                                                        <h4 className="mb-0" style={{ cursor: "pointer" }}>MetaMask</h4>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 item" onClick={() => { walletActivation(connectors.coinbase, "coinbase") }}>
                                                {
                                                    errorMessage && <p className="text-white">{errorMessage}</p>
                                                }
                                                {/* Single Wallet */}
                                                <div className="card single-wallet whole-hover">
                                                    <span className="d-block text-center" href="/login">
                                                        <img className="avatar-lg d-inline-block align-top" src="/img/cbw.svg" alt="" />
                                                        <h4 className="mb-0" style={{ cursor: "pointer" }}>CoinBase</h4>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 item" onClick={() => { walletActivation(connectors.walletConnect, "walletConnect") }}>
                                                {
                                                    errorMessage && <p className="text-white">{errorMessage}</p>
                                                }
                                                {/* Single Wallet */}
                                                <div className="card single-wallet whole-hover">
                                                    <span className="d-block text-center" href="/login">
                                                        <img className="avatar-lg d-inline-block align-top" src="/img/wc.svg" alt="" />
                                                        <h4 className="mb-0" style={{ cursor: "pointer" }}>WalletConnect</h4>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                        }
                    </div>
            }
        </section>
    );
}

const mapStateToProps = state => ({
    wallet: state.wallet,
    user: state.user,
    error: state.error
});

export default connect(mapStateToProps, { beforeWallet, setWalletAddress, login, signup })(Wallet);