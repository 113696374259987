import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

import { ENV } from '../../config/config';

const POLLING_INTERVAL = 15000;
const defaultChainId = ENV.requiredChainId;

const chains = [defaultChainId];

const networks = {
  5: 'https://goerli.infura.io/v3/8bf5e680f1404b5ba57eecbd63184a6c',
  1: 'https://mainnet.infura.io/v3/8bf5e680f1404b5ba57eecbd63184a6c',
};

export const CoinbaseWallet = new WalletLinkConnector({
  url: networks[defaultChainId],
  appName: ENV.appName,
});

// mainnet only
// export const walletconnect = new WalletConnectConnector({
//   rpc: networks,
//   bridge: 'https://bridge.walletconnect.org',
//   qrcode: true,
//   pollingInterval: POLLING_INTERVAL,
// });

export const walletconnect = new WalletConnectConnector({
  rpcUrl: networks,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
  infuraId: "8bf5e680f1404b5ba57eecbd63184a6c"
});

export const injected = new InjectedConnector({
  supportedChainIds: chains,
});

const connectors = {
  injected: {
    provider: injected,
    name: 'MetaMask',
  },
  walletConnect: {
    provider: walletconnect,
    name: 'Wallet Connect',
  },
  coinbase: {
    provider: CoinbaseWallet,
    name: 'Coinbase',
  }
};

export default connectors;
