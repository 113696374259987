
import { BEFORE_NFT, GET_NFTS, GET_NFT, UPSERT_NFT, SEARCH_NFT, TRANSFER_NFT, TRANSFER_ADMIN_NFT, CANCEL_LISTING, REFRESH_META_DATA } from '../../redux/types';

const initialState = {
    nftsData: {},
    nftsAuth: false,
    upsertAuth: false,
    searchAuth: false,
    searchData: null,
    transNft: null,
    transNftAuth: false,
    transAdminNft: null,
    transAdminNftAuth: false,
    cancelListingAuth: false,
    refreshedData: {},
    refreshedDataAuth: false
}

export default function nftsRed(state = initialState, action) {
    switch (action.type) {
        case UPSERT_NFT:
            return {
                ...state,
                nftsData: action.payload,
                upsertAuth: true
            }
        case TRANSFER_NFT:
            return {
                ...state,
                transNft: action.payload,
                transNftAuth: true
            }
        case TRANSFER_ADMIN_NFT:
            return {
                ...state,
                transAdminNft: action.payload,
                transAdminNftAuth: true
            }
        case SEARCH_NFT:
            return {
                ...state,
                searchData: action.payload,
                searchAuth: true
            }
        case GET_NFT:
            return {
                ...state,
                nftsData: action.payload,
                nftsAuth: true
            }
        case GET_NFTS:
            return {
                ...state,
                nftsData: action.payload,
                nftsAuth: true
            }
        case CANCEL_LISTING:
            return {
                ...state,
                cancelListingAuth: true
            }
        case REFRESH_META_DATA:
            return {
                ...state,
                refreshedData: action.payload,
                refreshedDataAuth: true
            }
        case BEFORE_NFT:
            return {
                ...state,
                nftsData: {},
                nftsAuth: false,
                upsertAuth: false,
                searchAuth: false,
                searchData: null,
                transNft: null,
                transNftAuth: false,
                transAdminNft: null,
                transAdminNftAuth: false,
                cancelListingAuth: false,
                refreshedData: {},
                refreshedDataAuth: false
            }
        default:
            return {
                ...state
            }
    }
}