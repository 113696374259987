import { useEffect, useState } from "react";
import Slider from "react-slick";
import { getNFTs, beforeNFT } from '../../nfts/nfts.action'
import { connect } from 'react-redux';
import moment from 'moment'
import FullPageLoader from '../../full-page-loader/full-page-loader';
import { ENV } from '../../../config/config'
import { Link } from 'react-router-dom';


var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        }
    ]
};
const Sliders = (props) => {

    const [nfts, setNfts] = useState(null)
    const [loader, setLoader] = useState(true)
    const [adminId, setAdminId] = useState('')
    const [getNftCheck, setNftCheck] = useState(true)

    useEffect(() => {
        const qs = ENV.objectToQueryString({ limit: 10, bothNfts: true })
        props.getNFTs(qs)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.nft.nftsAuth && getNftCheck) {
            setAdminId(props.nft.nftsData.adminId)
            setNfts(props.nft.nftsData.nfts)
            props.beforeNFT()
            setNftCheck(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nft.nftsAuth])

    useEffect(() => {
        if (nfts) {
            setLoader(false)
        }
    }, [nfts])

    return (
        <div className="slider-main-container">
            <div className="slider slider-wrapper">
                <div className="container">
                    <h2><span>EXCLUSIVE DROPS</span></h2>
                    {
                        loader ?
                            <FullPageLoader />
                            :
                            <Slider {...settings}>
                                {
                                    nfts && nfts.length ? nfts.map((item, index) => {
                                        if ((item.sellingMethod) || ((String(adminId) === String(item.owner._id)) && (item.purchaseCount < item.maxAllowed))) {
                                            if ((new Date().getTime() > new Date(item.auctionStartDate).getTime()) && (new Date().getTime() < new Date(item.auctionEndDate).getTime())) {
                                                return (
                                                    <Link key={index} to={`/item-details?item=${window.btoa(item._id)}${String(adminId) === String(item.owner._id) ? `&adminCheck=true` : ''}${ item.sellingNftId ? `&sellingNftId=${item.sellingNftId}`: ''}`}>
                                                        <div className="slide-holder">
                                                            <div className="img-contain">
                                                                <img className="img-fluid" src={ENV.webUrl + item.nftImageLink} style={{ objectFit: "contain", height: "100%", width: "100%" }} alt='img' />
                                                            </div>
                                                            <div className="slider-cont">
                                                                <div className="content-wrapper">
                                                                    <div className="cont1">
                                                                        <h4>
                                                                            {item.name}
                                                                        </h4>
                                                                    </div>
                                                                    {
                                                                        item.currentPrice ?
                                                                            <div className="cont2">
                                                                                <button type="button" className="btn btn-primary">
                                                                                    <span>
                                                                                        {item.currentPrice} {ENV.currency}
                                                                                    </span>
                                                                                </button>
                                                                            </div> : ''
                                                                    }
                                                                </div>
                                                                <div className="cont">
                                                                    {item.auctionEndDate ?
                                                                        <div className="w-100 cont4 d-flex justify-content-end align-items-center">
                                                                            <span className="icon-holder mr-2">
                                                                                <img className="img-fluid" src="img/time-left.svg" alt='img' />
                                                                            </span>
                                                                            <span>
                                                                                Ends {moment(item.auctionEndDate).fromNow()}
                                                                            </span>
                                                                        </div>
                                                                        :
                                                                        ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )
                                            }
                                        }
                                        return false
                                    }) : ''
                                }
                            </Slider>
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    nft: state.nft
});

export default connect(mapStateToProps, {
    getNFTs, beforeNFT
})(Sliders);