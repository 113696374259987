import wbnbContractAbi from './../utils/abis/wbnb.json';
import $ from 'jquery';
require('dotenv').config();
const CryptoJS = require("crypto-js");
const dataEncryptionKey = 'kalsaOOLLaASASAFFSSEE';
const moment = require('moment');

export const ENV = {
    network: 'testnet',
    signMsg: `${process.env.REACT_APP_NAME} uses this cryptographic signature in place of a password, verifying that you are the owner of this address.`,
    bscExplorerURL: process.env.REACT_APP_BSC_URL,

    // BNB To Dollar Conversion Link
    dollar: process.env.REACT_APP_DOLLAR_CONVERSION_LINK,
    ann: process.env.REACT_APP_ANN_TO_XLINK,

    // blockchain variables
    nftContractAddress: process.env.REACT_APP_NFT_CONTRACT_ADDRESS,
    web3ProviderAddress: process.env.REACT_APP_WEB3_PROVIDER_ADDRESS,

    // process variables
    url: process.env.REACT_APP_BASE_URL,
    webUrl: process.env.REACT_APP_BASE_LINK,
    assetUrl: process.env.REACT_APP_BASE_URL,
    currency: process.env.REACT_APP_CURRENCY,
    appName: process.env.REACT_APP_NAME,
    requiredChainName: process.env.REACT_APP_REQUIRED_CHAIN_NAME,
    requiredChainId: parseInt(process.env.REACT_APP_REQUIRED_CHAIN),
    // amountToApprove: 1000000000000000000,
    amountToApprove: '115792089237316195423570985008687907853269984665640564039457584007913129639935', currencies: [
        {
            label: 'Binance (BNB)',
            symbol: 'BNB',
            value: 'BNB',
            icon: 'assets/images/binance.svg',
            showInFilters: false // show in search & filters section
        },
        {
            label: 'Wrapped Binance (WBNB)',
            symbol: 'WBNB',
            value: 'WBNB',
            icon: 'assets/images/binance.svg',
            showInBuy: false, // show in make an offer / place a bid modal
            address: process.env.REACT_APP_WBNB_TOKEN,
            abi: wbnbContractAbi
        },
        {
            label: 'Ethereum (ETH)',
            symbol: 'ETH',
            value: 'ETH',
            icon: 'assets/images/binance.svg',
            showInFilters: true // show in search & filters section
        },
        {
            label: 'Wrapped Ethereum (WETH)',
            symbol: 'WETH',
            value: 'WETH',
            icon: 'assets/images/binance.svg',
            showInBuy: true, // show in make an offer / place a bid modal
            address: process.env.REACT_APP_WBNB_TOKEN,
            abi: wbnbContractAbi
        },
        {
            label: '💲 United States Dollar (USD)',
            symbol: 'USD',
            value: 'USD',
            icon: 'assets/images/ethereum.svg',
            showInFilters: true // show in search & filters section
        }
    ],
    blockchainExplorer: process.env.REACT_APP_BLOCK_EXPLORER,
    nativeCurrency: {
        name: process.env.REACT_APP_REQUIRED_CHAIN_NAME,
        symbol: process.env.REACT_APP_CURRENCY,
        decimals: process.env.REACT_APP_CURRENCY_DECIMALS,
    },
    tokenNameToValue: {
        // 'ANN': 1,
        'WETH': 2
    },
    serviceFee: 2,

    // Headers
    Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
    x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,

    // default images placeholders
    globalPlaceholderImage: '/img/placeholder.png',
    collectionFeaturedImg: '/img/placeholder.png',
    userDefaultImg: '/img/placeholder.png',
    categoryDefaultImg: '/img/placeholder.png',

    scrollToQuery: () => {
        var $container = $('html,body')
        var $scrollTo = $('.inquiry')

        $container.animate({ scrollTop: $scrollTo.offset().top - $container.offset().top, scrollLeft: 0 }, 500)
    },

    //set user in local storage
    encryptUserData: function (data) {
        let userData = localStorage.getItem('encuse');
        if (userData && !data.accessToken) {
            let bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            let originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            if (originalData && originalData.accessToken) {
                data.accessToken = originalData.accessToken;
            }
        }
        data = JSON.stringify(data);
        let encryptedUser = CryptoJS.AES.encrypt(data, dataEncryptionKey).toString();
        localStorage.setItem('encuse', encryptedUser);
        return true;
    },

    //return required keys
    getUserKeys: function (keys = null) {
        let userData = localStorage.getItem('encuse');
        if (userData) {
            var bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
            var originalData = bytes.toString(CryptoJS.enc.Utf8);
            originalData = JSON.parse(originalData);
            let user = {};
            if (keys) {
                keys = keys.split(" ");
                for (let key in keys) {
                    let keyV = keys[key];
                    user[keyV] = originalData[keyV];
                }
            }
            else {
                user = originalData;
            }
            return user;
        }
        return {};
    },

    //Object to query string
    objectToQueryString: function (body) {
        const qs = Object.keys(body).map(key => `${key}=${body[key]}`).join('&');
        return qs;
    },

    //validate image types
    isValidImageType: function (file) {
        if (file && file.type) {
            const acceptableTypes = ['image/png', 'image/x-png', 'image/jpeg', 'image/jpg']
            return (acceptableTypes.includes(file.type.toLowerCase()))
        }
    },

    //slick configurations
    slickSettings: {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    margin: 15,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    },

    dateRangeInitialSettings: {
        startDate: moment(),
        endDate: moment().add(6, 'months').toDate(),
        minDate: moment(),
        maxDate: moment().add(6, 'months').toDate(),
        ranges: {
            '1 Day': [
                moment().toDate(),
                moment().add(1, 'days').toDate(),
            ],
            '3 Days': [
                moment().toDate(),
                moment().add(3, 'days').toDate(),
            ],
            '1 Week': [
                moment().toDate(),
                moment().add(6, 'days').toDate(),
            ],
        }
    },

    countDownRenderer: ({ days, hours, minutes, seconds }) => {
        return (
            <div className="countdown-container d-flex" style={{ justifyContent: "space-between", width: "85%" }}>
                <div className="countdown-wrapper m-1">
                    <div>Days</div>
                    <div>{days}</div>
                </div>
                <div className="countdown-wrapper m-1">
                    <div>Hours</div>
                    <div>{hours}</div>
                </div>
                <div className="countdown-wrapper m-1">
                    <div>Minutes</div>
                    <div>{minutes}</div>
                </div>
                <div className="countdown-wrapper m-1">
                    <div>Seconds</div>
                    <div>{seconds}</div>
                </div>
            </div>
        )
    },

    decimalNumberValidator: function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        let specialKeys = [46, 8, 9, 27, 13, 110, 190]

        if (e.target.value.includes('.')) {
            specialKeys = [46, 8, 9, 27, 13]
        }
        else {
            specialKeys = [46, 8, 9, 27, 13, 110, 190]
        }

        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        if (specialKeys.includes(e.keyCode) ||
            // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
            ((e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 90 || e.keyCode === 88) && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40) ||
            // Allow F1 to F12 keys 
            (e.keyCode >= 112 && e.keyCode <= 123)
        ) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }

    },

    integerNumberValidator: function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        const specialKeys = [46, 8, 9, 27, 13, 110]

        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        if (specialKeys.includes(e.keyCode) ||
            // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
            ((e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 90 || e.keyCode === 88) && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    },

    convertBnbToUsd: (value, bnbUnit) => {
        return (value * bnbUnit).toFixed(2);
    },

    convertUsdToBnb: (value, bnbUnit) => {
        if (bnbUnit !== 0)
            return (value / (bnbUnit)).toFixed(2);
    },

    convertAnnToUsd: (value, annUnit) => {
        return (value * annUnit).toFixed(2)
    }
}
