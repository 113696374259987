import { useEffect, useState } from "react";
import {
  Modal
} from "react-bootstrap";
import { useHistory } from 'react-router-dom';


const Community = () => {

  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory();

  useEffect(() => {
    window.scroll(0, 0);
  }, [])


  useEffect(() => {
    window.scroll(0, 0)
    document.querySelectorAll('.nav-link').forEach((item) => {
        item.classList.remove('activeNav')
    })
    document.querySelector(`#link-navv-8`).classList.add('activeNav')
}, [])


  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }


  return (
    <>
    <section style={{ paddingTop:'50px', paddingBottom: '26px'}}>
      <div className="container">
        <div className="intro text-center mb-4" bis_skin_checked="1">
          <h3 className="mt-0 mb-1 text-upeprcase">Coming Soon</h3>
        </div>
      </div>
    </section>
      <div className="investor-detail-section bg-white pb-5">
        <div className="community-banner-container">
          <div className="position-relative">
            <img
              src="img/2nd.jpg"
              className="img-fluid investor-detail-banner"
              style={{
                width: "100%"
              }}
              alt="img"
            />
            <div className="absolute-wrapper px-3 h-100">
              <div className="d-flex community-wrapper h-100">
                <div className="community-desc">
                  Lorem Ipsum is simply dummy
                  text of the printing and
                  typesetting industry. Lorem
                  Ipsum has been the industry's
                  <div className="mt-4">
                    <button
                      className="btn banner-btn btn-primary"
                      onClick={openModal}
                    >
                      Start Minting Now
                    </button>
                  </div>
                </div>
                <div className="community-nft">
                  <div>
                    <div className="img-contain">
                      <img
                        className="img-fluid"
                        src="img/FISH2.png"
                        style={{
                          borderTopLeftRadius:
                            "10px",
                          borderTopRightRadius:
                            "10px",
                        }}
                        alt="img"
                      />
                    </div>
                    <div className="slider-cont">
                      <div
                        className="text-center bg-white p-2"
                        style={{
                          borderBottomLeftRadius:
                            "10px",
                          borderBottomRightRadius:
                            "10px",
                        }}
                      >
                        <div className="">
                          <h4 className="text-dark">
                            Illumagic Blaze
                          </h4>
                        </div>
                        <div className="">
                          Abstract Feature
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="roadmap-section">
          <div className="roadmap-container">
            <div className="text-center pb-5">
              <h1 className="roadmap-heading">
                RoadMap
              </h1>
            </div>
            <div className="timeline">
              <div className="timeline-container primary">
                <div className="timeline-icon">
                  <i className="far fa-grin-wink"></i>
                </div>
                <div className="timeline-body">
                  <h4 className="timeline-title">
                    <span className="badge">
                      Our Story
                    </span>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit.
                    Aliquam necessitatibus numquam
                    earum ipsa fugiat veniam
                    suscipit, officiis
                    repudiandae, eum recusandae
                    neque dignissimos. Cum fugit
                    laboriosam culpa, repellendus
                    esse commodi deserunt.
                  </p>
                  <p></p>
                </div>
              </div>
              <div className="timeline-container danger">
                <div className="timeline-icon">
                  <i className="far fa-grin-hearts"></i>
                </div>
                <div className="timeline-body">
                  <h4 className="timeline-title">
                    <span className="badge">
                      How to Get Benifits
                    </span>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit.
                    Aliquam necessitatibus numquam
                    earum ipsa fugiat veniam
                    suscipit, officiis
                    repudiandae, eum recusandae
                    neque dignissimos. Cum fugit
                    laboriosam culpa, repellendus
                    esse commodi deserunt.
                  </p>
                  <p></p>
                </div>
              </div>
              <div className="timeline-container success">
                <div className="timeline-icon">
                  <i className="far fa-grin-tears"></i>
                </div>
                <div className="timeline-body">
                  <h4 className="timeline-title">
                    <span className="badge">
                      Success Time Period
                    </span>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit.
                    Aliquam necessitatibus numquam
                    earum ipsa fugiat veniam
                    suscipit, officiis
                    repudiandae, eum recusandae
                    neque dignissimos. Cum fugit
                    laboriosam culpa, repellendus
                    esse commodi deserunt.
                  </p>
                  <p></p>
                </div>
              </div>
              <div className="timeline-container warning">
                <div className="timeline-icon">
                  <i className="far fa-grimace"></i>
                </div>
                <div className="timeline-body">
                  <h4 className="timeline-title">
                    <span className="badge">
                      Membership Criteria
                    </span>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit.
                    Aliquam necessitatibus numquam
                    earum ipsa fugiat veniam
                    suscipit, officiis
                    repudiandae, eum recusandae
                    neque dignissimos. Cum fugit
                    laboriosam culpa, repellendus
                    esse commodi deserunt.
                  </p>
                  <p></p>
                </div>
              </div>
              <div className="timeline-container">
                <div className="timeline-icon">
                  <i className="far fa-grin-beam-sweat"></i>
                </div>
                <div className="timeline-body">
                  <h4 className="timeline-title">
                    <span className="badge">
                      Exclusive Members
                    </span>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit.
                    Aliquam necessitatibus numquam
                    earum ipsa fugiat veniam
                    suscipit, officiis
                    repudiandae, eum recusandae
                    neque dignissimos. Cum fugit
                    laboriosam culpa, repellendus
                    esse commodi deserunt.
                  </p>
                  <p></p>
                </div>
              </div>
              <div className="timeline-container info">
                <div className="timeline-icon">
                  <i className="far fa-grin"></i>
                </div>
                <div className="timeline-body">
                  <h4 className="timeline-title">
                    <span className="badge">
                      Look Forward
                    </span>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit.
                    Aliquam necessitatibus numquam
                    earum ipsa fugiat veniam
                    suscipit, officiis
                    repudiandae, eum recusandae
                    neque dignissimos. Cum fugit
                    laboriosam culpa, repellendus
                    esse commodi deserunt.
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="modal-wrapper">
        <Modal
          centered
          size="lg"
          className=""
          show={isOpen}
          onHide={closeModal}
        >
          <Modal.Header className="text-center modal-title-wrapper">
            <Modal.Title>
              Create or Purchase NFT
            </Modal.Title>
          </Modal.Header>
          <span className="text-center pb-3">
            Do you want to create a new NFT or want to buy from the existing ones?
          </span>
          <Modal.Body>
            <div className="modal-boxex d-md-flex">
              <div className="arrow-wrapper" onClick={() => { history.push('/explore-all') }}>
                <div
                  style={{
                    display: "contents",
                  }}
                >
                  <img
                    src="img/arrow.svg"
                    style={{ width: "20%" }}
                    alt="img"
                  />
                </div>
                <div>
                  Go to marketplace.
                </div>
              </div>
              <div className="item-wrapper" onClick={() => { localStorage.getItem('encuse') ? history.push('/create'): history.push('/signup') }}>
                <div
                  style={{
                    display: "contents",
                  }}
                >
                  <img
                    src="img/item.svg"
                    style={{ width: "20%" }}
                    alt="img"
                  />
                </div>
                <div>Create a new item.</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn banner-btn btn-primary"
              onClick={closeModal}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Community;
