import React, { useEffect } from "react";
import Web3 from "web3";
import { useWeb3React } from '@web3-react/core';
import connectors from './../../components/wallet/connector';
import Header from "../../components/home/header/Header";

const Layout4 = (props) => {
    const { customBg } = props;
    const { account, activate, library } = useWeb3React();
    useEffect(() => {
        if (localStorage.getItem('connectedWalletName')) {
            window.ethereum.setSelectedProvider(window.ethereum.providers.find(({ isMetaMask }) => isMetaMask))
        }
        const provider = window.localStorage.getItem("provider");
        if (provider) {
            const connector = connectors[provider].provider;
            activate(connector, undefined, true)
                .then(async (res) => {
                    const web3Provider = await connector.getProvider();
                    const web3 = new Web3(web3Provider);
                    window.walletPO = web3
                })
                .catch((error) => {
                    console.log(error)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (account) {
            localStorage.setItem('connectedAddress', account);
        }
    }, [account])

    useEffect(() => {
        if (library) {
            window.library = library;
        }
    }, [library])

    return (
        <>
            <Header />
            <div className={customBg ? "bg-white" : ""}>
                <div className="main">
                    {props.children}
                </div>
            </div>
        </>
    );
}

export default Layout4;
