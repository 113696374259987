import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AuthorProfile from "../author-profile/author-profile";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import SimpleReactValidator from "simple-react-validator";
import {
	updateProfile,
	setIndividualUserData,
	beforeUser,
	setBanner,
} from "./../user/user.action";
import { signRequest } from "./../../utils/web3";
import { ENV } from "./../../config/config";
import { useHistory } from "react-router-dom";
import FullPageLoader from "../full-page-loader/full-page-loader";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
const bannerImg = "/img/placeholder.png";


const Profile = (props) => {
	const key = ENV.getUserKeys("_id");
	const history = useHistory();
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [loader, setLoader] = useState(false);
	const [errors, setErrors] = useState("");
	const [connectedAddress, setConnectedAddress] = useState("");
	const [image, setImage] = useState("");
	const [imageUrl, setImageUrl] = useState(null);
	const [banner, setBannerImage] = useState("");
	const [address, setAddress] = useState("");
	const [username, setUsername] = useState("");
	const [description, setDescription] = useState("");
	const [facebookLink, setFbLink] = useState("");
	const [twitterLink, setTwitterLink] = useState("");
	const [youTubeLink, setYouTubeLink] = useState("");
	const [tiktokLink, setTiktokLink] = useState("");
	const [instagramLink, setInstagramLink] = useState("");
	const [verificationCheck, setVerificationCheck] = useState('');
	const [submitCheck, setSubmitCheck] = useState(false);
	const [settings, setSettings] = useState(null);
	const [profileImageFile, setProfileImageFile] = useState('');
	const [bannerImageFile, setBannerImageFile] = useState('');
	const [email, setEmail] = useState('');


	const [msg, setMsg] = useState({
		facebookLink: "",
		twitterLink: "",
		youTubeLink: "",
		tiktokLink: "",
		instagramLink: "",
	});

	let validate = new SimpleReactValidator({
		autoForceUpdate: this,
		messages: {
			required: "This field is required.", // will override all messages
		},
	});
	const isValidUrl = (userInput) => {
		var res = userInput.match(
			/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
		);
		if (res == null) return false;
		else return true;
	};

	const addHttps = (link) => {
		if (!link.includes('http')) {
			return 'https://' + link
		}
		return link
	}

	useEffect(() => {
		if (key._id) {
			let user = ENV.getUserKeys("");
			props.setIndividualUserData(user);
			setVerificationCheck(user.verificationCheck ? user.verificationCheck : '')
			setConnectedAddress(user.address ? user.address : "");
			setBannerImage(user.bannerImageLink ? ENV.webUrl + user.bannerImageLink : "");
			setAddress(user.address ? user.address : "");
			setDescription(user.description ? user.description : "");
			setFbLink(user.facebookLink ? user.facebookLink : "");
			setYouTubeLink(user.youTubeLink ? user.youTubeLink : "");
			setTwitterLink(user.twitterLink ? user.twitterLink : "");
			setTiktokLink(user.tiktokLink ? user.tiktokLink : "");
			setInstagramLink(user.instagramLink ? user.instagramLink : "");
			setUsername(user.username ? user.username : "");
			setImageUrl(user.profileImageLink ? ENV.webUrl + user.profileImageLink : "");
			setEmail(user.email ? user.email : '');
		} else {
			history.push("/login");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (props.user.userAuth) {
			if (props.user.userData.bannerImage) {
				setBannerImage(ENV.webUrl + props.user.userData.bannerImageLink)
			}
			props.beforeUser();
			setLoader(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.user.userAuth]);

	useEffect(() => {
		if (props.error) {
			setErrors(props.error.message);
			goToTop();
		}
	}, [props.error]);

	useEffect(() => {
		if (props.settings.settingsAuth) {
			setSettings(props.settings.settings)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.settings.settingsAuth])

	const goToTop = () => {
		$("html, body").animate(
			{
				scrollTop: 0,
			},
			600
		);
	};

	const onFileChange = (e) => {
		let file = e.target.files[0];
		let fileURL = "";
		if (file) {
			if (file.type.includes("image")) {
				fileURL = URL.createObjectURL(file);
			} else {
				file = {};
				fileURL = "";
			}
			setImageUrl(fileURL);
			setProfileImageFile(file);
			setImage(file);
		}
	};

	const onBannerChange = (e) => {
		let file = e.target.files[0];
		let fileURL = "";
		if (file) {
			if (file.type.includes("image")) {
				fileURL = URL.createObjectURL(file);
			} else {
				file = {};
				fileURL = "";
			}
			setBannerImage(fileURL);
			setBannerImageFile(file);
			props.setBanner(fileURL);
		}
	};

	const submit = async (e) => {
		e.preventDefault();
		setSubmitCheck(true);
		setErrors("");
		setIsSubmitted(true);
		let isFormValid = validate.allValid() ? true : false;
		if (isFormValid) {
			let msgData = {
				facebookLink: "",
				twitterLink: "",
				youTubeLink: "",
				tiktokLink: "",
				instagramLink: "",
			};
			if (facebookLink) {
				if (!isValidUrl(facebookLink.toLocaleLowerCase())) {
					msgData.facebookLink = "Link Is Invalid.";
					goToTop();
				} else {
					setFbLink(addHttps(facebookLink));
					msgData.facebookLink = "";
				}
			}
			if (twitterLink) {
				if (!isValidUrl(twitterLink.toLocaleLowerCase())) {
					msgData.twitterLink = "Link Is Invalid.";
					goToTop();
				} else {
					setTwitterLink(addHttps(twitterLink));
					msgData.twitterLink = "";
				}
			}
			if (youTubeLink) {
				if (!isValidUrl(youTubeLink.toLocaleLowerCase())) {
					msgData.youTubeLink = "Link Is Invalid.";
					goToTop();
				} else {
					setYouTubeLink(addHttps(youTubeLink));
					msgData.youTubeLink = "";
				}
			}
			if (tiktokLink) {
				if (!isValidUrl(tiktokLink.toLocaleLowerCase())) {
					msgData.tiktokLink = "Link Is Invalid.";
					goToTop();
				} else {
					setTiktokLink(addHttps(tiktokLink));
					msgData.tiktokLink = "";
				}
			}
			if (instagramLink) {
				if (!isValidUrl(instagramLink)) {
					msgData.instagramLink = "Link Is Invalid.";
					goToTop();
				} else {
					setInstagramLink(addHttps(instagramLink));
					msgData.instagramLink = "";
				}
			}

			setMsg(msgData);

			let check = true;

			for (const property in msgData) {
				if (msgData[property] !== "") {
					check = false;
				}
			}

			if (check) {
				try {
					let signature = await signRequest();
					setLoader(true);
					var formData = new FormData();
					if (profileImageFile) formData.append("profileImage", profileImageFile);
					if (bannerImageFile) formData.append("bannerImage", bannerImageFile);
					formData.append("description", description);
					formData.append("facebookLink", facebookLink);
					formData.append("twitterLink", twitterLink);
					formData.append("youTubeLink", youTubeLink);
					formData.append("tiktokLink", tiktokLink);
					formData.append("instagramLink", instagramLink);
					if (username) formData.append("username", username);
					if (email) formData.append('email', email);
					formData.append("signature", signature);
					props.updateProfile(formData);
					setBannerImage("");
					setImage("");
				} catch (e) { }
			}
		} else {
			validate.showMessages();
			setErrors("Please fill all required fields in valid format.");
			goToTop();
		}
	};

	return (
		<>
			{
				loader ?
					<FullPageLoader />
					:
					<>
						<Breadcrumb banner={banner ? banner : bannerImg} />
						<section className="author-area">
							<div className="container">
								<div className="row justify-content-between">
									<div className="col-12 col-md-4">
										<AuthorProfile
											verificationCheck={verificationCheck}
											profileImage={imageUrl}
											username={username}
											description={description}
											address={connectedAddress}
											facebookLink={facebookLink}
											twitterLink={twitterLink}
											youTubeLink={youTubeLink}
											tiktokLink={tiktokLink}
											instagramLink={instagramLink}
										/>
									</div>
									<div className="col-12 col-md-7">
										<div className="mt-5 mt-lg-0 mb-4 mb-lg-5">
											<div className="intro">
												<div className="intro-content">
													<h3 className="mt-3 mb-0">Update your profile</h3>
												</div>
											</div>
										</div>
										<form id="create-nft" className="item-form card no-hover">
											{isSubmitted && errors && (
												<div className="row pb-2">
													<div className="col-12">
														<span id="create-nft-err" className="text-danger">
															{errors}
														</span>
													</div>
												</div>
											)}
											<div className="row">
												<div className="col-12">
													<div className="input-group form-group ">
														<div className="custom-file">
															<input type="file" className="custom-file-input" id="banner" accept=".png,.jpeg,.jpg" onChange={(e) => onBannerChange(e)} name="banner" />
															<label id="nft-imasge-label" className="custom-file-label" htmlFor="banner" >
																{banner && banner.name ? "File Selected" : "Choose Banner Image"}
															</label>
														</div>
														{
															settings && settings.imageSize ?
																<div className="text-info mt-1">
																	Maximum recommended size of image is {settings.imageSize} MB(s)
																</div>
																: ''
														}
													</div>
												</div>
												<div className="col-12">
													<div className="input-group form-group">
														<div className="custom-file">
															<input type="file" className="custom-file-input" id="image" accept=".png,.jpeg,.jpg" onChange={(e) => onFileChange(e)} cv name="image" />
															<label id="nft-imasge-label" className="custom-file-label" htmlFor="image">
																{image && image.name ? "File Selected" : "Choose Profile Picture"}
															</label>
														</div>
														{
															settings && settings.imageSize ?
																<div className="text-info mt-1">
																	Maximum recommended size of image is {settings.imageSize} MB(s)
																</div>
																: ''
														}
													</div>
												</div>
												<div className="col-12">
													<div className="form-group ">
														<input type="text" className="form-control" name="address" placeholder="Wallet Address *" required="required" value={address} readOnly />
														<span className="text-danger">
															{validate.message("address", address, "required")}
														</span>
													</div>
												</div>
												<div className="col-12">
													<div className="form-group">
														<input type="text" className="form-control" name="username" placeholder="Username *" required="required" onKeyUp={(e) => setUsername(e.target.value)} defaultValue={username} />
														<span className="text-danger">
															{validate.message("username", username, "required")}
														</span>
													</div>
												</div>
												<div className="col-12">
													<div className="form-group">
														<input type="email" className="form-control" name="email" placeholder="Email" onKeyUp={(e) => setEmail(e.target.value)} defaultValue={email} />
													</div>
												</div>
												<div className="col-12">
													<div className="form-group">
														<textarea className="form-control" name="description" placeholder="About" cols={30} rows={3} onKeyUp={(e) => setDescription(e.target.value)} defaultValue={description} />
													</div>
												</div>
												<div className="col-12">
													<div className="form-group">
														<input type="text" className="form-control" name="facebookLink" placeholder="Facebook Link" cols={30} rows={3}
															onChange={(e) => {
																setFbLink(e.target.value);
																if (submitCheck) {
																	if (facebookLink) {
																		if (!isValidUrl(facebookLink)) {
																			setMsg({
																				...msg,
																				facebookLink: "Link Is Invalid.",
																			});
																		} else {
																			setMsg({ ...msg, facebookLink: "" });
																		}
																	}
																}
															}}
															defaultValue={facebookLink}
														/>
														{facebookLink ? (
															<label
																className={`text-danger pl-1 ${msg.facebookLink ? `` : `d-none`
																	}`}
															>
																{msg.facebookLink}
															</label>
														) : (
															""
														)}
													</div>
												</div>
												<div className="col-12">
													<div className="form-group">
														<input type="text" className="form-control" name="twitterLink" placeholder="Twitter Link" cols={30} rows={3}
															onChange={(e) => {
																setTwitterLink(e.target.value);
																if (submitCheck) {
																	if (twitterLink) {
																		if (!isValidUrl(twitterLink)) {
																			setMsg({
																				...msg,
																				twitterLink: "Link Is Invalid.",
																			});
																		} else {
																			setMsg({ ...msg, twitterLink: "" });
																		}
																	}
																}
															}}
															defaultValue={twitterLink}
														/>
														{twitterLink ? (
															<label
																className={`text-danger pl-1 ${msg.twitterLink ? `` : `d-none`
																	}`}
															>
																{msg.twitterLink}
															</label>
														) : (
															""
														)}
													</div>
												</div>
												<div className="col-12">
													<div className="form-group">
														<input type="text" className="form-control" name="youTubeLink" placeholder="YouTube Link" cols={30} rows={3}
															onChange={(e) => {
																setYouTubeLink(e.target.value);
																if (submitCheck) {
																	if (youTubeLink) {
																		if (!isValidUrl(youTubeLink)) {
																			setMsg({
																				...msg,
																				youTubeLink: "Link Is Invalid.",
																			});
																		} else {
																			setMsg({ ...msg, youTubeLink: "" });
																		}
																	}
																}
															}}
															defaultValue={youTubeLink}
														/>
														{youTubeLink ? (
															<label
																className={`text-danger pl-1 ${msg.youTubeLink ? `` : `d-none`
																	}`}
															>
																{msg.youTubeLink}
															</label>
														) : (
															""
														)}
													</div>
												</div>
												<div className="col-12">
													<div className="form-group">
														<input type="text" className="form-control" name="tiktokLink" placeholder="TikTok Link" cols={30} rows={3}
															onChange={(e) => {
																setTiktokLink(e.target.value);
																if (submitCheck) {
																	if (tiktokLink) {
																		if (!isValidUrl(tiktokLink)) {
																			setMsg({
																				...msg,
																				tiktokLink: "Link Is Invalid.",
																			});
																		} else {
																			setMsg({ ...msg, tiktokLink: "" });
																		}
																	}
																}
															}}
															defaultValue={tiktokLink}
														/>
														{tiktokLink ? (
															<label
																className={`text-danger pl-1 ${msg.tiktokLink ? `` : `d-none`
																	}`}
															>
																{msg.tiktokLink}
															</label>
														) : (
															""
														)}
													</div>
												</div>
												<div className="col-12">
													<div className="form-group">
														<input type="text" className="form-control" name="instagramLink" placeholder="Instagram Link" cols={30} rows={3}
															onChange={(e) => {
																setInstagramLink(e.target.value);
																if (submitCheck) {
																	if (instagramLink) {
																		if (!isValidUrl(instagramLink)) {
																			setMsg({
																				...msg,
																				instagramLink: "Link Is Invalid.",
																			});
																		} else {
																			setMsg({ ...msg, instagramLink: "" });
																		}
																	}
																}
															}}
															defaultValue={instagramLink}
														/>
														{instagramLink ? (
															<label
																className={`text-danger pl-1 ${msg.instagramLink ? `` : `d-none`
																	}`}
															>
																{msg.instagramLink}
															</label>
														) : (
															""
														)}
													</div>
												</div>
												<div className="col-12">
													<button disabled={loader} className="btn w-100 mt-3 mt-sm-4" type="button" onClick={(e) => submit(e)}>
														Update Profile
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</section>
					</>
			}
		</>
	);
};

const mapStateToProps = (state) => ({
	wallet: state.wallet,
	user: state.user,
	error: state.error,
	settings: state.settings
});

export default connect(mapStateToProps, {
	updateProfile,
	setIndividualUserData,
	beforeUser,
	setBanner,
})(Profile);
