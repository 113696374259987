import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { beforeNFT, getNFTs } from '../nfts/nfts.action';
import { ENV } from '../../config/config';
import FullPageLoader from '../full-page-loader/full-page-loader'
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import FilterSearch from "../filter-search/filtersearch";
import { Images } from "../../assets/asset";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const initData = {
    pre_heading: "Explore",
    heading: "Official Aqua Culture NFTS",
    content: "Connect to your favourite artists and brands directly.",
    btn_1: "Load More"
}

function ExploreAll(props) {
    const [nftPagination, setNFTPagination] = useState(null);
    const [nfts, setNFTs] = useState([]); // NFTs for explore section
    const [loader, setLoader] = useState(true);
    const [loadMoreBtn, setLoadMoreBtn] = useState(false);
    const [isMoreClicked, setLoadMore] = useState(false); // flag to check if load more button clicked
    const [intro, setIntro] = useState(false);
    const [searchAndFilters, setSearchAndFilters] = useState(false);
    const [nftFilter, setNftFitler] = useState({});
    const pathname = useHistory().location.pathname
    const [Filter, setFilter] = useState(false);  // flag for responsive view only
    const [hideCheck, setHideCheck] = useState(false);
    const [adminId, setAdminId] = useState('');

    useEffect(() => {
        window.scroll(0, 0)

        document.querySelectorAll('.nav-link').forEach((item) => {
            item.classList.remove('activeNav')
        })
        if (window.location.href.split('/')[3] === 'explore-all') {
            document.querySelector(`#link-navv-1`).classList.add('activeNav')
        }
        else {
            document.querySelector(`#link-navv-3`).classList.add('activeNav')
        }


        // set intro section
        if (pathname === '/explore-all') {
            setIntro(true)
            setSearchAndFilters(true)
        }

        localStorage.removeItem('activeBtn')

        // get NFTs for explore section
        getNFTs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // set NFTs for explore section
    useEffect(() => {
        if (props.nft.nftsAuth) {
            const { nftsData } = props.nft
            if (nftsData) {
                setAdminId(nftsData.adminId)
                setNFTs(isMoreClicked ? nfts.concat(nftsData.nfts) : nftsData.nfts)
                setNFTPagination(nftsData.pagination)
                if (nftsData.nfts !== undefined && nftsData.nfts.length) {
                    setLoadMoreBtn(true)
                }
                if (nftsData.pagination !== undefined && nftsData.pagination.page === nftsData.pagination.pages) {
                    setLoadMoreBtn(false)
                }
                props.beforeNFT()
                setLoader(false)
                setLoadMore(false)
                if (props.setLoader)
                    props.setLoader(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nft.nftsAuth])

    useEffect(() => {
        let docs = document.querySelector('#total-result-sidebar')
        if (docs) {
            let totalRes = document.querySelectorAll('.jst-abv-cards')
            if (totalRes)
                totalRes = totalRes.length
            else
                totalRes = 0
            if (totalRes <= 1)
                docs.innerHTML = totalRes + ' result found'
            else
                docs.innerHTML = totalRes + ' results found'
            if (props.collectionId)
                props.itemCounterSetter(totalRes)
        }
    }, [nfts])

    useEffect(() => {
        if (!loader) {
            if (localStorage.getItem('activeBtn')) {
                document.querySelectorAll(".tabBtn").forEach((item) => {
                    item.classList.remove("active");
                });
                document.querySelector(localStorage.getItem('activeBtn')).classList.add('active')
            }
        }
    }, [loader])

    const loadMore = () => {
        const { page } = nftPagination
        setLoader(true)
        setLoadMore(true)
        // get more NFTs for explore section
        getNFTs(page + 1, 40, nftFilter)
    }

    const getNFTs = (page = 1, limit = 40, filter = null) => {
        let nftQS = { page, limit, explore: true, bothNfts: true }

        if (filter)
            nftQS = { ...nftQS, ...filter }

        if (props.collectionId)
            nftQS.collectionId = props.collectionId

        if (props.authorId)
            nftQS.authorId = props.authorId

        if (props.ownerId)
            nftQS.ownerId = props.ownerId

        const qs = ENV.objectToQueryString(nftQS)
        props.getNFTs(qs)
    }

    // apply search & filters
    useEffect(() => {
        if (props.filter)
            applyFilters(props.filter)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filter])

    const applyFilters = (filter) => {
        if (filter) {
            setLoader(true)
            setNftFitler(filter)
            getNFTs(1, 40, filter)
        }
    }

    const hideFilter = (check) => {
        setHideCheck(!hideCheck)
        if (check) {
            document.querySelector('#filter-sidebar').classList.add("d-none")
        }
        else {
            document.querySelector('#filter-sidebar').classList.remove("d-none")
        }
    }

    const sortBy = (num) => {
        setLoader(true)
        setNftFitler({ ...nftFilter, sortBy: num });
        getNFTs(1, 40, { ...nftFilter, sortBy: num });
    }

    return (
        <section className="explore-area" style={{ paddingTop: `${intro ? '50' : '30'}px` }}>
            {loader && <FullPageLoader />}
            <div className="container-fluid p-0">
                {
                    intro &&
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="intro text-center mb-4">
                                <h3 className="mt-3 mb-0">{initData.heading}</h3>
                                <p>{initData.content}</p>
                            </div>
                        </div>
                        <div className="w-100 d-flex mb-3">
                            <div className="upper-left-space d-none d-lg-block">&nbsp;</div>
                            <div className="w-100 d-flex flex-column flex-xl-row justify-content-between align-items-center align-items-xl-stretch">
                                <div className="marketplace-sort-btns mb-3 mb-xl-0">
                                </div>
                                <div className="filters-action">
                                    <button className="btn btn-white" onClick={() => { hideFilter(!hideCheck) }}>{!hideCheck ? "Hide" : "Show"} Filters <span className="fa fa-filter"></span></button>
                                    <div className="sort-box">
                                        <select className=" sorting" onChange={(e) => { if (e.target.value !== 'Sorting') sortBy(e.target.value); }}>
                                            <option value="">Sorting</option>
                                            <option value="1">Sort By: Name</option>
                                            <option value="2">Sort By: Price</option>
                                            <option value="3">Sort By: Last Created</option>
                                            <option value="4">Sort By: Last Modified</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={` ${Filter ? 'active' : ''} d-flex collection-wrapper`}>
                    <button className="side-toggle-btn" onClick={() => setFilter(!Filter)}><i className="fas fa-sort-amount-down-alt" /></button>
                    {
                        searchAndFilters &&
                        <FilterSearch applyFilters={applyFilters} showColFilters={true} showCatFilters={true} showAuthorFilters={true} />
                    }
                    <div className="nfts-collection-wrapper w-100">
                        <Row>
                            <Col md="12" className='text-center'>
                            </Col>
                        </Row>
                        <div className="row items explore-items">
                            {nfts && nfts.length > 0 ?
                                nfts.map((item, idx) => {
                                    if ((item.sellingMethod) || ((String(adminId) === String(item.owner._id)) && (item.purchaseCount < item.maxAllowed)) || (props.authorCheck) || (props.collectionCheck)) {
                                        if ((new Date().getTime() > new Date(item.auctionStartDate).getTime()) && (new Date().getTime() < new Date(item.auctionEndDate).getTime()) || (props.authorCheck) || (props.collectionCheck)) {
                                            return (
                                                <div key={`edth_${idx}`} className={`col-12 col-lg-6 col-xl-${props.lgCols ? props.lgCols : hideCheck ? '3' : '3'} item explore-item jst-abv-cards`} data-groups={item.group ? item.group : '["art","sports"]'}>
                                                    <div className="card">
                                                        <div className="image-over">
                                                            {
                                                                item.maxAllowed > 1 ?
                                                                    <span className='purchase-count-nft'>{item.purchaseCount} / {item.maxAllowed}</span> : ''
                                                            }
                                                            {
                                                                (String(adminId) === String(item.owner._id)) ?
                                                                    <Link to={`/item-details?item=${window.btoa(item._id)}&adminCheck=true${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                        <img className="card-img-top" src={ENV.url + '../../' + item.nftImageLink} alt="img" />
                                                                    </Link>
                                                                    :
                                                                    <Link to={`/item-details?item=${window.btoa(item._id)}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                        <img className="card-img-top" src={ENV.url + '../../' + item.nftImageLink} alt="img" />
                                                                    </Link>
                                                            }
                                                        </div>
                                                        <div className="card-caption col-12">
                                                            <div className="card-body">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div className="item-name-holder">
                                                                        {
                                                                            (String(adminId) === String(item.owner._id)) ?
                                                                                <Link to={`/item-details?item=${window.btoa(item._id)}&adminCheck=true${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                                    <h5 className="mb-0">{item.name}{item.nftNumber ? ' #' + String(item.nftNumber).padStart(3, '0') : ''}</h5>
                                                                                </Link>
                                                                                :
                                                                                <Link to={`/item-details?item=${window.btoa(item._id)}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                                    <h5 className="mb-0">{item.name}{item.nftNumber ? ' #' + String(item.nftNumber).padStart(3, '0') : ''}</h5>
                                                                                </Link>
                                                                        }
                                                                    </div>
                                                                    <span>
                                                                        {
                                                                            item.currentPrice && <strong className="d-flex align-items-center">
                                                                                <img src={Images.etheriumIcon} className="icon-etherium" alt="img" /> {item.currentPrice}
                                                                            </strong>
                                                                        }
                                                                    </span>
                                                                </div>

                                                                <div className="cont2 w-100 seller d-flex align-items-center justify-content-between my-3">
                                                                    <span className="text-break mr-2 d-flex justify-content-center aling-items-center">
                                                                        {item?.owner?.username}
                                                                        {
                                                                            item.owner && item.owner.verificationCheck ?
                                                                                <>
                                                                                    <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                        <FontAwesomeIcon icon={faCheck} />
                                                                                    </span>
                                                                                    <ReactTooltip />
                                                                                </>
                                                                                : ''
                                                                        }
                                                                    </span>
                                                                    {
                                                                        props.authorCheck ?
                                                                            item.sellingType ?
                                                                                <div className='d-flex flex-row-reverse'>
                                                                                    On Sell
                                                                                </div>
                                                                                :
                                                                                item.creator?._id !== item.owner?._id ?

                                                                                    <div className='d-flex flex-row-reverse'>
                                                                                        Owned By
                                                                                    </div>
                                                                                    :
                                                                                    ""
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }
                                    return false
                                })
                                : <div className="no-data border"><p className="text-center">No Items Found to Explore</p></div>
                            }
                        </div>
                    </div>
                </div>
                {loadMoreBtn ?
                    <div className="row">
                        <div className="col-12 text-center mb-3">
                            <span id="load-btn" className="btn banner-btn mt-4 " onClick={() => loadMore()}>{initData.btn_1}</span>
                        </div>
                    </div>
                    : ''
                }
            </div>
        </section>
    )
}

const mapStateToProps = state => ({
    nft: state.nft
});

export default connect(mapStateToProps, { beforeNFT, getNFTs })(ExploreAll);