import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import ReactTooltip from 'react-tooltip';
import { connect } from "react-redux";
import { beforeNFT, getNFT, upsertNFT } from "./nfts.action";
import FullPageLoader from "../../components/full-page-loader/full-page-loader";
import NFTPreview from "../nft-preview/nft-preview";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import validator from "validator";
import { changeSellingStatusWeb3, getPercentagesWeb3, putOnSale } from "./../../utils/web3";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { ENV } from "../../config/config";
const { decimalNumberValidator } = ENV;
const { dateRangeInitialSettings } = ENV;

const config1 = {
  price: {
    currency: ENV.currency,
    amount: "",
  },
  listingSchedule: {
    startDate: moment(),
    endDate: moment().add(6, "months"),
    startTime: moment(new Date()).add(2, 'minutes').format("HH:mm"),
    endTime: "23:59",
  },
  reserveFor: "", // if user selects reserve buyer
};
const config2 = {
  method: 1, // 1 = Sell to the highest bidder or 2 = Sell with the declining price
  startPrice: {
    currency: ENV.currency,
    amount: "",
  },
  duration: {
    startDate: moment(),
    endDate: moment().add(6, "months"),
    startTime: moment(new Date()).add(2, 'minutes').format("HH:mm"),
    endTime: "23:59",
  },
  // if user includes reserve price
  reservePrice: {
    currency: ENV.currency,
    amount: "",
  },
};

const SellNFT = (props) => {
  const [sharePercentages, setSharePercentages] = useState({}); // 1 = Fixed Price, 2 = Timed Auction
  const [sellingMethod, setSellingMethod] = useState(1); // 1 = Fixed Price, 2 = Timed Auction
  const [fixedPriceConfig, setFixedPriceConfig] = useState(config1);
  const [timedAuctionConfig, setTimedAuctionConfig] = useState(config2);
  const [nft, setNft] = useState(null);
  const [loader, setLoader] = useState(true); // NFT loader
  const reserveBuyer = false;
  const reservePrice = false;
  const [submitCheck, setSubmitCheck] = useState(false);
  const [avaliableNfts, setAvaliableNfts] = useState([]);
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [nftsMsg, setNftsMsg] = useState('');
  const [startFixedTimeMsg, setStartFixedTimeMsg] = useState('');
  const [startAuctionTimeMsg, setStartAuctionTimeMsg] = useState('');


  const [priceMsg, setPriceMsg] = useState({
    amount: "",
    reserveFor: "",
  });

  const [auctionMsg, setAuctionMsg] = useState({
    amount: "",
    price: "",
  });

  useEffect(() => {
    if (localStorage.getItem("encuse")) {
      window.scroll(0, 0);
      const { itemId } = props.match.params;
      if (itemId) props.getNFT(window.atob(itemId));
      else props.history.push("/explore-all");
    } else {
      props.history.push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when NFT data is received
  useEffect(() => {
    if (props.nft.nftsAuth) {
      const nft = props.nft.nftsData;
      setAvaliableNfts(nft.avaliableNfts)
      setNft(nft);
      getPercentages();
      // setLoader(false);
      props.beforeNFT();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nft.nftsAuth]);

  // when NFT data is updated
  useEffect(() => {
    if (props.nft.upsertAuth) {
      const { nft } = props.nft.nftsData;
      const pathname = nft.sellingMethod === 1 ? "/explore-all" : "/auctions";
      setLoader(false);
      props.beforeNFT();
      props.history.push(pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nft.upsertAuth]);

  // when an error is received
  useEffect(() => {
    if (props.error) {
      setLoader(false);
    }
  }, [props.error]);

  const getPercentages = async (e) => {
    let percentages = await getPercentagesWeb3();

    // let percentage = await getPercentagesWeb3();
    let platformShare = parseInt(percentages["platformSharePercent"]);
    let royaltyShare = parseInt(percentages["royaltyPercent"]);
    platformShare = platformShare > 0 ? platformShare / 100 : 0;
    royaltyShare = royaltyShare > 0 ? royaltyShare / 100 : 0;

    let percentObject = {
      platformShare: platformShare,
      royaltyShare: royaltyShare
    }
    setSharePercentages(percentObject);
    setLoader(false);
  };
  const onChange = (e) => {
    let { name, value } = e.target;
    let data = null;
    if (sellingMethod === 1) data = fixedPriceConfig;
    else data = timedAuctionConfig;

    const keys = name.split("."); // fixedPriceConfig, price, currency
    if (keys[1] === 'price' || keys[1] === 'startPrice') {
      value = Number(value).toFixed(8).replace(/\.?0+$/, "");
    }
    if (keys && keys[2]) data[keys[1]][keys[2]] = value;
    else if (keys && keys[1]) data[keys[1]] = value;

    if (sellingMethod === 1) {
      const startTime = moment(data.listingSchedule.startTime, 'HH:mm')
      data.listingSchedule.startDate.set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: startTime.get('second')
      });
      const endTime = moment(data.listingSchedule.endTime, 'HH:mm')
      data.listingSchedule.endDate.set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: endTime.get('second')
      });
      setFixedPriceConfig({ ...data });
    }
    else {
      const startTime = moment(data.duration.startTime, 'HH:mm')
      data.duration.startDate.set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: startTime.get('second')
      });
      const endTime = moment(data.duration.endTime, 'HH:mm')
      data.duration.endDate.set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: endTime.get('second')
      });
      setTimedAuctionConfig({ ...data });
    }
  };

  const handleDateChange = (e, picker) => {
    const { name } = e.target;
    const { startDate, endDate } = picker;

    // set start date
    onChange({
      target: {
        name: `${name}.startDate`,
        value: startDate,
      },
    });

    // set end date
    onChange({
      target: {
        name: `${name}.endDate`,
        value: endDate,
      },
    });
  };

  const submit = async () => {
    setSubmitCheck(true);
    if (avaliableNfts.length) {
      if (!selectedNfts.length) {
        setNftsMsg('Please Select An Nft');
        return
      }
    }
    if (sellingMethod === 1) {
      let d = new Date()
      if (new Date('1970-01-01 ' + config1.listingSchedule.startTime) < new Date('1970-01-01 ' + d.getHours() + ':' + d.getMinutes())) {
        setStartFixedTimeMsg('Please Select Suitable Start Time')
        return
      }
      let amount = "";
      let reserveFor = "";
      if (validator.isEmpty(fixedPriceConfig.price.amount)) {
        amount = "Amount Is Required.";
      }
      if (reserveBuyer) {
        if (validator.isEmpty(fixedPriceConfig.reserveFor)) {
          reserveFor = "Address Is Required.";
        }
      }
      setPriceMsg({ amount, reserveFor });
      if (amount || reserveFor) {
        return;
      }
    } else {
      let dte = new Date()
      if (new Date('1970-01-01 ' + config2.duration.startTime) < new Date('1970-01-01 ' + dte.getHours() + ':' + dte.getMinutes())) {
        setStartAuctionTimeMsg('Please Select Suitable Start Time')
        return
      }
      let amount = "";
      let price = "";
      if (validator.isEmpty(timedAuctionConfig.startPrice.amount)) {
        amount = "Amount Is Required.";
      }
      if (reservePrice) {
        if (validator.isEmpty(timedAuctionConfig.reservePrice.amount)) {
          price = "Amount Is Required.";
        }
      }
      setAuctionMsg({ amount, price });
      if (amount || price) {
        return;
      }
    }
    let payload = {
      _id: nft._id,
      sellingMethod,
      sellingConfig: JSON.stringify(
        sellingMethod === 1 ? fixedPriceConfig : timedAuctionConfig
      ),
      allNfts: nft.allNfts
    };
    if (avaliableNfts.length) {
      payload.sellingNfts = JSON.stringify(selectedNfts)
      payload.avaliableNfts = JSON.stringify(avaliableNfts.filter(item => !selectedNfts.includes(item)))
    }
    setFixedPriceConfig(config1);
    setTimedAuctionConfig(config2);
    setLoader(true);
    let sellingPrice = 0;
    if (sellingMethod === 1) {
      let sellingConfig = JSON.parse(payload.sellingConfig);
      sellingPrice = sellingConfig.price.amount;
    } else {
      let sellingConfig = JSON.parse(payload.sellingConfig);
      sellingPrice = sellingConfig.startPrice.amount;
    }
    let _nftData = {
      tokenId: nft.tokenId || 1,
      selling: sellingMethod,
      price: sellingPrice,
    };
    let sellingStatus = await changeSellingStatusWeb3(_nftData);
    if (!sellingStatus) {
      setLoader(false);
      return false;
    }
    if (nft.assetId) {
      _nftData = {
        assetId: nft.assetId,
        assetNumbers: selectedNfts
      }
      let PutOnSale = await putOnSale(_nftData)
      if (!PutOnSale) {
        setLoader(false);
        return false;
      }
      else {
        payload.currentPrice = sellingPrice
        var formData = new FormData();
        for (const key in payload) {
          formData.append(key, payload[key]);
        }
        props.upsertNFT("edit", formData, "PUT");
      }
    }
    else {
      payload.currentPrice = sellingPrice
      var formData = new FormData();
      for (const key in payload) {
        formData.append(key, payload[key]);
      }
      props.upsertNFT("edit", formData, "PUT");
    }
  };

  const selNft = (itm) => {
    let list = document.querySelector(`#selling-nft-${itm}`).classList
    list.forEach((item) => {
      if (item === 'sold-btn') {
        let selItems = selectedNfts
        selItems = selItems.filter((item) => { if (itm !== item) { return (item) } else { return (false) } })
        selItems.sort()
        setSelectedNfts([...new Set(selItems)])
        list.remove("sold-btn")
      }
      else {
        let selItems = selectedNfts
        selItems.push(itm)
        selItems.sort()
        setSelectedNfts([...new Set(selItems)])
        list.add("sold-btn")
      }
    })
  }

  const selAllBtn = () => {
    avaliableNfts.forEach((item) => {
      let list = document.querySelector(`#selling-nft-${item}`).classList
      list.forEach((itm) => {
        let selItems = selectedNfts
        selItems.push(item)
        selItems.sort()
        setSelectedNfts([...new Set(selItems)])
        list.add("sold-btn")
      })
    })
  }

  const unselAllBtn = () => {
    avaliableNfts.forEach((itm) => {
      let list = document.querySelector(`#selling-nft-${itm}`).classList
      list.forEach((item) => {
        list.remove("sold-btn")
      })
      setSelectedNfts([])
    })
  }

  return (
    <div className="padding-wrapper _items-sell">
      <div className="container">
        <span className="head-wrapper d-inline-block align-top">List of Bundle for Sale</span>
        {loader && <FullPageLoader />}
        <div
          className=""
        >
          <ReactTooltip />
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="tabs-container order-2 order-md-1">
              <div className="market-tabs-wrapper">
                <Tab.Container
                  id="marketplace-tabs"
                  defaultActiveKey="fixedPrice"
                >
                  <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                      <Nav variant="pills" className="flex-row justify-content-between  position-relative">
                        <Nav.Item className="nav-item-wrapper mr-sm-3">
                          <div className="mb-2"><b>Type</b></div>
                          <Nav.Link
                            eventKey="fixedPrice"
                            onClick={() => setSellingMethod(1)}
                          >
                            <i className="fas fa-dollar-sign" />
                            <div>Fixed Price</div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item-wrapper">
                          <div className="d-flex justify-content-end">
                            <div
                              className="text-right mb-2 w-fit"
                              data-tip="We offer two types of listing options: Fixed Price and Timed Auction."
                            >
                              <div className="price-tooltip">
                                <i className="fas fa-exclamation-circle ml-2" />
                              </div>
                            </div>
                          </div>
                          <Nav.Link
                            eventKey="timedAuction"
                            onClick={() => setSellingMethod(2)}
                          >
                            <i className="fas fa-clock" />
                            <div>Timed Auction</div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                  <div className="design-tabs market-tab-content">
                    <Container fluid>
                      <Tab.Content>
                        <Tab.Pane eventKey="fixedPrice">
                          <div
                            className="price-wrapper d-flex"
                          >
                            <div className="price-text position-relative">
                              <div className=" d-flex justify-content-between">
                                <div className="mb-2">
                                  <b>Price</b>
                                </div>
                                <div data-tip="List price and listing schedule cannot be edited once the item is listed. You will need to wait for your listing to get cancelled automatically and relist the item with the updated price and dates." className="text-right mb-2 ">
                                  <i className="fas fa-exclamation-circle ml-2" />
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <button type="button" className="ethereum-btn">
                                  <FontAwesomeIcon icon={faEthereum} data-tip="Ethereum" />
                                  <span className="ml-2">{ENV.currency}</span>
                                </button>
                                <div className="ethereum-input">
                                  <input
                                    type="text"
                                    name="fixedPriceConfig.price.amount"
                                    placeholder="Amount"
                                    className="amount-btn"
                                    style={{
                                      borderRadius: "4px",
                                    }}
                                    onChange={(e) => {
                                      onChange(e);
                                      if (submitCheck) {
                                        if (e.target.value) {
                                          setPriceMsg({
                                            ...priceMsg,
                                            amount: "",
                                          });
                                        } else {
                                          setPriceMsg({
                                            ...priceMsg,
                                            amount: "Amount Is Required.",
                                          });
                                        }
                                      }
                                    }}
                                    onKeyDown={(e) => decimalNumberValidator(e)}
                                    defaultValue={fixedPriceConfig.price.amount}
                                    required
                                  />
                                </div>
                                <div className="text-right mb-2 dollar-wrapper">
                                  US$
                                  {props.app.rateAuth
                                    ? ENV.convertBnbToUsd(
                                      fixedPriceConfig.price.amount,
                                      props.app.rate
                                    )
                                    : "0.00"}
                                </div>
                              </div>
                              <div>
                                <label
                                  className={`text-danger pl-1 ${priceMsg.amount ? `` : `d-none`
                                    }`}
                                >
                                  {priceMsg.amount}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="price-wrapper d-flex">
                            <div className="price-text">
                              <div className="">
                                <b>Schedule Listing</b>
                              </div>
                              <div className="date-picker">
                                <DateRangePicker
                                  initialSettings={dateRangeInitialSettings}
                                  onApply={handleDateChange}
                                >
                                  <button
                                    name="fixedPriceConfig.listingSchedule"
                                    className="calender-btn"
                                  >
                                    {fixedPriceConfig.listingSchedule.startDate.format(
                                      "MMMM D, YYYY"
                                    )}{" "}
                                    -{" "}
                                    {fixedPriceConfig.listingSchedule.endDate.format(
                                      "MMMM D, YYYY"
                                    )}
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-calendar-week" />
                                  </button>
                                </DateRangePicker>
                              </div>

                              <div className="row">
                                <div className="mr-auto my-3 budle-wrapper">
                                  <label className="mb-2"><b>From</b></label>
                                  <input
                                    type="time"
                                    name="fixedPriceConfig.listingSchedule.startTime"
                                    placeholder="Start Time"
                                    onChange={(e) => onChange(e)}
                                    defaultValue={
                                      fixedPriceConfig.listingSchedule.startTime
                                    }
                                    required
                                  />
                                  <div className="pt-5">
                                    <label className={`text-danger pl-1 ${startFixedTimeMsg ? `` : `d-none`}`}>{startFixedTimeMsg}</label>
                                  </div>
                                </div>

                                <div className="ml-auto my-3 budle-wrapper">
                                  <label className="mb-2"><b>To</b></label>
                                  <input
                                    type="time"
                                    name="fixedPriceConfig.listingSchedule.endTime"
                                    placeholder="End Time"
                                    onChange={(e) => onChange(e)}
                                    defaultValue={
                                      fixedPriceConfig.listingSchedule.endTime
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {reserveBuyer && (
                            <div
                              className="mb-3 budle-wrapper"
                              style={{
                                borderBottom: "1px solid",
                                paddingBottom: "2rem",
                              }}
                            >
                              <span>
                                <input
                                  type="text"
                                  placeholder="0xf45a189..."
                                  required
                                  defaultValue={fixedPriceConfig.reserveFor}
                                  onChange={(e) => {
                                    onChange(e);
                                    if (submitCheck) {
                                      if (e.target.value) {
                                        setPriceMsg({
                                          ...priceMsg,
                                          reserveFor: "",
                                        });
                                      } else {
                                        setPriceMsg({
                                          ...priceMsg,
                                          reserveFor: "Address Is Required.",
                                        });
                                      }
                                    }
                                  }}
                                  name="fixedPriceConfig.reserveFor"
                                />
                              </span>
                              <div>
                                <label
                                  className={`text-danger pl-1 ${priceMsg.reserveFor ? `` : `d-none`
                                    }`}
                                >
                                  {priceMsg.reserveFor}
                                </label>
                              </div>
                            </div>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="timedAuction">
                          <div className="form-group">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="method" className="mb-2">
                                <b>Method</b>
                              </label>
                              <div data-tip="Sell to the highest bidder or sell with the declining price, which allows the listing to reduce the price until a buyer is found.">
                                <i className="fas fa-exclamation-circle ml-2" />
                              </div>
                            </div>
                            <div className="custom-select-holder">
                              <select className="form-control" id="timed-auction-method" name="timedAuctionConfig.method" onChange={(e) => onChange(e)}>
                                <option value={1}>
                                  Sell to the highest bidder
                                </option>
                                <option value={2}>
                                  Sell with the declining price
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="price-wrapper d-flex">
                            <div className="price-text position-relative">
                              <div className="mb-2 absolute-wrapper">
                                <b>Starting Price</b>
                              </div>
                              <div
                                className="text-right mb-2"
                                data-tip="List price and listing schedule cannot be edited once the item is listed. You will need to wait for your listing to get cancelled automatically and relist the item with the updated price and dates."
                              >
                                <i className="fas fa-exclamation-circle ml-2" />
                              </div>
                              <div className="d-flex">
                                <button type="button" className="ethereum-btn">
                                  <FontAwesomeIcon icon={faEthereum} data-effect="float" data-tip="Ethereum" />
                                  <span className="ml-2">{ENV.currency}</span>
                                </button>
                                <input
                                  type="text"
                                  name="timedAuctionConfig.startPrice.amount"
                                  placeholder="Amount"
                                  className="amount-btn ml-3"
                                  style={{
                                    borderRadius: "4px",
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    if (submitCheck) {
                                      if (e.target.value) {
                                        setAuctionMsg({
                                          ...auctionMsg,
                                          amount: "",
                                        });
                                      } else {
                                        setAuctionMsg({
                                          ...auctionMsg,
                                          amount: "Amount Is Required.",
                                        });
                                      }
                                    }
                                  }}
                                  onKeyDown={(e) => decimalNumberValidator(e)}
                                  defaultValue={timedAuctionConfig.startPrice.amount}
                                  required
                                />
                                <div className="text-right mb-2 dollar-wrapper">
                                  US$
                                  {props.app.rateAuth
                                    ? ENV.convertBnbToUsd(
                                      timedAuctionConfig.startPrice.amount,
                                      props.app.rate
                                    )
                                    : "0.00"}
                                </div>
                              </div>
                              <div>
                                <label
                                  className={`text-danger pl-1 ${auctionMsg.amount ? `` : `d-none`
                                    }`}
                                >
                                  {auctionMsg.amount}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="price-wrapper d-flex">
                            <div className="price-text">
                              <div>
                                <b>Duration</b>
                              </div>
                              <div className="date-picker">
                                <DateRangePicker
                                  initialSettings={dateRangeInitialSettings}
                                  onApply={handleDateChange}
                                >
                                  <button
                                    name="timedAuctionConfig.duration"
                                    className="calender-btn"
                                    data-toggle="tooltip"
                                    title="Date"
                                  >
                                    <i className="fas fa-calendar-week" />
                                    &nbsp;&nbsp;&nbsp;
                                    {timedAuctionConfig.duration.startDate.format(
                                      "MMMM D, YYYY"
                                    )}{" "}
                                    -{" "}
                                    {timedAuctionConfig.duration.endDate.format(
                                      "MMMM D, YYYY"
                                    )}
                                  </button>
                                </DateRangePicker>
                              </div>

                              <div className="row">
                                <div className="mr-auto my-3 budle-wrapper">
                                  <label className="mb-2"><b>From</b></label>
                                  <input
                                    type="time"
                                    name="timedAuctionConfig.duration.startTime"
                                    placeholder="Start Time"
                                    onChange={(e) => onChange(e)}
                                    defaultValue={
                                      timedAuctionConfig.duration.startTime
                                    }
                                    required
                                  />
                                  <div className="pt-5">
                                    <label className={`text-danger pl-1 ${startAuctionTimeMsg ? `` : `d-none`}`}>{startAuctionTimeMsg}</label>
                                  </div>
                                </div>

                                <div className="ml-auto my-3 budle-wrapper">
                                  <label className="mb-2"><b>To</b></label>
                                  <input
                                    type="time"
                                    name="timedAuctionConfig.duration.endTime"
                                    placeholder="End Time"
                                    onChange={(e) => onChange(e)}
                                    defaultValue={
                                      timedAuctionConfig.duration.endTime
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {reservePrice && (
                            <div className="d-flex">
                              <button type="button" className="ethereum-btn">
                                <svg
                                  viewBox="0 0 126.61 126.61"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  height="18"
                                  width="18"
                                >
                                  <g>
                                    <path d="m38.73 53.2 24.59-24.58 24.6 24.6 14.3-14.31-38.9-38.91-38.9 38.9z" />
                                    <path d="m0 63.31 14.3-14.31 14.31 14.31-14.31 14.3z" />
                                    <path d="m38.73 73.41 24.59 24.59 24.6-24.6 14.31 14.29-38.9 38.91-38.91-38.88z" />
                                    <path d="m98 63.31 14.3-14.31 14.31 14.3-14.31 14.32z" />
                                    <path d="m77.83 63.3-14.51-14.52-10.73 10.73-1.24 1.23-2.54 2.54 14.51 14.5 14.51-14.47z" />
                                  </g>
                                </svg>
                                <span className="ml-2">{ENV.currency}</span>
                              </button>
                              <input
                                type="text"
                                name="timedAuctionConfig.reservePrice.amount"
                                placeholder="Amount"
                                className="amount-btn ml-3"
                                style={{
                                  borderRadius: "4px",
                                }}
                                onChange={(e) => {
                                  onChange(e);
                                  if (submitCheck) {
                                    if (e.target.value) {
                                      setAuctionMsg({
                                        ...auctionMsg,
                                        price: "",
                                      });
                                    } else {
                                      setAuctionMsg({
                                        ...auctionMsg,
                                        price: "Amount Is Required.",
                                      });
                                    }
                                  }
                                }}
                                onKeyDown={(e) => decimalNumberValidator(e)}
                                defaultValue={
                                  timedAuctionConfig.reservePrice.amount
                                }
                                required
                              />
                            </div>
                          )}
                          {
                            timedAuctionConfig.reservePrice.amount &&
                            <div className="text-right mb-2">
                              US$
                              {props.app.rateAuth
                                ? ENV.convertBnbToUsd(
                                  timedAuctionConfig.reservePrice.amount,
                                  props.app.rate
                                )
                                : "0.00"}
                            </div>
                          }
                          <div>
                            <label
                              className={`text-danger pl-1 ${auctionMsg.price ? `` : `d-none`
                                }`}
                            >
                              {auctionMsg.price}
                            </label>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                      {
                        (sharePercentages && sharePercentages.platformShare) || (sharePercentages && sharePercentages.royaltyShare) ?
                          <div className="d-flex justify-content-between my-3">
                            <div>
                              <b>Fees</b>
                            </div>
                            <div data-toggle="tooltip" title="Lorem23">
                              <i className="fas fa-exclamation-circle" />
                            </div>
                          </div> : <div className="d-flex justify-content-between my-3"></div>
                      }
                      {
                        sharePercentages && sharePercentages.platformShare ?
                          <div className="d-flex justify-content-between mb-4">
                            <div>Service Fee</div>
                            <div>{sharePercentages.platformShare}%</div>
                          </div>
                          : ''
                      }
                      {
                        sharePercentages && sharePercentages.royaltyShare ?
                          <div className="d-flex justify-content-between mb-4">
                            <div>Creator Royalty</div>
                            <div>{sharePercentages.royaltyShare}%</div>
                          </div>
                          : ''
                      }
                      {
                        avaliableNfts && avaliableNfts.length ?
                          <div className="pt-3 pb-5">
                            <div className="mb-5 flex-column flex-sm-row d-flex justify-content-between align-items-center">
                              <div className="mb-3 mb-sm-0">
                                Select NFT Numbers To Sell
                              </div>
                              <div className="d-flex flex-column flex-sm-row">
                                <div className="mb-3 mb-sm-0">
                                  <button className="btn btn-bordered-white p-3 mx-1" onClick={() => unselAllBtn()}>
                                    Unselect All NFT Numbers
                                  </button>
                                </div>
                                <div className="mb-3 mb-sm-0">
                                  <button className="btn btn-bordered-white p-3 mx-1" onClick={() => selAllBtn()}>
                                    Select All NFT Numbers
                                  </button>
                                </div>
                              </div>
                            </div>
                            {
                              avaliableNfts.map((item, index) => {
                                return (
                                  <span key={index}
                                    className="mb-2 mr-2 cursor-pointer selling-nfts-count d-inline-block align-top"
                                    id={`selling-nft-${item}`}
                                    onClick={() => { selNft(item) }}
                                  >
                                    {item}
                                  </span>
                                )
                              })
                            }
                            <div className="pt-5">
                              <label className={`text-danger pl-1 ${nftsMsg ? `` : `d-none`}`}>{nftsMsg}</label>
                            </div>
                          </div>
                          : ''
                      }
                      <div>
                        <button
                          className="complete-listing-btn p-3"
                          onClick={() => submit()}
                        >
                          Complete Listing
                        </button>
                      </div>
                    </Container>
                  </div>
                </Tab.Container>
              </div>
            </div>
            <div className="summary-container order-1 order-md-2">
              {nft && <NFTPreview {...nft} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  nft: state.nft,
  error: state.error,
  app: state.app,
});

export default connect(mapStateToProps, {
  beforeNFT,
  getNFT,
  upsertNFT,
})(SellNFT);
