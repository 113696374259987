import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { faAngleDown, faAngleUp, faDollarSign, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'rc-pagination/assets/index.css';
import { useEffect, useState } from 'react';
import { Button, Table } from "react-bootstrap";
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Images } from "../../assets/asset";
import { ENV } from "../../config/config";
import FullPageLoader from '../full-page-loader/full-page-loader';
import { beforeActivity, beforeLeaderboard, getActivities, getLeaderboard, refreshActivities } from './activity.action';
import { faCheck, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
const { globalPlaceholderImage } = ENV

const Activity = (props) => {

    const [activity, setActivity] = useState([])
    const [loader, setLoader] = useState(true)
    const [page, setPage] = useState(2)
    const [moreCheck, setMoreCheck] = useState(true)
    const [type, setType] = useState(null)
    const [searchVal, setSearchVal] = useState('')
    const [leadersboard, setLeadersboard] = useState([])
    const [activePeriod, setActivePeriod] = useState('0')
    const [activeOrder, setActiveOrder] = useState('0')
    const [activeSort, setActiveSort] = useState('0')
    const history = useHistory()


    useEffect(() => {
        window.scroll(0, 0)
        localStorage.removeItem('leaderboardSel')
        localStorage.removeItem('activityActiveBtn')
        props.getLeaderboard()
        document.querySelectorAll('.nav-link').forEach((item) => {
            item.classList.remove('activeNav')
        })
        document.querySelector(`#link-navv-6`).classList.add('activeNav')
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.activities.activitiesAuth) {
            const { activities } = props.activities.activities
            if (activities.length) {
                setActivity([...activity, ...activities])
            }
            else {
                setMoreCheck(false)
            }
            setLoader(false)
            props.beforeActivity()
        }
        // eslint-disable-next-line
    }, [props.activities.activitiesAuth])

    useEffect(() => {
        if (props.activities.leadersboardAuth) {
            const { leadersboard } = props.activities.leadersboard
            setLeadersboard(leadersboard)
            setLoader(false)
            props.beforeLeaderboard()
        }
        // eslint-disable-next-line
    }, [props.activities.leadersboardAuth])

    useEffect(() => {
        if (props.activities.refreshActivitiesAuth) {
            let refreshedActivities = props.activities.refreshActivities.data.activities
            setActivity(refreshedActivities)
            setLoader(false)
            props.beforeActivity()
        }
    }, [props.activities.refreshActivitiesAuth])

    useEffect(() => {
        if (leadersboard) {
            if (localStorage.getItem('leaderboardSel')) {
                document.querySelector('#leaderbrdSel').value = localStorage.getItem('leaderboardSel')
            }
        }
    }, [leadersboard])

    useEffect(() => {
        if (!loader) {
            if (localStorage.getItem('activityActiveBtn')) {
                document.querySelectorAll('.up-arrow').forEach((item) => {
                    item.classList.remove('active')
                })
                document.querySelector(`#${localStorage.getItem('activityActiveBtn')}`).classList.add('active')
            }
        }
    }, [loader])

    useEffect(() => {
        setLoader(true)
        setActivity([])
        setMoreCheck(true)
        setPage(2)
        props.getActivities(type);
        // eslint-disable-next-line
    }, [type])

    const fetchData = () => {
        let qs
        if (searchVal)
            qs = ENV.objectToQueryString({ page, search: searchVal })
        else
            qs = ENV.objectToQueryString({ page, search: searchVal })
        props.getActivities(type, qs)
        setPage(page + 1)
    }

    const search = (e) => {
        e.preventDefault()
        if (e.target[0].value) {
            setLoader(true)
            setActivity([])
            setMoreCheck(true)
            const qs = ENV.objectToQueryString({ page, search: e.target[0].value })
            props.getActivities(type, qs)
            setPage(page + 1)
        }

    }

    const leaderBoardSel = (e, sortBy = 0, order = 0) => {
        setLoader(true)
        let period = e?.target?.value || activePeriod;
        const qs = ENV.objectToQueryString({ period, sortBy, order })
        props.getLeaderboard(qs)
        setActivePeriod(period)
        setActiveOrder(order)
        setActiveSort(sortBy)
    }

    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    const refreshActivities = () => {
        setLoader(true)
        setPage(2)
        setMoreCheck(true)
        props.refreshActivities()
    }

    return (
        loader ?
            <FullPageLoader />
            :
            <section className="activity-area load-more">
                <div className="container custom-container">
                    <div className="row pb-3">
                        <div className='col-12'>
                            <div className="intro mb-3 px-md-3">
                                <div className="intro-content">
                                    <h3 className="mt-3 mb-0">{"LeaderBoard"}</h3>
                                </div>
                            </div>
                            <div className="filters-action mb-3 px-md-3">
                                <div>
                                    <div className="text-center">
                                        <Button className=" banner-btn " variant="primary" size="lg" onClick={() => { history.push('/activity/leaderboard') }}>
                                            View All
                                        </Button>
                                    </div>
                                </div>
                                <div className="sort-box">
                                    <select className="sorting" id='leaderbrdSel' defaultValue={activePeriod} value={activePeriod} onChange={(e) => { leaderBoardSel(e) }}>
                                        <option value="0">ALL TIME LEADERBOARD</option>
                                        <option value="1">MONTHLY LEADERBOARD</option>
                                        <option value="2">3 MONTHS LEADERBOARD</option>
                                        <option value="3">6 MONTHS LEADERBOARD</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-none d-md-block">
                                <div className="table-responsive p-3">
                                    <Table hover id="bd-zero" className="activity-logs-table">
                                        <thead>
                                            <tr>
                                                <th className="name-col">User</th>
                                                <th className="text-center sort-col">
                                                    <div className="d-inline-block align-top">
                                                        <div className="d-flex align-items-center">
                                                            <span className="etherium-icon-holder mr-2">
                                                                <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                            </span>
                                                            <span className="mr-2">Bought</span>
                                                            <span className="d-flex flex-column up-down-arrows">
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 1 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 1, 1)}>
                                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                                </span>
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 1 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 1, 0)}>
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-center sort-col">
                                                    <div className="d-inline-block align-top">
                                                        <div className="d-flex align-items-center">
                                                            <span className="etherium-icon-holder mr-2">
                                                                <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                            </span>
                                                            <span className="mr-2">Sold</span>
                                                            <span className="d-flex flex-column up-down-arrows">
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 2 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 2, 1)}>
                                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                                </span>
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 2 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 2, 0)}>
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-center sort-col">
                                                    <div className="d-inline-block aling-top">
                                                        <div className="d-flex align-items-center">
                                                            <span className="etherium-icon-holder mr-2">
                                                                <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                            </span>
                                                            <span className="mr-2">Volume Traded</span>
                                                            <span className="d-flex flex-column up-down-arrows">
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 3 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 3, 1)}>
                                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                                </span>
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 3 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 3, 0)}>
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-center sort-col">
                                                    <div className="d-inline-block align-top">
                                                        <div className="d-flex align-items-center">
                                                            <span className="mr-2">NFTs Owned</span>
                                                            <span className="d-flex flex-column up-down-arrows">
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 4 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 4, 1)}>
                                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                                </span>
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 4 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 4, 0)}>
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-center sort-col">
                                                    <div className="d-inline-block align-top">
                                                        <div className="d-flex align-items-center">
                                                            <span className="mr-2">NFTs Created</span>
                                                            <span className="d-flex flex-column up-down-arrows">
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 5 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 5, 1)}>
                                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                                </span>
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 5 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 5, 0)}>
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                leadersboard && leadersboard.length ?
                                                    leadersboard.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="name-col">
                                                                    <span className="d-flex align-items-center">
                                                                        <img src={item.profileImageLink ? ENV.webUrl + item.profileImageLink : globalPlaceholderImage} className='avatar-sm rounded-circle mr-2' alt='img' />
                                                                        <Link to={`/author/${item._id}`} className="address-truncate d-flex" data-effect="float">
                                                                            {item.username ? item.username : item.address}
                                                                            {
                                                                                item.verificationCheck ?
                                                                                    <>
                                                                                        <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                            <FontAwesomeIcon icon={faCheck} />
                                                                                        </span>
                                                                                        <ReactTooltip />
                                                                                    </>
                                                                                    : ''
                                                                            }
                                                                        </Link>
                                                                    </span>
                                                                </td>
                                                                <td className="text-center sort-col">{item.bought !== undefined ? item.bought.toFixed(8) : 0.00}</td>
                                                                <td className="text-center sort-col">{item.sold !== undefined ? item.sold.toFixed(8) : 0.00}</td>
                                                                <td className="text-center sort-col">{item.tradeVolume !== undefined ? item.tradeVolume.toFixed(8) : 0.00}</td>
                                                                <td className="text-center sort-col">{item.ownedNFTs ? parseInt(item.ownedNFTs) : 0}</td>
                                                                <td className="text-center sort-col">{item.createdNFTs ? parseInt(item.createdNFTs) : 0}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <tr>No Item To Show</tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="mobile-leader-board px-md-3 d-block d-md-none">
                                <div className="filter-block mb-4 position-relative overflow-hidden">
                                    <div className="d-flex filter-header align-items-center cursor-pointer" onClick={toggleClass}>
                                        <FontAwesomeIcon className="mr-2" icon={faFilter} />
                                        <span>Filters</span>
                                    </div>
                                    <ul className={isActive ? "list-unstyled leaderboard-filters active" : "list-unstyled leaderboard-filters"}>
                                        <li className="d-flex align-items-center">
                                            <span className="filter-name mr-2">Bought</span>
                                            <span className="d-flex flex-column up-down-arrows">
                                                <span className={`up-arrow cursor-pointer ${activeSort === 1 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 1, 1)}>
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                </span>
                                                <span className={`up-arrow cursor-pointer ${activeSort === 1 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 1, 0)}>
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span>
                                            </span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <span className="filter-name mr-2">Sold</span>
                                            <span className="d-flex flex-column up-down-arrows">
                                                <span className={`up-arrow cursor-pointer ${activeSort === 2 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 2, 1)}>
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                </span>
                                                <span className={`up-arrow cursor-pointer ${activeSort === 2 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 2, 0)}>
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span>
                                            </span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <span className="filter-name mr-2">Volume Traded</span>
                                            <span className="d-flex flex-column up-down-arrows">
                                                <span className={`up-arrow cursor-pointer ${activeSort === 3 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 3, 1)}>
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                </span>
                                                <span className={`up-arrow cursor-pointer ${activeSort === 3 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 3, 0)}>
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span>
                                            </span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <span className="filter-name mr-2">NFTS Owned</span>
                                            <span className="d-flex flex-column up-down-arrows">
                                                <span className={`up-arrow cursor-pointer ${activeSort === 4 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 4, 1)} >
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                </span>
                                                <span className={`up-arrow cursor-pointer ${activeSort === 4 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 4, 0)} >
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span>
                                            </span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <span className="filter-name mr-2">NFTS Created</span>
                                            <span className="d-flex flex-column up-down-arrows">
                                                <span className={`up-arrow cursor-pointer ${activeSort === 5 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 5, 1)}>
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                </span>
                                                <span className={`up-arrow cursor-pointer ${activeSort === 5 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 5, 0)}>
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <ul className="activity-items list-unstyled">
                                    {
                                        leadersboard && leadersboard.length ?
                                            leadersboard.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <ul className="list-unstyled activity-item-details">
                                                            <li className="d-flex align-items-center">
                                                                <strong className="mr-2">User:</strong>
                                                                <span className="d-flex align-items-center">
                                                                    <img src={item.profileImageLink ? ENV.webUrl + item.profileImageLink : globalPlaceholderImage} className="avatar-sm rounded-circle mr-2" alt='img' />
                                                                    <Link className="address-truncate d-flex" data-effect="float" to={`/author/${item._id}`}>
                                                                        {item.username ? item.username : item.address}
                                                                        {
                                                                            item.verificationCheck ?
                                                                                <>
                                                                                    <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                        <FontAwesomeIcon icon={faCheck} />
                                                                                    </span>
                                                                                    <ReactTooltip />
                                                                                </>
                                                                                : ''
                                                                        }
                                                                    </Link>
                                                                </span>
                                                            </li>
                                                            <li className="d-flex align-items-center">
                                                                <strong className="d-flex align-items-center">
                                                                    <span className="etherium-icon-holder mr-2">
                                                                        <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                                    </span>
                                                                    <span className="mr-2">Bought</span>
                                                                </strong>
                                                                <span>{item.bought ? item.bought.toFixed(5) : 0}</span>
                                                            </li>
                                                            <li className="d-flex align-items-center">
                                                                <strong className="d-flex align-items-center">
                                                                    <span className="etherium-icon-holder mr-2">
                                                                        <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                                    </span>
                                                                    <span className="mr-2">Sold</span>
                                                                </strong>
                                                                <span>{item.sold ? item.sold.toFixed(5) : 0}</span>
                                                            </li>
                                                            <li className="d-flex align-items-center">
                                                                <strong className="d-flex align-items-center">
                                                                    <span className="etherium-icon-holder mr-2">
                                                                        <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                                    </span>
                                                                    <span className="mr-2">Volume Traded</span>
                                                                </strong>
                                                                <span>{item.tradeVolume ? item.tradeVolume.toFixed(5) : 0}</span>
                                                            </li>
                                                            <li className="d-flex align-items-center">
                                                                <strong className="d-flex align-items-center">
                                                                    <span className="etherium-icon-holder mr-2">
                                                                        <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                                    </span>
                                                                    <span className="mr-2">NFTs Owned</span>
                                                                </strong>
                                                                <span>{item.ownedNFTs ? item.ownedNFTs.toFixed(5) : 0}</span>
                                                            </li>
                                                            <li className="d-flex align-items-center">
                                                                <strong className="d-flex align-items-center">
                                                                    <span className="etherium-icon-holder mr-2">
                                                                        <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                                    </span>
                                                                    <span className="mr-2">NFTs Created</span>
                                                                </strong>
                                                                <span>{item.createdNFTs ? item.createdNFTs.toFixed(5) : 0}</span>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                )
                                            })
                                            : <li>No Item To Show</li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="px-md-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center flex-column flex-md-row mb-3 mb-md-5">
                                    <div className="intro mb-3 mb-md-0 d-flex w-100 pr-2">
                                        <div className="intro-content text-white d-flex justify-content-between align-items-center flex-column flex-lg-row w-100">
                                            <h3 className="mb-3 mb-lg-0">{"List Of Activities"}</h3>
                                            <Button
                                                className="btn-refresh-listing d-flex btn btn-bordered-white2 btn-refresh-metadata justify-content-center btn btn-primary"
                                                onClick={refreshActivities}
                                            >
                                                <FontAwesomeIcon className="mr-2" icon={faSyncAlt} />
                                                <span className="d-block">Refresh Activities</span>
                                            </Button>
                                        </div>
                                    </div>
                                    {/* Activity Content */}
                                    <div className="activity-content">
                                        {/* Single Widget */}
                                        <div className="single-widget mb-0">
                                            {/* Search Widget */}
                                            <div className="widget-content search-widget">
                                                <form onSubmit={(e) => search(e)}>
                                                    <input type="text" placeholder="Search" value={searchVal} onChange={(e) => { setPage(1); setSearchVal(e.target.value) }} />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row items">
                            <div className="col-12">
                                {/* Netstorm Tab */}
                                <ul className="netstorm-tab nav nav-tabs" id="nav-tab">
                                    <li onClick={() => { setType(null); setSearchVal(''); }}>
                                        <Link to="#" className={!type ? `active` : ''} data-toggle="pill">
                                            <h5 className="m-0">{"All"}</h5>
                                        </Link>
                                    </li>
                                    <li onClick={() => { setType(1); setSearchVal(''); }}>
                                        <Link to="#" className={type === 1 ? `active` : ''} data-toggle="pill">
                                            <h5 className="m-0">{"Created"}</h5>
                                        </Link>
                                    </li>
                                    <li onClick={() => { setType(2); setSearchVal(''); }}>
                                        <Link to="#" className={type === 2 ? `active` : ''} data-toggle="pill">
                                            <h5 className="m-0">{"Offers"}</h5>
                                        </Link>
                                    </li>
                                    <li onClick={() => { setType(3); setSearchVal(''); }}>
                                        <Link to="#" className={type === 3 ? `active` : ''} data-toggle="pill">
                                            <h5 className="m-0">{"Bids"}</h5>
                                        </Link>
                                    </li>
                                    <li onClick={() => { setType('t'); setSearchVal(''); }}>
                                        <Link to="#" className={type === 't' ? `active` : ''} data-toggle="pill">
                                            <h5 className="m-0">{"Transfers"}</h5>
                                        </Link>
                                    </li>
                                </ul>
                                {/* Tab Content */}
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-home">
                                        <ul className="list-unstyled">
                                            {/* Single Tab List */}
                                            <InfiniteScroll
                                                dataLength={activity.length} //This is important field to render the next data
                                                next={fetchData}
                                                hasMore={moreCheck}
                                                loader={<h4>Loading...</h4>}
                                            >
                                                {
                                                    activity && activity.length > 0 ? activity.map((item, idx) => {
                                                        return (
                                                            <li key={`ato_${idx}`} className="single-tab-list d-flex align-items-center">
                                                                <img className="avatar-lg" src={item.nft && item.nft.nftImageLink ? ENV.webUrl + item.nft.nftImageLink : globalPlaceholderImage} alt="" />
                                                                {/* Activity Content */}
                                                                <div className="activity-content ml-4">
                                                                    <Link to={`/item-details?item=${window.btoa(item.nft._id)}${`&activity=true`}`}>
                                                                        <h5 className="mt-0 mb-2">{item.nft.name}</h5>
                                                                    </Link>
                                                                    <ReactTooltip />
                                                                    {
                                                                        item.type === 1 ?
                                                                            <p className="m-0">Item created {moment(item.createdAt).fromNow()} by <Link to={`/author/${item.user._id}`} className="d-inline d-flex">
                                                                                {item.user.username}
                                                                                {
                                                                                    item.user && item.user.verificationCheck ?
                                                                                        <>
                                                                                            <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                                <FontAwesomeIcon icon={faCheck} />
                                                                                            </span>
                                                                                            <ReactTooltip />
                                                                                        </>
                                                                                        : ''
                                                                                }
                                                                            </Link>{
                                                                                    item.price ?
                                                                                        <span> for Price: <strong><FontAwesomeIcon icon={faEthereum} className="ethereum-icon" data-effect="float" data-tip="Ethereum" /> {item.price} ETH</strong>
                                                                                        </span>
                                                                                        :
                                                                                        ''
                                                                                }
                                                                            </p> :
                                                                            item.type === 2 ? <p className="m-0">Offer made {moment(item.createdAt).fromNow()} by <Link to={`/author/${item.user._id}`} className="d-inline d-flex">
                                                                                {item.user.username}
                                                                                {
                                                                                    item.user && item.user.verificationCheck ?
                                                                                        <>
                                                                                            <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                                <FontAwesomeIcon icon={faCheck} />
                                                                                            </span>
                                                                                            <ReactTooltip />
                                                                                        </>
                                                                                        : ''
                                                                                }
                                                                            </Link>{
                                                                                    item.price ?
                                                                                        <span> for Price: <strong><FontAwesomeIcon icon={faEthereum} className="pink-ethereum-icon" data-effect="float" data-tip="Wrapped Ethereum" /> {item.price} WETH</strong>
                                                                                        </span>
                                                                                        :
                                                                                        ''
                                                                                }
                                                                            </p> :
                                                                                item.type === 3 ? <p className="m-0">Bid placed {moment(item.createdAt).fromNow()} by <Link to={`/author/${item.user._id}`} className="d-inline d-flex">{item.user.username}{
                                                                                    item.user && item.user.verificationCheck ?
                                                                                        <>
                                                                                            <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                                <FontAwesomeIcon icon={faCheck} />
                                                                                            </span>
                                                                                            <ReactTooltip />
                                                                                        </>
                                                                                        : ''
                                                                                }</Link>{
                                                                                        item.price ?
                                                                                            <span> for Price: <strong><FontAwesomeIcon icon={faEthereum} className="pink-ethereum-icon" data-effect="float" data-tip="Wrapped Ethereum" /> {item.price} WETH</strong>
                                                                                            </span>
                                                                                            :
                                                                                            ''
                                                                                    }
                                                                                </p> :
                                                                                    item.type === 4 ? <p className="m-0">Item transferred {moment(item.createdAt).fromNow()} from <Link to={`/author/${item.user._id}`} className="d-inline-block align-top d-flex">{item.user.username}{
                                                                                        item.user && item.user.verificationCheck ?
                                                                                            <>
                                                                                                <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                                                </span>
                                                                                                <ReactTooltip />
                                                                                            </>
                                                                                            : ''
                                                                                    }</Link> to <Link className="d-inline-block align-top d-flex" to={item.toUserId ? `/author/${item.toUserId}` : '#'}>{item.toUserName ? item.toUserName : '----'}{item.toUserVerificationCheck ?
                                                                                        <>
                                                                                            <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                                <FontAwesomeIcon icon={faCheck} />
                                                                                            </span>
                                                                                            <ReactTooltip />
                                                                                        </>
                                                                                        : ''
                                                                                    }</Link>{
                                                                                            item.price ?
                                                                                                <span> for Sale Price: <strong>{
                                                                                                    item.currency === 'ETH' ?
                                                                                                        <>
                                                                                                            <FontAwesomeIcon icon={faEthereum} className="ethereum-icon" data-effect="float" data-tip="Ethereum" /> {item.price} ETH
                                                                                                        </> :
                                                                                                        item.currency === 'WETH' ?
                                                                                                            <>
                                                                                                                <FontAwesomeIcon icon={faEthereum} className="pink-ethereum-icon" data-effect="float" data-tip="Wrapped Ethereum" /> {item.price} WETH
                                                                                                            </> :
                                                                                                            item.currency === 'USD' ?
                                                                                                                <>
                                                                                                                    <FontAwesomeIcon icon={faDollarSign} className="dollar-icon" data-effect="float" data-tip="US Dollar" /> {item.price} US$
                                                                                                                </> : ''
                                                                                                }
                                                                                                </strong>
                                                                                                </span>
                                                                                                :
                                                                                                ''
                                                                                        }
                                                                                    </p>
                                                                                        :
                                                                                        item.type === 5 ? <p className="m-0">Item transferred {moment(item.createdAt).fromNow()} from <Link to={`/author/${item.user._id}`} className="d-inline-block align-top d-flex">{item.user.username}{
                                                                                            item.user && item.user.verificationCheck ?
                                                                                                <>
                                                                                                    <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                                        <FontAwesomeIcon icon={faCheck} />
                                                                                                    </span>
                                                                                                    <ReactTooltip />
                                                                                                </>
                                                                                                : ''
                                                                                        }</Link> to <Link className="d-inline-block align-top" to={item.toUserId ? `/author/${item.toUserId}` : '#'}>{item.toUserName ? item.toUserName : '----'}</Link>{
                                                                                                item.price ?
                                                                                                    <span> for Sale Price: <strong>{
                                                                                                        item.currency === 'ETH' ?
                                                                                                            <>
                                                                                                                <FontAwesomeIcon icon={faEthereum} className="ethereum-icon" data-effect="float" data-tip="Ethereum" /> {item.price} ETH
                                                                                                            </> :
                                                                                                            item.currency === 'WETH' ?
                                                                                                                <>
                                                                                                                    <FontAwesomeIcon icon={faEthereum} className="pink-ethereum-icon" data-effect="float" data-tip="Wrapped Ethereum" /> {item.price} WETH
                                                                                                                </> :
                                                                                                                item.currency === 'USD' ?
                                                                                                                    <>
                                                                                                                        <FontAwesomeIcon icon={faDollarSign} className="dollar-icon" data-effect="float" data-tip="US Dollar" /> {item.price} US$
                                                                                                                    </> : ''
                                                                                                    }
                                                                                                    </strong>
                                                                                                    </span>
                                                                                                    :
                                                                                                    ''
                                                                                            }
                                                                                        </p>
                                                                                            :
                                                                                            item.type === 6 ? <p className="m-0">1 copy transferred {moment(item.createdAt).fromNow()} from <Link to={`/author/${item.user._id}`} className="d-inline-block align-top d-flex">{item.user.username}{
                                                                                                item.user && item.user.verificationCheck ?
                                                                                                    <>
                                                                                                        <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                                            <FontAwesomeIcon icon={faCheck} />
                                                                                                        </span>
                                                                                                        <ReactTooltip />
                                                                                                    </>
                                                                                                    : ''
                                                                                            }</Link> to <Link className="d-inline-block align-top" to={item.toUserId ? `/author/${item.toUserId}` : '#'}>{item.toUserName ? item.toUserName : '----'}</Link>{
                                                                                                    item.price ?
                                                                                                        <span> for Sale Price: <strong>{
                                                                                                            item.currency === 'ETH' ?
                                                                                                                <>
                                                                                                                    <FontAwesomeIcon icon={faEthereum} className="ethereum-icon" data-effect="float" data-tip="Ethereum" /> {item.price} ETH
                                                                                                                </> :
                                                                                                                item.currency === 'WETH' ?
                                                                                                                    <>
                                                                                                                        <FontAwesomeIcon icon={faEthereum} className="pink-ethereum-icon" data-effect="float" data-tip="Wrapped Ethereum" /> {item.price} WETH
                                                                                                                    </> :
                                                                                                                    item.currency === 'USD' ?
                                                                                                                        <>
                                                                                                                            <FontAwesomeIcon icon={faDollarSign} className="dollar-icon" data-effect="float" data-tip="US Dollar" /> {item.price} US$
                                                                                                                        </> : ''
                                                                                                        }
                                                                                                        </strong>
                                                                                                        </span>
                                                                                                        :
                                                                                                        ''
                                                                                                }
                                                                                            </p>
                                                                                                : ''
                                                                    }
                                                                </div>
                                                            </li>
                                                        );
                                                    })
                                                        :
                                                        <li>{'No Activity To Show.'}</li>
                                                }
                                            </InfiniteScroll>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
    );
}

const mapStateToProps = state => ({
    activities: state.activities
});

export default connect(mapStateToProps, { beforeActivity, getActivities, getLeaderboard, beforeLeaderboard, refreshActivities })(Activity);