import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './components/scroll-to-top/scroll-to-top'
import { Provider } from 'react-redux';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import store from './store';
import { toast, ToastContainer } from 'react-toastify';
import getLibrary from './utils/getLibrary';
import 'react-toastify/dist/ReactToastify.css'
const NetworkContextName = "NETWORK";
const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);
toast.configure({
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  newestOnTop: true,
  autoClose: 3000
});

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ProviderNetwork getLibrary={getLibrary}>
      <Provider store={store}>
        <ToastContainer />
        <BrowserRouter>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    </Web3ProviderNetwork>
  </Web3ReactProvider>,
  document.getElementById("root")
);
serviceWorker.unregister();
