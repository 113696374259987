import { useEffect, useState } from 'react';
import { getInvestors, beforeUser } from '../../user/user.action'
import { connect } from 'react-redux';
import ArtistSlider from './ArtistSlider';

const Investor = (props) => {

    const [investors, setInvestors] = useState(null)
    const [btnCheck, setBtnCheck] = useState(true)
    const [page, setPage] = useState(2)
    const [loader, setLoader] = useState(true)
    let type = 1

    useEffect(() => {
        window.scroll(0, 0)
        document.querySelectorAll('.nav-link').forEach((item) => {
            item.classList.remove('activeNav')
        })
        document.querySelector(`#link-navv-2`).classList.add('activeNav')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.user.investorsAuth) {
            if (investors) {
                if (props.user.getInvestors.investors.length) {
                    setInvestors([...investors, ...props.user.getInvestors.investors])
                    setPage(page + 1);
                }
                else {
                    setBtnCheck(false)
                }
            }
            else {
                setInvestors(props.user.getInvestors.investors)
            }
            props.beforeUser()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.investorsAuth])

    useEffect(() => {
        if (investors) {
            setLoader(false)
        }
    }, [investors])

    useEffect(() => {
        if (!loader) {
            activator(type - 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loader])

    useEffect(() => {
        if (!btnCheck) {
            setLoader(false)
        }
    }, [btnCheck])

    const activator = (index) => {
        let list = document.querySelectorAll('.inv-type')
        if (list.length) {
            list.forEach((item) => {
                item.classList.remove('active')
            })
            list[index].classList.add('active')
        }
    }

    return (
        <div className="investor-wrapper">
            <ArtistSlider />
        </div>
    );

}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, { getInvestors, beforeUser })(Investor);