import { ENV } from '../../../config/config'
const Dalua = () => {

    return (
        <div className="dalua">
            <div className="">
                <div className="dalua-main-section">

                    <div className="dalua-image">
                        <img className="img-fluid" src="img/community-banner.jpeg" alt="img" />
                    </div>
                    <div className="dalua-button">
                        <button type="button" className="btn banner-btn btn-primary" onClick={() => ENV.scrollToQuery()}>Join Our Community</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dalua;