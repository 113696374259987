import React from "react";
import { NavLink } from "react-router-dom";
import marketPlaceImage from '../../../assets/images/market-place-image.png';
import artistsImage from '../../../assets/images/artist-image.png';
import communityImage from '../../../assets/images/community-image-dao.png';


const Block = () => {
	return (
		<div className="blocks">
			<div className="container">
				<div className="main-block text-center">
					<div className="row main-block-row">
						<div className="col-md-4 col-sm-6">
							<div className="block-content">
								<NavLink to="/investor">
									<div className="single-block about-block">
										<div className="image">
											<img className="img-fluid" src={marketPlaceImage} alt="" />
										</div>
										<div className="block-below">
											<h2>Store of value</h2>	
										</div>
									</div>
								</NavLink>
							</div>
						</div>
						<div className="col-md-4 col-sm-6">
							<div className="block-content">
								<NavLink to="/explore-all">
									<div className="single-block about-block1">
										<div className="image">
											<img className="img-fluid" src={artistsImage} alt="" />
										</div>
										<div className="block-below">
											<h2>Aquarium Art Culture</h2>	
										</div>
									</div>
								</NavLink>
							</div>
						</div>
						<div className="col-md-4 col-sm-6">
							<div className="block-content">
								<NavLink to="/community">
									<div className="single-block about-block2">
										<div className="image">
											<img className="img-fluid" src={communityImage} alt="" />
										</div>
										<div className="block-below">
											<h2>1MM Community Aquarium</h2>	
										</div>
									</div>
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Block;
