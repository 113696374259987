import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { beforeNFT, getNFTs } from '../nfts/nfts.action';
import { ENV } from '../../config/config';
import FullPageLoader from '../full-page-loader/full-page-loader'
import { Link, useHistory } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const initData = {
    pre_heading: "Explore",
    heading: "Official Aqua Culture NFTS",
    content: "Connect to your favourite artists and brands directly.",
    btn_1: "Load More"
}

function Gallery(props) {
    const key = ENV.getUserKeys("_id");
    const history = useHistory();
    const [nftPagination, setNFTPagination] = useState(null);
    const [nfts, setNFTs] = useState([]); // NFTs for explore section
    const [loader, setLoader] = useState(true);
    const [loadMoreBtn, setLoadMoreBtn] = useState(false);
    const [isMoreClicked, setLoadMore] = useState(false); // flag to check if load more button clicked
    const [nftFilter, setNftFitler] = useState({});
    const [Filter, setFilter] = useState(false);  // flag for responsive view only

    useEffect(() => {
        if (key._id) {
            window.scroll(0, 0)
            document.querySelectorAll('.nav-link').forEach((item) => {
                item.classList.remove('activeNav')
            })
            document.querySelector(`#link-navv-1`).classList.add('activeNav')

            localStorage.removeItem('activeBtn')

            // get NFTs for explore section
            getNFTs()
        } else {
            history.push("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // set NFTs for explore section
    useEffect(() => {
        if (props.nft.nftsAuth) {
            const { nftsData } = props.nft
            if (nftsData) {
                setNFTs(isMoreClicked ? nfts.concat(nftsData.nfts) : nftsData.nfts)
                setNFTPagination(nftsData.pagination)
                if (nftsData.nfts.length) {
                    setLoadMoreBtn(true)
                }
                if (nftsData.pagination.page === nftsData.pagination.pages) {
                    setLoadMoreBtn(false)
                }
                props.beforeNFT()
                setLoader(false)
                setLoadMore(false)
                if (props.setLoader)
                    props.setLoader(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nft.nftsAuth])

    useEffect(() => {
        let docs = document.querySelector('#total-result-sidebar')
        if (docs) {
            if (nfts.length <= 1)
                docs.innerHTML = nfts.length + ' result found'
            else
                docs.innerHTML = nfts.length + ' results found'
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nfts])

    useEffect(() => {
        if (!loader) {
            if (localStorage.getItem('activeBtn')) {
                document.querySelectorAll(".tabBtn").forEach((item) => {
                    item.classList.remove("active");
                });
                document.querySelector(localStorage.getItem('activeBtn')).classList.add('active')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loader])

    const loadMore = () => {
        const { page } = nftPagination
        setLoader(true)
        setLoadMore(true)
        // get more NFTs for explore section
        getNFTs(page + 1, 40, nftFilter)
    }

    const getNFTs = (page = 1, limit = 40, filter = null) => {
        let nftQS = { page, limit, ownerId: ENV.getUserKeys("_id")?._id, explore: true, bothNfts: true }

        if (filter)
            nftQS = { ...nftQS, ...filter }

        if (props.collectionId)
            nftQS.collectionId = props.collectionId

        if (props.authorId)
            nftQS.authorId = props.authorId

        const qs = ENV.objectToQueryString(nftQS)
        props.getNFTs(qs)
    }

    // apply search & filters
    useEffect(() => {
        if (props.filter)
            applyFilters(props.filter)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filter])

    const applyFilters = (filter) => {
        if (filter) {
            setLoader(true)
            setNftFitler(filter)
            getNFTs(1, 40, filter)
        }
    }

    return (
        <div className='p-sm-5'>
            <section className="explore-area" style={{ paddingTop: `50px` }}>
                {loader && <FullPageLoader />}
                <div className="container-fluid p-0">
                    <div className={` ${Filter ? 'active' : ''} d-flex collection-wrapper`}>
                        <button className="side-toggle-btn" onClick={() => setFilter(!Filter)}><i className="fas fa-sort-amount-down-alt" /></button>
                        <div className="nfts-collection-wrapper p-sm-5 w-100">
                            <Row>
                                <Col md="12" className='text-center'>
                                    <h3 className="my-4">My NFT Gallery</h3>
                                </Col>
                            </Row>
                            <div className="row items explore-items">
                                {nfts && nfts.length > 0 ?
                                    nfts.map((item, idx) => {
                                        if (item.assetId) {
                                            if (item.purchaseCount < item.maxAllowed) {
                                                return (
                                                    <div key={`edth_${idx}`} className={`col-12 col-lg-4 col-xl-3 item explore-item`} data-groups={item.group ? item.group : '["art","sports"]'}>
                                                        <div className="card">
                                                            <div className="image-over">
                                                                {
                                                                    item.maxAllowed > 1 ?
                                                                        <span className='purchase-count-nft'>Sold {item.purchaseCount} of {item.maxAllowed}</span> : ''
                                                                }
                                                                <Link to={`/item-details?item=${window.btoa(item._id)}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                    <img className="card-img-top" src={ENV.webUrl + item.nftImageLink} alt="img" />
                                                                </Link>
                                                            </div>
                                                            <div className="card-caption col-12">
                                                                <div className="card-body">
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <Link to={`/item-details?item=${window.btoa(item._id)}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                            <h5 className="mb-0">{item.name}{item.nftNumber ? ' #' + String(item.nftNumber).padStart(3, '0') : ''}</h5>
                                                                        </Link>
                                                                        <span><b>{item.currentPrice} {item.currency}</b></span>
                                                                    </div>

                                                                    <div className="cont2 w-100 seller d-flex align-items-center justify-content-between my-3">
                                                                        <span className="text-break mr-2 d-inline-block align-top d-flex">{item.owner.username}{
                                                                            item.owner && item.owner.verificationCheck ?
                                                                                <>
                                                                                    <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                        <FontAwesomeIcon icon={faCheck} />
                                                                                    </span>
                                                                                    <ReactTooltip />
                                                                                </>
                                                                                : ''
                                                                        }</span>
                                                                        <Link className="btn btn-primary" to={`/item-details?item=${window.btoa(item._id)}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                            <span>View Details</span>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                        else {
                                            return (
                                                <div key={`edth_${idx}`} className={`col-12 col-lg-4 col-xl-3 item explore-item`} data-groups={item.group ? item.group : '["art","sports"]'}>
                                                    <div className="card">
                                                        <div className="image-over">
                                                            <Link to={`/item-details?item=${window.btoa(item._id)}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                <img className="card-img-top" src={ENV.webUrl + item.nftImageLink} alt="img" />
                                                            </Link>
                                                        </div>
                                                        <div className="card-caption col-12">
                                                            <div className="card-body">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <Link to={`/item-details?item=${window.btoa(item._id)}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                        <h5 className="mb-0">{item.name}{item.nftNumber ? ' #' + String(item.nftNumber).padStart(3, '0') : ''}</h5>
                                                                    </Link>
                                                                    <span><b>{item.currentPrice} {item.currency}</b></span>
                                                                </div>

                                                                <div className="cont2 w-100 seller d-flex align-items-center justify-content-between my-3">
                                                                    <span className="text-break mr-2 d-inline-block align-top d-flex">{item.owner.username}{
                                                                        item.owner && item.owner.verificationCheck ?
                                                                            <>
                                                                                <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                                </span>
                                                                                <ReactTooltip />
                                                                            </>
                                                                            : ''
                                                                    }</span>
                                                                    <Link className="btn btn-primary" to={`/item-details?item=${window.btoa(item._id)}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}>
                                                                        <span>View Details</span>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return false
                                    })
                                    : <div className="no-data border"><p className="text-center">No Items Found In Gallery</p></div>
                                }
                            </div>
                        </div>
                    </div>
                    {loadMoreBtn ?
                        <div className="row">
                            <div className="col-12 text-center mb-3">
                                <span id="load-btn" className="btn banner-btn mt-4 " onClick={() => loadMore()} href>{initData.btn_1}</span>
                            </div>
                        </div>
                        : ''
                    }
                </div>
            </section>
        </div>
    )
}

const mapStateToProps = state => ({
    nft: state.nft
});

export default connect(mapStateToProps, { beforeNFT, getNFTs })(Gallery);