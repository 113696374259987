import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Explore from "../explore/explore-all";
import { getUserById, beforeUser } from "./../user/user.action";
import { ENV } from "./../../config/config";
import FullPageLoader from "../../components/full-page-loader/full-page-loader";
import { toast } from 'react-toastify';
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const bannerImg = "/img/placeholder.png";
const Author = (props) => {
  const params = useParams();
  const [authorProfile, setAuthorProfile] = useState({});
  const [authorLoader, setAuthorLoader] = useState(true); // collections loader
  const [nftsLoader, setNftsLoader] = useState(true); // NFTs loader
  const [Filter, setFilter] = useState(false);
  let nftFilter = null;

  useEffect(() => {
    window.scroll(0, 0);
    props.getUserById(params.authorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.user.individualUserAuth) {
      setAuthorProfile(props.user.individualUser);
      props.beforeUser();
      setAuthorLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.individualUserAuth]);

  const getNftsLoader = (loader) => {
    setNftsLoader(loader);
  };

  const copy = () => {
    var copyText = document.getElementById("author-add-field");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    toast.success("Address Copied To Clipboard.")
  }

  const addHttps = (link) => {
		if (!link.includes('http')) {
			return 'https://' + link
		}
		return link
	}

  return (
    <>
      <Breadcrumb banner={authorProfile.bannerImageLink ? ENV.webUrl + authorProfile.bannerImageLink : bannerImg} />
      <section
        className="author-area explore-area popular-collections-area"
        style={{ padding: "0px" }}
      >
        {(authorLoader || nftsLoader) && <FullPageLoader />}
        {
          authorProfile &&
          <div className="">
            <div className="d-flex author-detail-container">
              <div className="author-preview author-card">
                <div className="seller">
                  <div className="seller-thumb avatar-lg">
                    <img
                      className="rounded-circle"
                      src={authorProfile.profileImageLink ? ENV.webUrl + authorProfile.profileImageLink : ENV.globalPlaceholderImage}
                      alt="User Profile"
                    />
                  </div>
                </div>
              </div>
              <div className="card-caption p-0 text-center">
                <div className="card-body">
                  <h5 className="mb-3 d-flex justify-content-center">
                    {authorProfile.username}
                    {
                      authorProfile.verificationCheck ?
                        <>
                          <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <ReactTooltip />
                        </>
                        : ''
                    }
                  </h5>
                  <p className="my-3">{authorProfile.description}</p>
                  <div className="input-group">
                    <input type="text" className="form-control" id="author-add-field" placeholder="0x000000000000000000" readOnly value={authorProfile.address}
                      style={{
                        borderRadius: "100px",
                        backgroundColor: "transparent",
                        border: "2px solid",
                        height: "40px",
                        padding: "1.5rem",
                        boxShadow: "none",
                        outline: "none",

                      }}
                    />
                    <div className="input-group-append"
                      style={{
                        position: "absolute",
                        height: "100%",
                        top: "0",
                        right: "0",
                        borderRadius: "100px",
                        overflow: "hidden",
                      }}
                      onClick={copy}
                    >
                      <button style={{
                        marginRight: "5px",
                        padding: "12px 16px",
                        backgroundColor: "transparent",
                        color: "#000",
                        border: "0",
                        outline: "none",
                      }}><i className="icon-docs" /></button>
                    </div>
                  </div>
                  <div className="social-icons d-flex justify-content-center my-3">
                    {
                      authorProfile.facebookLink && <a className="facebook" href={addHttps(authorProfile.facebookLink)} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                    }
                    {
                      authorProfile.twitterLink && <a className="twitter" href={addHttps(authorProfile.twitterLink)} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Twitter">
                        <i className="fab fa-twitter" />
                      </a>
                    }
                    {
                      authorProfile.youTubeLink && <a className="youtube" href={addHttps(authorProfile.youTubeLink)} target="_blank" rel="noreferrer" data-toggle="tooltip" title="YouTube">
                        <i className="fab fa-youtube" />
                      </a>
                    }
                    {
                      authorProfile.tiktokLink && <a className="tiktok" href={addHttps(authorProfile.tiktokLink)} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Tiktok">
                        <i className="fab fa-tiktok" />
                      </a>
                    }
                    {
                      authorProfile.instagramLink && <a className="instagram" href={addHttps(authorProfile.instagramLink)} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Instagram">
                        <i className="fab fa-instagram" />
                      </a>
                    }
                  </div>
                  {
                    props.followText &&
                    <a className="btn btn-bordered-white btn-smaller" href>{props.followText}</a>
                  }
                </div>
              </div>
              <div className={`d-flex collection-wrapper ${Filter ? 'active' : ''}`}>
                <button className="side-toggle-btn" onClick={() => setFilter(!Filter)}><i className="fas fa-sort-amount-down-alt" /></button>
                <div className="nfts-collection-wrapper w-100">
                  {
                    authorProfile && authorProfile._id &&
                    <Explore authorCheck={true} ownerId={authorProfile._id} setLoader={getNftsLoader} filter={nftFilter} />
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.error,
  user: state.user,
});

export default connect(mapStateToProps, { getUserById, beforeUser })(Author);
