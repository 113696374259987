import { faAngleDown, faAngleUp, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { useEffect, useState } from 'react';
import { Table } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Images } from "../../assets/asset";
import { ENV } from "../../config/config";
import FullPageLoader from '../full-page-loader/full-page-loader';
import { beforeLeaderboard, getLeaderboard } from './activity.action';
import ReactTooltip from "react-tooltip";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
const { globalPlaceholderImage } = ENV

const LeaderBoard = (props) => {

    const [loader, setLoader] = useState(true)
    const [leadersboard, setLeadersboard] = useState([])
    const [pagination, setPagination] = useState(null)
    const [activePeriod, setActivePeriod] = useState('0')
    const [activeOrder, setActiveOrder] = useState('0')
    const [activeSort, setActiveSort] = useState('0')

    useEffect(() => {
        window.scroll(0, 0)
        localStorage.removeItem('leaderboardSel')
        localStorage.removeItem('activityActiveBtn')
        props.getLeaderboard()
        document.querySelectorAll('.nav-link').forEach((item) => {
            item.classList.remove('activeNav')
        })
        document.querySelector(`#link-navv-6`).classList.add('activeNav')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.activities.leadersboardAuth) {
            const { leadersboard, pagination } = props.activities.leadersboard
            setLeadersboard(leadersboard)
            setPagination(pagination)
            setLoader(false)
            props.beforeLeaderboard()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.activities.leadersboardAuth])

    useEffect(() => {
        if (leadersboard) {
            if (localStorage.getItem('leaderboardSel')) {
                document.querySelector('#leaderbrdSel').value = localStorage.getItem('leaderboardSel')
            }
        }
    }, [leadersboard])

    useEffect(() => {
        if (!loader) {
            if (localStorage.getItem('activityActiveBtn')) {
                document.querySelectorAll('.up-arrow').forEach((item) => {
                    item.classList.remove('active')
                })
                document.querySelector(`#${localStorage.getItem('activityActiveBtn')}`).classList.add('active')
            }
        }
    }, [loader])

    const onPageChange = async (page) => {
        setLoader(true)
        const qs = ENV.objectToQueryString({ page, period: activePeriod, sortBy: activeSort, order: activeOrder })
        props.getLeaderboard(qs)
    }

    const leaderBoardSel = (e, sortBy = 0, order = 0) => {
        setLoader(true)
        let period = e?.target?.value || activePeriod;
        const qs = ENV.objectToQueryString({ period, sortBy, order })
        props.getLeaderboard(qs)
        setActivePeriod(period)
        setActiveOrder(order)
        setActiveSort(sortBy)
    }

    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    return (
        loader ?
            <FullPageLoader />
            :
            <section className="activity-area load-more">
                <div className="container custom-container">
                    <div className="row">
                        <div className='col-12'>
                            <div className="intro mb-3">
                                <div className="intro-content">
                                    <h3 className="mt-3 mb-0">{"LeaderBoard"}</h3>
                                </div>
                            </div>
                            <div className="filters-action mb-3">
                                <div className="sort-box">
                                    <select className="sorting" id='leaderbrdSel' defaultValue={activePeriod} value={activePeriod} onChange={(e) => { leaderBoardSel(e) }}>
                                        <option value="0">ALL TIME LEADERBOARD</option>
                                        <option value="1">MONTHLY LEADERBOARD</option>
                                        <option value="2">3 MONTHS LEADERBOARD</option>
                                        <option value="3">6 MONTHS LEADERBOARD</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-none d-md-block">
                                <div className="table-responsive p-3">
                                    <Table hover id="bd-zero" className="activity-logs-table">
                                        <thead>
                                            <tr>
                                                <th className="name-col">Wallet/User Name</th>
                                                <th className="text-center sort-col">
                                                    <div className="d-inline-block align-top">
                                                        <div className="d-flex align-items-center">
                                                            <span className="etherium-icon-holder mr-2">
                                                                <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                            </span>
                                                            <span className="mr-2">Bought</span>
                                                            <span className="d-flex flex-column up-down-arrows">
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 1 && activeOrder === 1 ? "active" : ""}`} id='up-bought' onClick={() => leaderBoardSel(activePeriod, 1, 1)}>
                                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                                </span>
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 1 && activeOrder === 0 ? "active" : ""}`} id='down-bought' onClick={() => leaderBoardSel(activePeriod, 1, 0)}>
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-center sort-col">
                                                    <div className="d-inline-block align-top">
                                                        <div className="d-flex align-items-center">
                                                            <span className="etherium-icon-holder mr-2">
                                                                <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                            </span>
                                                            <span className="mr-2">Sold</span>
                                                            <span className="d-flex flex-column up-down-arrows">
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 2 && activeOrder === 1 ? "active" : ""}`} id='up-bought' onClick={() => leaderBoardSel(activePeriod, 2, 1)}>
                                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                                </span>
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 2 && activeOrder === 0 ? "active" : ""}`} id='down-bought' onClick={() => leaderBoardSel(activePeriod, 2, 0)}>
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-center sort-col">
                                                    <div className="d-inline-block align-top">
                                                        <div className="d-flex align-items-center">
                                                            <span className="etherium-icon-holder mr-2">
                                                                <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                            </span>
                                                            <span className="mr-2">Volume Traded</span>
                                                            <span className="d-flex flex-column up-down-arrows">
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 3 && activeOrder === 1 ? "active" : ""}`} id='up-bought' onClick={() => leaderBoardSel(activePeriod, 3, 1)}>
                                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                                </span>
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 3 && activeOrder === 0 ? "active" : ""}`} id='down-bought' onClick={() => leaderBoardSel(activePeriod, 3, 0)}>
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-center sort-col">
                                                    <div className="d-inline-block align-top">
                                                        <div className="d-flex align-items-center">
                                                            <span className="mr-2">NFTS Owned</span>
                                                            <span className="d-flex flex-column up-down-arrows">
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 4 && activeOrder === 1 ? "active" : ""}`} id='up-bought' onClick={() => leaderBoardSel(activePeriod, 4, 1)}>
                                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                                </span>
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 4 && activeOrder === 0 ? "active" : ""}`} id='down-bought' onClick={() => leaderBoardSel(activePeriod, 4, 0)}>
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="text-center sort-col">
                                                    <div className="d-inline-block align-top">
                                                        <div className="d-flex align-items-center">
                                                            <span className="mr-2">NFTS Created</span>
                                                            <span className="d-flex flex-column up-down-arrows">
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 5 && activeOrder === 1 ? "active" : ""}`} id='up-bought' onClick={() => leaderBoardSel(activePeriod, 5, 1)}>
                                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                                </span>
                                                                <span className={`up-arrow cursor-pointer ${activeSort === 5 && activeOrder === 0 ? "active" : ""}`} id='down-bought' onClick={() => leaderBoardSel(activePeriod, 5, 0)}>
                                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                leadersboard && leadersboard.length ?
                                                    leadersboard.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="name-col">
                                                                    <span className="d-flex align-items-center">
                                                                        <img src={item.profileImageLink ? ENV.webUrl + item.profileImageLink : globalPlaceholderImage} className='avatar-sm rounded-circle mr-2' alt='img' />
                                                                        <Link to={`/author/${item._id}`} className="address-truncate d-flex" data-effect="float">
                                                                            {item.username ? item.username : item.address}
                                                                            {
                                                                                item.verificationCheck ?
                                                                                    <>
                                                                                        <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                            <FontAwesomeIcon icon={faCheck} />
                                                                                        </span>
                                                                                        <ReactTooltip />
                                                                                    </>
                                                                                    : ''
                                                                            }
                                                                        </Link>
                                                                    </span>
                                                                </td>
                                                                <td className="text-center sort-col">{item.bought !== undefined ? item.bought.toFixed(8) : 0.00}</td>
                                                                <td className="text-center sort-col">{item.sold !== undefined ? item.sold.toFixed(8) : 0.00}</td>
                                                                <td className="text-center sort-col">{item.tradeVolume !== undefined ? item.tradeVolume.toFixed(8) : 0.00}</td>
                                                                <td className="text-center sort-col">{item.ownedNFTs ? parseInt(item.ownedNFTs) : 0}</td>
                                                                <td className="text-center sort-col">{item.createdNFTs ? parseInt(item.createdNFTs) : 0}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <tr>No Item To Show</tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="mobile-leader-board px-md-3 d-block d-md-none">
                                <div className="filter-block mb-4 position-relative overflow-hidden">
                                    <div className="d-flex filter-header align-items-center cursor-pointer" onClick={toggleClass}>
                                        <FontAwesomeIcon className="mr-2" icon={faFilter} />
                                        <span>Filters</span>
                                    </div>
                                    <ul className={isActive ? "list-unstyled leaderboard-filters active" : "list-unstyled leaderboard-filters"}>
                                        <li className="d-flex align-items-center">
                                            <span className="filter-name mr-2">Bought</span>
                                            <span className="d-flex flex-column up-down-arrows">
                                                <span className={`up-arrow cursor-pointer ${activeSort === 1 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 1, 1)}>
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                </span>
                                                <span className={`up-arrow cursor-pointer ${activeSort === 1 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 1, 0)}>
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span>
                                            </span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <span className="filter-name mr-2">Sold</span>
                                            <span className="d-flex flex-column up-down-arrows">
                                                <span className={`up-arrow cursor-pointer ${activeSort === 2 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 2, 1)}>
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                </span>
                                                <span className={`up-arrow cursor-pointer ${activeSort === 2 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 2, 0)}>
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span>
                                            </span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <span className="filter-name mr-2">Volume Traded</span>
                                            <span className="d-flex flex-column up-down-arrows">
                                                <span className={`up-arrow cursor-pointer ${activeSort === 3 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 3, 1)}>
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                </span>
                                                <span className={`up-arrow cursor-pointer ${activeSort === 3 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 3, 0)}>
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span>
                                            </span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <span className="filter-name mr-2">NFTS Owned</span>
                                            <span className="d-flex flex-column up-down-arrows">
                                                <span className={`up-arrow cursor-pointer ${activeSort === 4 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 4, 1)} >
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                </span>
                                                <span className={`up-arrow cursor-pointer ${activeSort === 4 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 4, 0)} >
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span>
                                            </span>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <span className="filter-name mr-2">NFTS Created</span>
                                            <span className="d-flex flex-column up-down-arrows">
                                                <span className={`up-arrow cursor-pointer ${activeSort === 5 && activeOrder === 1 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 5, 1)}>
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                </span>
                                                <span className={`up-arrow cursor-pointer ${activeSort === 5 && activeOrder === 0 ? "active" : ""}`} onClick={() => leaderBoardSel(activePeriod, 5, 0)}>
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                </span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <ul className="activity-items list-unstyled">
                                    {
                                        leadersboard && leadersboard.length ?
                                            leadersboard.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <ul className="list-unstyled activity-item-details">
                                                            <li className="d-flex align-items-center">
                                                                <strong className="mr-2">User:</strong>
                                                                <span className="d-flex align-items-center">
                                                                    <img src={item.profileImageLink ? ENV.webUrl + item.profileImageLink : globalPlaceholderImage} className='avatar-sm rounded-circle mr-2' alt='img' />
                                                                    <Link to={`/author/${item._id}`} className="address-truncate d-flex" data-effect="float">
                                                                        {item.username ? item.username : item.address}
                                                                        {
                                                                            item.verificationCheck ?
                                                                                <>
                                                                                    <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                        <FontAwesomeIcon icon={faCheck} />
                                                                                    </span>
                                                                                    <ReactTooltip />
                                                                                </>
                                                                                : ''
                                                                        }
                                                                    </Link>
                                                                </span>
                                                            </li>
                                                            <li className="d-flex align-items-center">
                                                                <strong className="d-flex align-items-center">
                                                                    <span className="etherium-icon-holder mr-2">
                                                                        <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                                    </span>
                                                                    <span className="mr-2">Bought</span>
                                                                </strong>
                                                                <span>{item.bought ? item.bought.toFixed(5) : 0}</span>
                                                            </li>
                                                            <li className="d-flex align-items-center">
                                                                <strong className="d-flex align-items-center">
                                                                    <span className="etherium-icon-holder mr-2">
                                                                        <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                                    </span>
                                                                    <span className="mr-2">Sold</span>
                                                                </strong>
                                                                <span>{item.sold ? item.sold.toFixed(5) : 0}</span>
                                                            </li>
                                                            <li className="d-flex align-items-center">
                                                                <strong className="d-flex align-items-center">
                                                                    <span className="etherium-icon-holder mr-2">
                                                                        <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                                    </span>
                                                                    <span className="mr-2">Volume Traded</span>
                                                                </strong>
                                                                <span>{item.tradeVolume ? item.tradeVolume.toFixed(5) : 0}</span>
                                                            </li>
                                                            <li className="d-flex align-items-center">
                                                                <strong className="d-flex align-items-center">
                                                                    <span>
                                                                        <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                                    </span>
                                                                    <span className="mr-2">NFTs Owned</span>
                                                                </strong>
                                                                <span>{item.ownedNFTs ? item.ownedNFTs.toFixed(5) : 0}</span>
                                                            </li>
                                                            <li className="d-flex align-items-center">
                                                                <strong className="d-flex align-items-center">
                                                                    <span>
                                                                        <img src={Images.etheriumIcon} className="icon-etherium" alt='img' />
                                                                    </span>
                                                                    <span className="mr-2">NFTs Created</span>
                                                                </strong>
                                                                <span>{item.createdNFTs ? item.createdNFTs.toFixed(5) : 0}</span>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                )
                                            })
                                            : <li>No Item To Show</li>
                                    }
                                </ul>
                            </div>
                            {
                                pagination &&
                                <Pagination
                                    className="pt-3"
                                    defaultCurrent={1}
                                    pageSize // items per page
                                    current={pagination.page} // current active page
                                    total={pagination.pages} // total pages
                                    onChange={onPageChange}
                                    locale={localeInfo}
                                />
                            }
                        </div>
                    </div>
                </div>
            </section>
    );
}

const mapStateToProps = state => ({
    activities: state.activities
});

export default connect(mapStateToProps, { getLeaderboard, beforeLeaderboard })(LeaderBoard);