import randomstring from "randomstring";
import { toast } from 'react-toastify'
import contractAbi from './../utils/abis/token.json';
import { ENV } from './../config/config';
import store from './../store'
import { axiosSyncPost } from './../utils/functions';
import { SET_WALLET_ERROR, REDIRECT_TO_WALLET } from './../redux/types';
let WEB3Utils = require('web3-utils');
const nftContractAddress = ENV.nftContractAddress;

const call = (method, params) => {
    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
        method(...params)
            .call()
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
const getTxCountAndGas = async (connectedAddress, data) => {
    const web3 = await getWeb3();
    const txCount = await web3.eth.getTransactionCount(connectedAddress);
    let gas = await web3.eth.getGasPrice();
    const gasPrice = await web3.eth.estimateGas({
        from: connectedAddress,
        nonce: txCount,
        to: nftContractAddress,
        data,
    });
    // gas = Math.min(gas, 30000000)
    return {gas, gasPrice}
}
const send = (method, params, from, value = 0, passGas=true) => {
    // eslint-disable-next-line no-undef
    return new Promise(async (resolve, reject) => {
        if(passGas) {
            // const myData = await method(...params).encodeABI();
            // const {gas, gasPrice} = await getTxCountAndGas(from, myData)
            // console.log(gas)
            // console.log(WEB3Utils.toHex(gas))
            // console.log(gasPrice)
            // console.log(WEB3Utils.toHex(gasPrice))
            // console.log(30000000)
            method(...params)
            // .send({ from, value, gasPrice: WEB3Utils.toHex(gasPrice)  })
            // .send({ from, value, gas: WEB3Utils.toHex(gas), gasPrice: WEB3Utils.toHex(gasPrice)  })
            // .send({ from, value, gas: 6623310  })
            // .send({ from, value, gasPrice: 100000000000000  })
            .send({ from, value, gasLimit: 600000  })
            // .send({ from, value })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
        }
        else {
            method(...params)
            .send({ from, value })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
        }
    });
};
const methods = {
    call,
    send,
};
export const getWeb3 = async () => {
    if (window.walletPO)
        return window.walletPO;

    store.dispatch(redirectToWallet())
    localStorage.clear()
    return false;
}
// method to check if a user is logged in but wallet is locked
export const isWalletLocked = async () => {
    try {
        if (localStorage.getItem('encuse') && localStorage.getItem('connectedAddress')) {
            const web3 = await getWeb3();
            if (!web3)
                return store.dispatch(setWalletError("Please install Crypto Wallet in order to use all features of Marketplace"));

            const accounts = await web3.eth.getAccounts();
            if (!accounts?.length) {
                store.dispatch(redirectToWallet())
                localStorage.clear()
                return true
            }
        }
    } catch (e) {
        const eMessage = e.message.split('{')[0] || '';
        return false;
    }
}
export const connectMetamask = async () => {
    try {
        const { address } = ENV.getUserKeys('address')
        if (address)
            return address

        else
            store.dispatch(setWalletError("Please install Crypto Wallet in order to use all features of Marketplace"));
    } catch (e) {
        return false
    }
}
export const signRequest = async () => {
    const isLocked = await isWalletLocked()
    if (isLocked)
        return false

    if (!window.walletPO) {
        alert("Please install Crypto Wallet in order to use all features of Marketplace");
        return;
    }
    const web3 = await getWeb3();
    let accounts = await web3.eth.getAccounts();
    let address = accounts[0];
    let signature = await handleSignMessage(address);
    return signature;
}
export const getPercentagesWeb3 = async () => {
    const web3 = await getWeb3();
    // if (!web3) {
    //     toast.error("No web3 instance found");
    //     return false;
    // }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const tokenContract = new web3.eth.Contract(
            contractAbi,
            nftContractAddress,
        );
        const percentage = await methods.call(tokenContract.methods.checkPercentages, [])
        return percentage
    } catch (e) {
        return false;
    }
}
//---updated
export const createAssetPublic = async (_nftData, _id) => {
    const web3 = await getWeb3();
    // if (!web3) {
    //     toast.error("Please connect wallet");
    //     return false;
    // }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        _nftData.creator = connectedAddress;
        const tokenContract = new web3.eth.Contract(contractAbi, nftContractAddress);
        const price = 0;
        const { hash, nonce, encodeKey } = await createHash(connectedAddress, price);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);
        const requestData = {
            nonce,
            encodeKey,
            amount: 0,
            signature,
            maxAllowed: _nftData.copies,
            metadata: _nftData.metaData,
            price: 0
        }
        // struct createAssetPublicData {
        //     string encodeKey;
        //     uint256 nonce;
        //     uint256 amount;
        //     bytes signature;
        //     uint256 maxAllowed;
        //     string metadata;
        //     uint256 price;
        // }
        const txDetails = await methods.send(
            tokenContract.methods.createAssetPublic,
            [requestData],
            connectedAddress,
            0,
            false
        );
        if (!txDetails.status) {
            let payloadData = {
                _id
            }
            axiosSyncPost('nfts/unset', payloadData)
        }
        const txHash = txDetails.transactionHash;
        const assetId = txDetails?.events?.NewAsset?.returnValues?.assetId || 0;
        return { assetId, txHash };
    } catch (e) {
        let payloadData = {
            _id
        }
        axiosSyncPost('nfts/unset', payloadData)
        toast.error('Sorry, unable to create an item/NFT for you')
        return false;
    }
}
//---updated
export const createNFT = async (_nftData, _id) => {
    const web3 = await getWeb3();
    // if (!web3) {
    //     toast.error("No web3 instance found");
    //     return false;
    // }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        _nftData.creator = connectedAddress;
        const tokenContract = new web3.eth.Contract(contractAbi, nftContractAddress);
        const price = 0;
        const { hash, nonce, encodeKey } = await createHash(connectedAddress, price);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);
        const requestData = {
            ..._nftData,
            nonce,
            encodeKey,
            signature,
            amount: 0
        }
        // struct createNftData {
        //     string metaData;
        //     address creator;
        //     string encodeKey;
        //     uint256 nonce;
        //     uint256 amount;
        //     bytes signature;
        // }
        const txDetails = await methods.send(
            tokenContract.methods.createNFT,
            [requestData],
            connectedAddress,
            0,
            false
        );
        if (!txDetails.status) {
            let payloadData = {
                _id
            }
            axiosSyncPost('nfts/unset', payloadData)
        }
        const txHash = txDetails.transactionHash;
        const tokenId = txDetails?.events?.NewNFT?.returnValues?.tokenId || 0;
        return { tokenId, txHash };
    } catch (e) {
        let payloadData = {
            _id
        }
        axiosSyncPost('nfts/unset', payloadData)
        toast.error('Sorry, unable to create an item/NFT for you')
        return false;
    }
}
export const changeSellingStatusWeb3 = async (_nftData) => {
    const web3 = await getWeb3();
    // if (!web3) {
    //     toast.error("No web3 instance found");
    //     return false;
    // }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        _nftData.creator = connectedAddress;
        const weiPrice = WEB3Utils.toWei(`${_nftData.price}`, 'ether');
        const { hash } = await createHash(connectedAddress, weiPrice);
        await handleSignMessageWithHash(hash, connectedAddress);
        return true;
    } catch (e) {
        let eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
//---updated
export const buyAndMintAsset = async (_nftData, assetId, payThrough, ipfs, assetNumber) => {
    const web3 = await getWeb3();
    // if (!web3) {
    //     toast.error("No web3 instance found");
    //     return false;
    // }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        _nftData.creator = connectedAddress;
        const tokenContract = new web3.eth.Contract(contractAbi, nftContractAddress);
        if (_nftData.price)
            _nftData.price = _nftData.price.toFixed(10);
        else
            _nftData.price = 0;

        const weiPrice = payThrough === 1 ? WEB3Utils.toWei(`${_nftData.price}`, 'ether') : WEB3Utils.toWei(`${_nftData.usdAmount}`, 'ether');
        const { hash, nonce, encodeKey } = await createHash(connectedAddress, weiPrice);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);
        const requestData = {
            assetId,
            nonce,
            encodeKey,
            amount: weiPrice,
            signature,
            payThrough,
            metadata: ipfs,
            assetNumber,
            nftId: _nftData.nftId,
            ownerId: _nftData.ownerId,
            buyerId: _nftData.buyerId,
            currency: _nftData.currency,
            selectedNft: _nftData.selectedNft,
            assetNumber: _nftData.assetNumber,
            sellingMethod: _nftData.sellingMethod,
            sellingNftId: _nftData.sellingNftId
        }

        const txDetails = await methods.send(
            tokenContract.methods.buyAndMintAsset,
            [requestData],
            connectedAddress,
            payThrough === 1 ? weiPrice : 0,
            true
        );
        if (!txDetails.status) {
            return false;
        }
        const txHash = txDetails.transactionHash;
        const tokenId = txDetails?.events?.NewNFT?.returnValues?.tokenId || 0;
        return { tokenId, txHash };
    } catch (e) {
        console.log('buyAndMintAsset error: ', e)
        toast.error('Sorry, unable to purchase NFT')
        return false;
    }
}
export const makeOfferWeb3 = async (_nftData) => {
    const web3 = await getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found");
        return "web3Err";
    }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        const cabi = JSON.parse(_nftData.cabi);
        const cAddress = _nftData.cAddress;
        const approvedAmount = await isApproved(nftContractAddress, connectedAddress, cAddress, cabi);
        const weiPrice = WEB3Utils.toWei(`${_nftData.price}`, 'ether');
        if (parseFloat(weiPrice) > parseFloat(approvedAmount)) {
            let gotApproval = await getApproval(nftContractAddress, ENV.amountToApprove, cAddress, cabi)
            if (!gotApproval) {
                return false;
            }
        }
        return true;
    } catch (e) {
        let eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
export const placeBidWeb3 = async (_nftData) => {
    const web3 = await getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found");
        return "web3Err";
    }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        const cabi = JSON.parse(_nftData.cabi);
        const cAddress = _nftData.cAddress;
        const approvedAmount = await isApproved(nftContractAddress, connectedAddress, cAddress, cabi);
        const weiPrice = WEB3Utils.toWei(`${_nftData.price}`, 'ether');
        if (parseFloat(weiPrice) > parseFloat(approvedAmount)) {
            let gotApproval = await getApproval(nftContractAddress, ENV.amountToApprove, cAddress, cabi)
            if (!gotApproval) {
                return false;
            }
        }
        return true;
    } catch (e) {
        let eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
export const cancelOfferBidWeb3 = async (_nftData) => {
    const web3 = await getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found");
        return "web3Err";
    }
    const isLocked = await isWalletLocked()
    if (isLocked)
        return false

    return true;
}
//---updated
export const buyAndMintAssetByAccept = async (_nftData, newOwner, assetId, amount, ipfs) => {
    const web3 = await getWeb3();
    // if (!web3) {
    //     toast.error("No web3 instance found");
    //     return false;
    // }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        _nftData.creator = connectedAddress;
        const tokenContract = new web3.eth.Contract(contractAbi, nftContractAddress);
        const weiPrice = WEB3Utils.toWei(`${amount}`, 'ether');
        const { hash, nonce, encodeKey } = await createHash(connectedAddress, weiPrice);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);
        const requestData = {
            nonce,
            encodeKey,
            amount: weiPrice,
            signature,
            assetId,
            newOwner,
            metadata: ipfs,
            nftNumber: _nftData.nftNumber,
            bidId: _nftData.bidId,
            offerId: _nftData.offerId,
            sellingNftId: _nftData.sellingNftId
        }
        // struct buyAndMintAssetAcceptData {
        //     string encodeKey;
        //     uint256 nonce;
        //     uint256 amount;
        //     bytes signature;
        //     uint256 assetId;
        //     address newOwner;
        //     string metadata;
        // }
        const txDetails = await methods.send(
            tokenContract.methods.buyAndMintAssetByAccept,
            [requestData],
            connectedAddress,
            0,
            true
        );
        if (!txDetails.status) {
            return false;
        }
        const txHash = txDetails.transactionHash;
        const tokenId = txDetails?.events?.NewNFT?.returnValues?.tokenId || 0;
        return { tokenId, txHash };
    } catch (e) {
        toast.error('Sorry, unable to tranfer ownership')
        console.log('------: :------ ', e)
        return false;
    }
}
//---updated
export const buyNow = async (_nftData, payThrough) => {
    const web3 = await getWeb3();
    if (!web3) {
        // toast.error("No web3 instance found");
        return "web3Err";
    }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        const tokenContract = new web3.eth.Contract(contractAbi, nftContractAddress);
        const weiPrice = payThrough === 1 ? WEB3Utils.toWei(`${_nftData.price}`, 'ether') : WEB3Utils.toWei(`${_nftData.usdAmount}`, 'ether');
        const { hash, nonce, encodeKey } = await createHash(connectedAddress, weiPrice);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);
        const requestData = {
            nonce,
            encodeKey,
            amount: weiPrice,
            signature,
            tokenId: _nftData.tokenId,
            payThrough,
            owner: _nftData.owner,
            nftId: _nftData.nftId,
            currency: _nftData.currency,
            sellingMethod: _nftData.sellingMethod,
            sellingNftId: _nftData.sellingNftId
        }
        const txDetails = await methods.send(
            tokenContract.methods.buyNow,
            [requestData],
            connectedAddress,
            payThrough === 1 ? weiPrice : 0,
            true
        );
        if (!txDetails.status) {
            return false;
        }
        const txHash = txDetails.transactionHash;
        return txHash;
    } catch (e) {
        console.log('buyNow Error: ', e)
        let eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
//---updated
export const transferByAccept = async (_nftData, newOwner, payThrough, amount) => {
    const web3 = await getWeb3();
    // if (!web3) {
    //     toast.error("No web3 instance found");
    //     return false;
    // }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        _nftData.creator = connectedAddress;
        const tokenContract = new web3.eth.Contract(contractAbi, nftContractAddress);
        const weiPrice = WEB3Utils.toWei(`${amount}`, 'ether');
        const { hash, nonce, encodeKey } = await createHash(connectedAddress, weiPrice);
        const signature = await handleSignMessageWithHash(hash, connectedAddress);
        const requestData = {
            nonce,
            encodeKey,
            amount: weiPrice,
            signature,
            tokenId: _nftData.tokenId,
            newOwner,
            bidId: _nftData.bidId,
            offerId: _nftData.offerId,
            sellingNftId: _nftData.sellingNftId
        }
        // struct transferByAcceptData {
        //     string encodeKey;
        //     uint256 nonce;
        //     uint256 amount;
        //     bytes signature;
        //     uint256 tokenId;
        //     address newOwner;
        // }

        const txDetails = await methods.send(
            tokenContract.methods.transferByAccept,
            [requestData],
            connectedAddress,
            0,
            true
        );

        if (!txDetails.status) {
            return false;
        }
        const txHash = txDetails.transactionHash;
        const tokenId = txDetails?.events?.OfferAccepted?.returnValues?.tokenId || 0;
        return { tokenId, txHash };
    } catch (e) {
        toast.error('Sorry, unable to transfer NFT')
        return false;
    }
}

export const getApproval = async (guy, amount, contractAddress, contractABI) => {
    const web3 = await getWeb3();
    // if (!web3) {
    //     toast.error("No web3 instance found");
    //     return false;
    // }
    try {
        const connectedAddress = await connectMetamask();
        const tokenContract = new web3.eth.Contract(
            contractABI,
            contractAddress,
        );
        await methods.send(
            tokenContract.methods.approve,
            [guy, amount],
            connectedAddress,
            0,
            false
        );

        return true;
    } catch (e) {
        let eMessage = e.message.split('{')[0] || '';
        toast.error(eMessage);
        return false;
    }
}
export const isApproved = async (guy, connectedAddress, contractAddress, contractABI) => {
    const web3 = await getWeb3();
    // if (!web3) {
    //     toast.error("No web3 instance found");
    //     return false;
    // }
    try {
        const tokenContract = new web3.eth.Contract(
            contractABI,
            contractAddress,
        );
        const myNewData = await methods.call(tokenContract.methods.allowance, [connectedAddress, guy])

        return myNewData;
    } catch (e) {
        return 0;
    }
}
export const checkBalance = async (_nftData) => {
    const web3 = await getWeb3();
    // if (!web3) {
    //     toast.error("No web3 instance found");
    //     return false;
    // }
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        const cabi = JSON.parse(_nftData.cabi);
        const cAddress = _nftData.cAddress;
        const tokenContract = new web3.eth.Contract(
            cabi,
            cAddress,
        );
        let userBalance = await methods.call(tokenContract.methods.balanceOf, [connectedAddress])
        if (userBalance)
            userBalance = WEB3Utils.fromWei(userBalance, 'ether')
        return userBalance;
    } catch (e) {
        return 0;
    }
}
const handleSignMessage = async (address) => {
    if (!address) return

    return new Promise(async (resolve, reject) => {
        const web3 = await getWeb3();
        web3.eth.personal.sign(
            WEB3Utils.fromUtf8(`${ENV.appName} uses this cryptographic signature in place of a password, verifying that you are the owner of this address.`),
            address,
            (err, signature) => {
                if (err) return reject(err);
                return resolve(signature);
            }
        )
    });
};
const handleSignMessageWithHash = async (hash, wallet) => {
    const signature = await window.library.provider.request({
        method: "personal_sign",
        params: [hash, wallet]
    });
    let completedSignature = signature;
    if (signature.slice(-2) === '00') {
        completedSignature = signature.slice(0, -2) + '1b';
    } else if (signature.slice(-2) === '01') {
        completedSignature = signature.slice(0, -2) + '1c';
    }
    return completedSignature;
}
const createHash = async (wallet, _amount) => {
    const encodeKey = getEncodeKey();
    const nonce = Date.now();
    const hash = await WEB3Utils.soliditySha3(wallet, _amount, encodeKey, nonce);
    return { hash, nonce, encodeKey };
}
const getEncodeKey = () => {
    return randomstring.generate({
        length: 20,
        charset: 'alphabetic'
    });
}
const accountsChangedHandler = () => {
    if (window.ethereum) {
        window.ethereum.on('accountsChanged', function (accounts) {
            let connectedWalletName = localStorage.getItem('connectedWalletName')
            localStorage.clear()
            if (connectedWalletName)
                localStorage.setItem('connectedWalletName', connectedWalletName)
            store.dispatch(redirectToWallet())
        })
        window.ethereum.on('chainChanged', function (_chainId) {
            const chaindId = parseInt(_chainId, 16);
            if (chaindId === ENV.requiredChainId) {
                store.dispatch(setWalletError(""));
            }
            else {
                store.dispatch(setWalletError(`Please switch to ${ENV.requiredChainName} in order to use all features of Marketplace`));
            }
        })
    }
}
export const putOnSale = async (_nftData) => {
    const web3 = await getWeb3();
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        const tokenContract = new web3.eth.Contract(contractAbi, nftContractAddress);

        await methods.send(
            tokenContract.methods.putOnSale,
            [_nftData.assetNumbers, _nftData.assetId],
            connectedAddress,
            0,
            false
        );
        return true
    } catch (e) {
        toast.error('Sorry, unable to put on sale.')
        return false;
    }
}
export const putOffSale = async (_nftData) => {
    const web3 = await getWeb3();
    try {
        const isLocked = await isWalletLocked()
        if (isLocked)
            return false

        const connectedAddress = await connectMetamask();
        const tokenContract = new web3.eth.Contract(contractAbi, nftContractAddress);

        await methods.send(
            tokenContract.methods.putOffSale,
            [_nftData.assetId],
            connectedAddress,
            0,
            false
        );
        return true
    } catch (e) {
        toast.error('Sorry, unable to put off sale.')
        return false;
    }
}
const setWalletError = (message) => {
    return {
        type: SET_WALLET_ERROR,
        payload: message
    }
}
// redirect user to connect wallet
const redirectToWallet = () => {
    return {
        type: REDIRECT_TO_WALLET,
        payload: true
    }
}
accountsChangedHandler();