import React from 'react';
import Banner from '../home/banner/Banner';
import Sliders from '../home/slider/Slider';
import Trending from '../home/trending/Trending';
import Dalua from '../home/dalua/Dalua';
import Requiry from '../home/contactform/Contact';
import Footer from '../home/footer/Footer';
import Web3AquaCultre from './Web3AquaCultre';
function Template() {
	return (
		<React.Fragment>
			<Banner />
			<Web3AquaCultre />
			<Sliders />
			<Trending />
			<Dalua />
			<Requiry />
			<Footer />
		</React.Fragment>
	);
}

export default Template;