import React from 'react';
import { ENV } from '../../config/config'
const itemPlaceholderImg = ENV.globalPlaceholderImage

const NFTPreview = (props) => {
    return (
        <div className="card no-hover text-center">
            <div className="image-over">
                <img id="nft-image" className="card-img-top" src={props.nftImageLink ? ENV.webUrl + props.nftImageLink : itemPlaceholderImg} alt="img" />
            </div>
            <div className="card-caption col-12 p-0">
                <div className="card-body mt-4">
                    <h5 className="mb-3">{props.name}</h5>
                </div>
            </div>
        </div>
    );
}

export default NFTPreview;