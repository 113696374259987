import { BEFORE_APP, SET_RATE, SET_ANN, BEFORE_WEB3_CHECK, SET_WEB3_CHECK } from './redux/types';
import { emptyError } from './redux/shared/error/error.action';
import axios from 'axios';
import { ENV } from './config/config';


export const beforeApp = () => {
    return {
        type: BEFORE_APP
    }
};

export const beforeWeb3Check = () => {
    return {
        type: BEFORE_WEB3_CHECK
    }
}

export const setWeb3Check = () => dispatch => {
    dispatch(emptyError());
    dispatch({
        type: SET_WEB3_CHECK,
        payload: true
    })
}

export const getRate = (id) => dispatch => {
    dispatch(emptyError());
    axios.get(ENV.dollar + `?id=${id}`).then((res) => {
        if (res) {
            dispatch({
                type: SET_RATE,
                payload: res.data[0].price_usd
            })
        }
    })
};

export const getAnnRate = () => dispatch => {
    dispatch(emptyError());
    axios.get(ENV.ann).then((res) => {
        if (res) {
            dispatch({
                type: SET_ANN,
                payload: res.data.market_data.current_price.usd
            })
        }
    })
};