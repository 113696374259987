import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

const Web3AquaCultre = () => {
    return (
        <div className="aqua-culture">
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="culture-head text-center">
                            <h2>
                                So, What in the World is an NFT, WEB 3.0 and Aqua Culture?
                            </h2>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="aqua-flip-card">
                            <div className="aqua-flip-card-inner">
                                <div className="aqua-flip-card-front culture-para-1 d-flex justify-content-between align-items-center">
                                    <p>
                                        NFT’s are digital assets, art works, tokens or tickets, basically any digital object that is certifiable by the block chain.
                                    </p>
                                </div>
                                <div className="aqua-flip-card-back culture-para-1 d-flex justify-content-between align-items-center">
                                    <p>
                                        NFT’s are digital assets, art works, tokens or tickets, basically any digital object that is certifiable by the block chain.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="aqua-flip-card">
                            <div className="aqua-flip-card-inner">
                                <div className="aqua-flip-card-front culture-para-2 d-flex justify-content-between align-items-center">
                                    <p>
                                        And WEB 3.0? It’s a new form of website where your digital tokens (NFT’s see above) can be certified through your web browser or wallet and thereby allowing private area access and a direct connection with the site you’re visiting.
                                    </p>
                                </div>
                                <div className="aqua-flip-card-back culture-para-2 d-flex justify-content-between align-items-center">
                                    <p>
                                        And WEB 3.0? It’s a new form of website where your digital tokens (NFT’s see above) can be certified through your web browser or wallet and thereby allowing private area access and a direct connection with the site you’re visiting.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                    <div className="aqua-flip-card">
                            <div className="aqua-flip-card-inner">
                                <div className="aqua-flip-card-front culture-para-3 d-flex justify-content-between align-items-center">
                                    <p>
                                    So Aqua Culture is? Where all of the above comes together. It’s like minded people who are fanatical about reefing and aquariums. It’s culture, art, community and events. It’s our global reefing community coming together to celebrate every aspect of this amazing hobby and way of life.
                                    </p>
                                </div>
                                <div className="aqua-flip-card-back culture-para-3 d-flex justify-content-between align-items-center">
                                    <p>
                                    So Aqua Culture is? Where all of the above comes together. It’s like minded people who are fanatical about reefing and aquariums. It’s culture, art, community and events. It’s our global reefing community coming together to celebrate every aspect of this amazing hobby and way of life.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Web3AquaCultre