/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useWeb3React } from '@web3-react/core';
import { setWalletAddress } from "../../wallet/wallet.action";
import { ENV } from '../../../config/config';
import { connect } from "react-redux";
import logo from "../../../assets/images/logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { faWallet } from '@fortawesome/free-solid-svg-icons'
import { searchNft, beforeNFT } from "../../nfts/nfts.action";

const Header = (props) => {
  const { chainId } = useWeb3React();
  const history = useHistory();
  const pathName = history.location.pathname;
  const [dropDownCheck, setDropDownCheck] = useState(false);
  const [settings, setSettings] = useState(null)
  const [networkMsg, setNetworkMsg] = useState('')
  const [initHeader, setInitHeader] = useState(false)

  const formatAddress = (address) => {
    return address ? address.substr(0, 6) + "..." + address.substr(-4) : null;
  };

  useEffect(() => {
    if (props.settings.settingsAuth) {
      setSettings(props.settings.settings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.settings.settingsAuth])

  useEffect(() => {
    if (initHeader) {
      if (localStorage.getItem('connectedAddress')) {
        if (chainId) {
          if (parseInt(chainId) === ENV.requiredChainId) {
            setNetworkMsg('')
          }
        }
        else {
          setNetworkMsg(`Please switch to ${ENV.requiredChainName} in order to use all features of Marketplace`)
        }
      }
    }
    // eslint-disable-next-line
  }, [chainId])

  useEffect(() => {
    setInitHeader(true)
  }, [])


  return (
    <header id="header" className={`${pathName === "/" ? 'home-page' : ''}`}>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg">
        <div className="container header">
          {/* Navbar Brand*/}
          <Link className="navbar-brand" to="/">
            <img
              className="navbar-brand-sticky"
              src={logo}
              alt="sticky brand-logo"
            />
          </Link>
          <div className="ml-auto" />

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            {/* Navbar */}
            <ul className="navbar-nav items">
              <li className="nav-item dropdown">
                <Link className="nav-link" id="link-navv-1" to="/explore-all">
                  Marketplace
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/investor" className="nav-link" id="link-navv-2">
                  Investors
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/authors" className="nav-link" id="link-navv-3">
                  Creators
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/auctions" className="nav-link" id="link-navv-4">
                  Live Auctions
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/activity" className="nav-link" id="link-navv-6">
                  Activity
                </Link>
              </li>
              {
                settings && settings.whitepaper ?
                  <li className="nav-item">
                    <a href={settings.whitepaper} rel="noreferrer" target="_blank" className="nav-link" id="link-navv-6">
                      Whitepaper
                    </a>
                  </li>
                  : ''
              }
              <li className="nav-item">
                <Link to="/about-us" className="nav-link" id="link-navv-7">
                  About A/C
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/community" className="nav-link" id="link-navv-8">
                  $1MM Aquarium
                </Link>
              </li>
            </ul>
          </div>
          {/* Connect Wallet */}
          <ul className="navbar-nav action d-flex header-user-btns">
            {
              settings && settings.discord ?
                <li className="ml-2 ml-xxl-3">
                  <a href={settings.discord} target="_blank" rel="noreferrer" className="headerbtn color-hover btn ml-lg-auto btn-bordered-white">
                    <span className="header-btn-name">Connect Discord</span>
                    <FontAwesomeIcon icon={faDiscord} className="ml-md-2"></FontAwesomeIcon>
                  </a>
                </li>
                : ''
            }
            {!localStorage.getItem("encuse") || dropDownCheck ? (
              <li className="ml-2 ml-xxl-3">
                <Link
                  to="/login"
                  className="headerbtn color-hover btn ml-lg-auto btn-bordered-white"
                >
                  <span className="header-btn-name">Connect Wallet / Sign Up</span>
                  <FontAwesomeIcon icon={faWallet} className="ml-md-2" />
                </Link>
              </li>
            ) : (
              <li className="ml-2 ml-xxl-3">
                <Dropdown className="wallet-icon-dropdown">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="btn ml-lg-auto"
                    data-toggle="tooltip"
                    title="Wallet"
                  >
                    <Link to="#" className="text-white">
                      <span className="header-btn-name">{formatAddress(localStorage.getItem("connectedAddress"))}</span>
                      <FontAwesomeIcon icon={faWallet} />
                    </Link>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      id="wallet-item-color"
                      onClick={() => {
                        history.push("/profile");
                      }}
                    >
                      My Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      id="wallet-item-color"
                      onClick={() => {
                        history.push("/create");
                      }}
                    >
                      Create NFT
                    </Dropdown.Item>
                    <Dropdown.Item
                      id="wallet-item-color"
                      onClick={() => {
                        history.push("/my-collections");
                      }}
                    >
                      My Collections
                    </Dropdown.Item>
                    <Dropdown.Item
                      id="wallet-item-color"
                      onClick={() => {
                        history.push("/my-nft-gallery");
                      }}
                    >
                      My NFT Gallery
                    </Dropdown.Item>
                    <Dropdown.Item
                      id="wallet-item-color"
                      onClick={() => {
                        history.push("/view-bids-offers");
                      }}
                    >
                      View Bids/Offers
                    </Dropdown.Item>
                    <Dropdown.Item
                      id="wallet-item-color"
                      onClick={() => {
                        setNetworkMsg('')
                        localStorage.removeItem("encuse");
                        localStorage.removeItem("connectedAddress");
                        localStorage.removeItem("connectedWalletName");
                        history.push("/");
                        setDropDownCheck(true);
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            )}
          </ul>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon fa fa-bars"></span>
          </button>
        </div>
      </nav>
      {
        networkMsg ?
          <div className="network-message text-center mb-0">
            {networkMsg}
          </div>
          : ''
      }
    </header>
  );
};

const mapStateToProps = (state) => ({
  wallet: state.wallet,
  nft: state.nft,
  settings: state.settings
});

export default connect(mapStateToProps, {
  setWalletAddress,
  searchNft,
  beforeNFT,
})(Header);
