import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { beforeCollection, upsertCollection } from '../collections/collections.actions';
import { beforeCategory, getCategories } from '../categories/categories.action';
import { emptyError } from '../../redux/shared/error/error.action';
import $ from 'jquery'
import SimpleReactValidator from 'simple-react-validator'
import FullPageLoader from '../../components/full-page-loader/full-page-loader';


class CreateCollection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitted: false,
            formValid: true,
            loader: true,
            errors: '',
            collection: {
                userId: '',
                categoryId: '',
                logo: '',
                featuredImg: '',
                banner: '',
                name: '',
                url: '',
                description: ''
            },
            categories: null,
            urlMsg: '',
            submitCheck: false
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            messages: {
                required: 'This field is required.'  // will override all messages
            },
        })
    }

    componentDidMount() {
        window.scroll(0, 0)
        this.props.getCategories()
    }

    componentDidUpdate() {
        if (localStorage.getItem('encuse')) {
            if (this.props.error) {
                this.setState({ loader: false }, () => {
                    this.props.emptyError()
                })
            }

            if (this.props.collection.upsertAuth) {
                this.props.beforeCollection()
                this.props.history.push('/my-collections')
            }

            if (this.props.category.getAuth) {
                const { categories } = this.props.category
                this.setState({ categories, loader: false }, () => {
                    this.props.beforeCategory()
                })
            }
            if (this.props.settings.settingsAuth && !this.state.settings) {
                this.setState({ settings: this.props.settings.settings })
            }
        }
        else {
            this.props.history.push('/login')
        }
    }

    onFileChange(e) {
        let file = e.target.files[0];
        if (file)
            if (file.type.includes('image')) {
                let { collection } = this.state
                collection = { ...collection, [e.target.name]: file }
                if (e.target.name) {
                    collection[`${e.target.name}Url`] = URL.createObjectURL(e.target.files[0])
                    $(`#collection-${e.target.name}-label`).html('File selected')
                }
                this.setState({ collection })
            }
    }

    onChange(e) {
        let { name, value } = e.target
        let { collection } = this.state
        collection = { ...collection, [name]: value }
        this.setState({ collection })
    }

    reset = () => {
        const collection = {
            userId: '',
            categoryId: '',
            logo: '',
            featuredImg: '',
            banner: '',
            name: '',
            url: '',
            description: '',
        }
        this.setState({ collection, isSubmitted: false })
    }

    isValidUrl = (userInput) => {
        var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g);
        if (res == null)
            return false;
        else
            return true;
    }

    submit = (e) => {
        e.preventDefault()
        this.setState({
            ...this.state,
            submitCheck: true
        })
        this.setState({ isSubmitted: true, formValid: this.validator.allValid() ? true : false }, () => {
            const { formValid } = this.state
            if (formValid) {
                this.setState({
                    loader: true,
                }, async () => {
                    if (this.state.collection.url) {
                        if (!this.isValidUrl(this.state.collection.url)) {
                            this.setState({
                                ...this.state,
                                urlMsg: 'Link Is Invalid.',
                                loader: false
                            })
                            window.scroll(0, 0)
                            return
                        }
                        else {
                            this.setState({
                                ...this.state,
                                urlMsg: '',
                                // loader: false
                            })
                        }
                    }
                    let { collection } = this.state
                    var formData = new FormData()
                    for (const key in collection)
                        if (collection[key])
                            formData.append(key, collection[key])
                    this.props.upsertCollection('collection/create', formData)
                })
            } else {
                this.validator.showMessages()
                this.setState({
                    errors: 'Please fill all required fields in valid format.',
                    formValid: false
                }, () => {
                    $('#create-collection').scrollTop(0, 0)
                })
            }
        })
    }

    render() {
        const { collection, errors, loader, isSubmitted, categories } = this.state

        return (
            <section className="author-area padding-wrapper">
                {loader && <FullPageLoader />}
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-12">
                            <div className="mt-5 mt-lg-0 mb-4 mb-lg-5">
                                <div className="intro">
                                    <div className="intro-content">
                                        <h3 className="mt-3 mb-0">Create Collection</h3>
                                    </div>
                                </div>
                                {
                                    isSubmitted && errors &&
                                    <div className="row">
                                        <div className="col-12">
                                            <span id="create-collection-err" className="text-danger">{errors}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <form id="create-collection" className="item-form card no-hover">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="input-group form-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="logo" accept=".png,.jpeg,.jpg" onChange={(e) => this.onFileChange(e)} name="logo" />
                                                <label id="collection-logo-label" className="custom-file-label" htmlFor="logo">Choose Logo *</label>
                                            </div>
                                            <span className="text-danger">{this.validator.message('logo', collection.logo, 'required')}</span>
                                        </div>
                                        {
                                            this.state.settings && this.state.settings.imageSize ?
                                                <div className="text-info note-text mt-3">
                                                    Maximum recommended size of image is {this.state.settings.imageSize} MB(s)
                                                </div>
                                                : ''
                                        }
                                    </div>
                                    <div className="col-12 ">
                                        <div className="card no-hover text-center profile">
                                            <div className="image-over">
                                                <img id="logo-placeholder" className={collection.logoUrl ? "card-img-top" : "card-img-top create-collection-placeholder"} src={collection.logoUrl ? collection.logoUrl : "/img/placeholder.png"} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group form-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="featuredImg" accept=".png,.jpeg,.jpg" onChange={(e) => this.onFileChange(e)} name="featuredImg" />
                                                <label id="collection-featuredImg-label" className="custom-file-label" htmlFor="featuredImg"> Choose Featured Image</label>
                                            </div>
                                        </div>
                                        {
                                            this.state.settings && this.state.settings.imageSize ?
                                                <div className="text-info note-text mt-3">
                                                    Maximum recommended size of image is {this.state.settings.imageSize} MB(s)
                                                </div>
                                                : ''
                                        }
                                    </div>
                                    <div className="col-12 ">
                                        <div className="card no-hover text-center featured">
                                            <div className="image-over">
                                                <img id="featuredImg-placeholder" className={collection.featuredImgUrl ? "card-img-top" : "card-img-top create-collection-placeholder"} src={collection.featuredImgUrl ? collection.featuredImgUrl : "/img/placeholder.png"} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group form-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="banner" accept=".png,.jpeg,.jpg" onChange={(e) => this.onFileChange(e)} name="banner" />
                                                <label id="collection-banner-label" className="custom-file-label" htmlFor="banner">Choose Banner</label>
                                            </div>
                                        </div>
                                        {
                                            this.state.settings && this.state.settings.imageSize ?
                                                <div className="text-info note-text mt-3">
                                                    Maximum recommended size of image is {this.state.settings.imageSize} MB(s)
                                                </div>
                                                : ''
                                        }
                                    </div>
                                    <div className="col-12">
                                        <div className="card no-hover text-center banner">
                                            <div className="image-over">
                                                <img id="banner-placeholder" className={collection.bannerUrl ? "card-img-top" : "card-img-top create-collection-placeholder"} src={collection.bannerUrl ? collection.bannerUrl : "/img/placeholder.png"} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="name" placeholder="Name *" onChange={(e) => this.onChange(e)} defaultValue={collection.name} />
                                            <span className="text-danger">{this.validator.message('name', collection.name, 'required')}</span>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="url" placeholder="URL"
                                                onChange={(e) => {
                                                    this.onChange(e)
                                                    if (this.state.submitCheck) {
                                                        if (e.target.value) {
                                                            if (!this.isValidUrl(e.target.value)) {
                                                                let { collection } = this.state
                                                                collection = { ...collection, url: e.target.value }
                                                                this.setState({
                                                                    ...this.state,
                                                                    urlMsg: 'Link Is Invalid.',
                                                                    collection
                                                                })
                                                            }
                                                            else {
                                                                let { collection } = this.state
                                                                collection = { ...collection, url: e.target.value }
                                                                this.setState({
                                                                    ...this.state,
                                                                    urlMsg: '',
                                                                    collection
                                                                })
                                                            }
                                                        }
                                                    }
                                                }} defaultValue={collection.url} />
                                            {this.state.collection.url ? <label className={`text-danger pl-1 ${this.state.urlMsg ? `` : `d-none`}`}>{this.state.urlMsg}</label> : ''}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <span className="no-padding">
                                                <select className="form-control" id="category" name="categoryId" onChange={(e) => this.onChange(e)}>
                                                    <option value="">Select Category</option>
                                                    {
                                                        categories && categories.map((category, index) => {
                                                            if(!category.adminCategory)
                                                                return (
                                                                    <option key={index} value={category._id}>{category.name}</option>
                                                                )
                                                            return false
                                                        })
                                                    }
                                                </select>
                                            </span>
                                            <span className="text-danger">{this.validator.message('category', collection.categoryId, 'required')}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea className="form-control" name="description" placeholder="Description" cols={30} rows={3} onChange={(e) => this.onChange(e)} defaultValue={collection.description} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button disabled={loader} className="btn w-100 mt-3 mt-sm-4" type="button" onClick={(e) => this.submit(e)}>Create Collection</button>
                                        <Link to="/my-collections" disabled={loader} className="btn btn-grey w-100 mt-3 mt-sm-4" >Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </section >
        )
    }
}

const mapStateToProps = state => ({
    collection: state.collection,
    error: state.error,
    category: state.category,
    settings: state.settings
});

export default connect(mapStateToProps, { beforeCollection, upsertCollection, emptyError, beforeCategory, getCategories })(CreateCollection);