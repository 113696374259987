/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { beforeCategory, getCategories } from '../../categories/categories.action'
import { getNFTs, beforeNFT } from '../../nfts/nfts.action'
import { ENV } from '../../../config/config'
import moment from "moment";
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import FullPageLoader from "../../full-page-loader/full-page-loader";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Trending = (props) => {

    const [categories, setCategories] = useState(null)
    const [nfts, setNfts] = useState(null)
    const history = useHistory()
    const [loader, setLoader] = useState(true)
    const [adminId, setAdminId] = useState('')

    useEffect(() => {
        const qs = ENV.objectToQueryString({ limit: 7, bothNfts: true })
        props.getCategories()
        props.getNFTs(qs)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.category.getAuth) {
            setCategories(props.category.categories)
            props.beforeCategory()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.category.getAuth])

    useEffect(() => {
        if (props.nft.nftsAuth) {
            setAdminId(props.nft.nftsData.adminId)
            setNfts(props.nft.nftsData.nfts)
            props.beforeNFT()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nft.nftsAuth])

    useEffect(() => {
        if (nfts) {
            setLoader(false)
        }
    }, [nfts])

    const activator = (index) => {
        document.querySelectorAll('.dip-cat').forEach((item) => {
            item.classList.remove('active')
        })
        document.querySelector(`#cat-dip-${index}`).classList.add('active')
    }

    const truncate = (str) => {
        return str.length > 10 ? str.substring(0, 7) + "..." : str;
    }

    return (
        <div className="trending">
            <div className="container-fluid">
                <div className="trending-cont">
                    <div className="trend mr-3">
                        <h3>TRENDING NFTS</h3>
                    </div>
                    <div className="trending-btn">
                        <a id={"cat-dip-first"} className="dip-cat active" style={{ cursor: "pointer" }} onClick={() => { let qs = ENV.objectToQueryString({ limit: 9, bothNfts: true }); props.getNFTs(qs); activator("first"); setLoader(true); }}>
                            All items
                        </a>
                        {
                            categories && categories.length ?
                                categories.map((item, index) => {
                                    return (
                                        <a id={`cat-dip-${index}`} style={{ cursor: "pointer" }} key={index} className="dip-cat" onClick={() => { let qs = ENV.objectToQueryString({ categoryId: item._id, limit: 9, bothNfts: true }); props.getNFTs(qs); activator(index); setLoader(true); }}>
                                            {item.name}
                                        </a>
                                    )
                                }) : ''
                        }
                    </div>
                </div>
                {
                    loader ?
                        <FullPageLoader />
                        :
                        <div className="vp__example --grcspan2">
                            {
                                nfts && nfts.length ?
                                    nfts.map((item, index) => {
                                        if (index < 1) {
                                            if ((item.sellingMethod) || ((String(adminId) === String(item.owner._id)) && (item.purchaseCount < item.maxAllowed))) {
                                                if ((new Date().getTime() > new Date(item.auctionStartDate).getTime()) && (new Date().getTime() < new Date(item.auctionEndDate).getTime())) {
                                                    return (
                                                        <div className="vp-item bc--teal trend-nft-count" key={index}>
                                                            <div className="position-relative overflow-hidden img-contain2">
                                                                <img className="img-fluid trending-nfts-img1" src={ENV.webUrl + item.nftImageLink} style={{ width: "100%", height: "100%", objectFit: "contain" }} alt='img' />
                                                                <button className="detail-btn" onClick={() => { history.push(`/item-details?item=${window.btoa(item._id)}${String(adminId) === String(item.owner._id) ? `&adminCheck=true` : ''}${ item.sellingNftId ? `&sellingNftId=${item.sellingNftId}`: ''}`) }}>View Details</button>
                                                            </div>
                                                            <div className="slider-conts">
                                                                <div className="content-wrapper">
                                                                    <div className="cont1">
                                                                        <h4>
                                                                            {item.name}
                                                                        </h4>
                                                                    </div>
                                                                    {
                                                                        item.currentPrice ?
                                                                            <div className="cont2">
                                                                                <button type="button" className="btn btn-primary">
                                                                                    <span>{item.currentPrice} {ENV.currency}</span>
                                                                                </button>
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                </div>
                                                                <div className="cont">
                                                                    <div className="cont3">
                                                                        {
                                                                            item.creator.username ?
                                                                                <p className="d-flex">
                                                                                    Creator: {truncate(item.creator.username)}
                                                                                    {
                                                                                        item.creator && item.creator.verificationCheck ?
                                                                                            <>
                                                                                                <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                                                </span>
                                                                                                <ReactTooltip />
                                                                                            </>
                                                                                            : ''
                                                                                    }
                                                                                </p>
                                                                                : ''
                                                                        }
                                                                    </div>
                                                                    {
                                                                        item.auctionEndDate ?
                                                                            <div className="cont4">
                                                                                <img src="img/time-left.svg" alt='img' />Ends {moment(item.auctionEndDate).fromNow()}
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        }
                                        // else if (index < 7) {
                                        else if (index < 50) {
                                            if ((item.sellingMethod) || ((String(adminId) === String(item.owner._id)) && (item.purchaseCount < item.maxAllowed))) {
                                                if ((new Date().getTime() > new Date(item.auctionStartDate).getTime()) && (new Date().getTime() < new Date(item.auctionEndDate).getTime())) {
                                                    return (
                                                        <div className="vp-item trend-nft-count" key={index}>
                                                            <div className="position-relative overflow-hidden img-contain3">
                                                                <img src={ENV.webUrl + item.nftImageLink} style={{ width: "100%", height: "100%", objectFit: "contain" }} alt='img' />
                                                                <button className="detail-btn2" onClick={() => { history.push(`/item-details?item=${window.btoa(item._id)}${String(adminId) === String(item.owner._id) ? `&adminCheck=true` : ''}${ item.sellingNftId ? `&sellingNftId=${item.sellingNftId}`: ''}`) }}>View Details</button>
                                                            </div>
                                                            <div className="slider-conts">
                                                                <div className="content-wrapper">
                                                                    <div className="cont1">
                                                                        <h4>
                                                                            {item.name}
                                                                        </h4>
                                                                    </div>
                                                                    {
                                                                        item.currentPrice ?
                                                                            <div className="cont2">
                                                                                <button type="button" className="btn btn-primary">
                                                                                    <span>{item.currentPrice} {ENV.currency}</span>
                                                                                </button>
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                </div>
                                                                <div className="cont">
                                                                    <div className="cont3">
                                                                        {
                                                                            item.creator.username ?
                                                                                <p className="d-flex">
                                                                                    Creator: {truncate(item.creator.username)}
                                                                                    {
                                                                                        item.creator && item.creator.verificationCheck ?
                                                                                            <>
                                                                                                <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                                                </span>
                                                                                                <ReactTooltip />
                                                                                            </>
                                                                                            : ''
                                                                                    }
                                                                                </p>
                                                                                : ''
                                                                        }
                                                                    </div>
                                                                    {
                                                                        item.auctionEndDate ?
                                                                            <div className="cont4">
                                                                                <img src="img/time-left.svg" alt='img' />Ends {moment(item.auctionEndDate).fromNow()}
                                                                            </div>
                                                                            : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        }
                                        return false
                                    }) :
                                    'No Item To Show.'
                            }
                        </div>
                }
                {
                    !loader && (document.querySelectorAll('.trend-nft-count').length ? true : false) ?
                        <div className="text-center pt-3">
                            <Button className=" banner-btn " variant="primary" size="lg" onClick={() => { history.push('/explore-all') }}>
                                View All
                            </Button>
                        </div> : ''
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    category: state.category,
    nft: state.nft
});

export default connect(mapStateToProps, {
    beforeCategory, getCategories, getNFTs, beforeNFT
})(Trending);