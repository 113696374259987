/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { beforeCollection, getCollections } from '../collections/collections.actions'
import { beforeCategory, getCategories } from '../categories/categories.action'
import { beforeUser, getCreators } from '../user/user.action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from "react-tooltip";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ENV } from '../../config/config'
const { decimalNumberValidator, globalPlaceholderImage } = ENV
const currencies = (ENV.currencies).filter(elem => elem.showInFilters === true)

function FilterSearch(props) {
  const [collections, setCollections] = useState(null);
  const [categories, setCategories] = useState(null);
  const [authors, setAuthors] = useState(null);

  const [status, setStatus] = useState(null); // 1 = on auction, 2 = has offer, 3 = new
  const [currency, setCurrency] = useState(null);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [filter, setFilter] = useState(null);

  let filtersCheck = true;

  useEffect(() => {
    // get collections only if collection filters are visible
    if (props.showColFilters)
      getCollections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showColFilters])

  useEffect(() => {
    // get categories only if category filters are visible
    if (props.showCatFilters)
      getCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showCatFilters])

  useEffect(() => {
    // get authors only if author filters are visible
    if (props.showAuthorFilters)
      getAuthors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showAuthorFilters])

  // apply status filter
  const applyStatusFilter = (value) => {
    setStatus(value)

    const filterData = { ...filter, status: value }

    if (!filterData.status)
      delete filterData.status

    setFilter(filterData)
    props.applyFilters(filterData)
  }

  const handleChange = selectedOption => {
    setCurrency(selectedOption?.value || null)
  }

  const onChange = (e) => {
    const { name, value } = e.target
    if (name === 'minPrice')
      setMinPrice(value)
    else if (name === 'maxPrice')
      setMaxPrice(value)
  }

  // apply price filter
  const applyPriceFilter = () => {
    const filterData = { ...filter }
    if (minPrice)
      filterData.minPrice = minPrice
    else
      delete filterData.minPrice

    if (maxPrice)
      filterData.maxPrice = maxPrice
    else
      delete filterData.maxPrice

    if (currency)
      filterData.currency = currency
    else
      delete filterData.currency

    setFilter(filterData)
    props.applyFilters(filterData)
  }

  // clear price filter
  const clearPriceFilter = () => {
    // clear fields
    setCurrency(null)
    setMinPrice('')
    setMaxPrice('')

    const filterData = { ...filter }
    delete filterData.minPrice
    delete filterData.maxPrice
    delete filterData.currency
    setFilter(filterData)
    props.applyFilters(filterData)
  }

  const getCollections = () => {
    const filter = {
      all: true,
    }

    const qs = ENV.objectToQueryString(filter)
    props.getCollections(qs)
  }

  const getCategories = () => {
    const filter = {
      all: true,
    }

    const qs = ENV.objectToQueryString(filter)
    props.getCategories(qs)
  }

  const getAuthors = () => {
    props.getCreators()
  }

  // set authors
  useEffect(() => {
    if (props.user.creatorsAuth) {
      setAuthors(props.user.creators?.creators)
      props.beforeUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.creatorsAuth])

  // set collections
  useEffect(() => {
    if (props.collection.getAuth) {
      const { collections } = props.collection
      setCollections(collections)
      props.beforeCollection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.collection.getAuth])

  // set categories
  useEffect(() => {
    if (props.category.getAuth) {
      const { categories } = props.category
      setCategories(categories)
      props.beforeCategory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.category.getAuth])

  // get collection NFTs
  const getColNFTs = (collectionId) => {
    const filterData = { ...filter }

    if (collectionId)
      filterData.collectionId = collectionId

    if (collectionId === 'all')
      delete filterData.collectionId

    setFilter(filterData)
    props.applyFilters(filterData)
  }

  // get categories NFTs
  const getCatNFTs = (categoryId) => {
    const filterData = { ...filter }

    if (categoryId)
      filterData.categoryId = categoryId

    if (categoryId === 'all')
      delete filterData.categoryId

    setFilter(filterData)
    props.applyFilters(filterData)
  }

  // get author / creators NFTs
  const getAuthorNFTs = (authorId) => {
    const filterData = { ...filter }

    if (authorId)
      filterData.creatorId = authorId

    if (authorId === 'all')
      delete filterData.creatorId

    setFilter(filterData)
    props.applyFilters(filterData)
  }

  const search = (value) => {
    const filterData = { ...filter }
    if (value)
      filterData.name = value
    setFilter(filterData)
    props.applyFilters(filterData)
  }

  const searchNum = (value) => {
    if (value.match(/\d+/g)) {
      if (parseInt(value) > 0) {
        const filterData = { ...filter }
        filterData.numberedNft = value
        setFilter(filterData)
        props.applyFilters(filterData)
      }
    }
  }

  const clear = () => {
    document.querySelector('#search-sidebar').value = '';
    document.querySelector('#search-number-sidebar').value = '';
    setStatus(null);
    setCurrency(null);
    setMinPrice('');
    setMaxPrice('');
    setFilter(null);
    setFilter({ page: 1, limit: 40, explore: true })
    props.applyFilters({ page: 1, limit: 40, explore: true })
  }

  return (
    <React.Fragment>
      <div className="filter-wrapper" id="filter-sidebar">
        <div className="total-result" id="total-result-sidebar">0 result found</div>
        <div className="statuses-boxex2">


        </div>

        <div
          className={`${filtersCheck ? 'open' : ''} status-wrapper d-flex`}
        >
          <div className='d-flex flex-column align-items-start w-100'>
            <div className="mb-3 w-100 d-flex justify-content-between">
              <span><b>Filters</b></span>
              <div className="clear-all">
                <a onClick={() => { clear() }}>Clear all filters</a>
              </div>
            </div>
            <div className="search-box w-100">
              <form onSubmit={(e) => { e.preventDefault(); search(e.target[0].value) }}>
                <input type="text" placeholder="Search Nfts By Name" className="search-box" name="search" id="search-sidebar" />
                <a onClick={() => { search(document.querySelector('#search-sidebar').value) }}>
                  <span className="icon fa fa-search"></span>
                </a>
              </form>
            </div>
            <div className="search-box w-100">
              <form onSubmit={(e) => { e.preventDefault(); searchNum(e.target[0].value) }}>
                <input type="text" placeholder="Search Nfts By Number" className="search-box" name="search" id="search-number-sidebar" />
                <a onClick={() => { searchNum(document.querySelector('#search-number-sidebar').value) }}>
                  <span className="icon fa fa-search"></span>
                </a>
              </form>
            </div>
          </div>
        </div>
        {
          filtersCheck &&
          <>
            <div className='p-2'><span><b>Status</b></span></div>
            <div className="statuses-boxex" style={{ border: "none" }}>
              <div className={`on-auction ${status === 1 && 'active'}`} onClick={() => applyStatusFilter(status === 1 ? null : 1)}>On Auction</div>
              <div className={`on-auction ${status === 2 && 'active'}`} onClick={() => applyStatusFilter(status === 2 ? null : 2)}>Has Offer</div>
              <div className={`on-auction ${status === 3 && 'active'}`} onClick={() => applyStatusFilter(status === 3 ? null : 3)}>New</div>
            </div>
            <div className='p-2'><span><b>Price</b></span></div>
            <div className="statuses-boxex2">
              <Select
                options={currencies}
                value={currency ? currencies.filter(option => option.value === currency) : currencies[0]}
                onChange={handleChange}
                className="select-custom-style"
                placeholder="Select Currency"
                classNamePrefix="custom-select"
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    color: state.isDisabled ? 'grey' : 'hsl(0,0%,20%)',
                    cursor: state.isDisabled ? 'not-allowed' : 'pointer'
                  })
                }}
                isSearchable={false}
              />
              <div className="d-flex justify-content-center align-items-center mt-3">
                <input type="text" placeholder="min" className="min-max mr-2" name="minPrice" onChange={(e) => onChange(e)} onKeyDown={(e) => decimalNumberValidator(e)} value={minPrice} /> to <input type="text" placeholder="max" className="min-max ml-2" name="maxPrice" onChange={(e) => onChange(e)} onKeyDown={(e) => decimalNumberValidator(e)} value={maxPrice} />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <button className="apply-btn" onClick={() => applyPriceFilter()} disabled={!(minPrice || maxPrice)}>Apply</button>
                <button className="apply-btn grey-btn" onClick={() => clearPriceFilter()}>Clear</button>
              </div>
            </div>

            <div className='p-2'><span><b>Category</b></span></div>
            <div className="d-flex justify-content-start statuses-boxex flex-column" style={{ border: "none" }}>
              <div className={`d-flex align-items-center filter-item ${!filter?.categoryId && 'active'}`} onClick={() => getCatNFTs('all')}>
                <div className="collection-img rounded"></div><span className="ml-2">All</span>
              </div>
              {
                categories && categories.map((category, index) => {
                  return (
                    <div key={index} className={`d-flex align-items-center filter-item ${category._id === filter?.categoryId && 'active'}`} onClick={() => getCatNFTs(category._id)}>
                      <div className="filter-img"><img className="rounded" src={ENV.webUrl + category.imageLink} alt="img" /></div><span className="ml-2">{category.name}</span>
                    </div>
                  )
                })
              }
            </div>
            <div className='p-2'><span><b>Artists</b></span></div>
            <div className="d-flex flex-column justify-content-start statuses-boxex" style={{ border: "none" }}>
              <div className={`d-flex align-items-center filter-item ${!filter?.creatorId && 'active'}`} onClick={() => getAuthorNFTs('all')}>
                <div className="collection-img rounded"></div><span className="ml-2">All</span>
              </div>
              {
                authors && authors.map((author, index) => {
                  return (
                    <div key={index} className={`d-flex align-items-center filter-item ${author._id === filter?.creatorId && 'active'}`} onClick={() => getAuthorNFTs(author._id)}>
                      <div className="filter-img rounded">
                        <img className="rounded" src={author.profileImageLink ? ENV.webUrl + author.profileImageLink : globalPlaceholderImage} alt="img" />
                      </div>
                      <span className="ml-2 d-flex">{author.username}{
                        author.verificationCheck ?
                          <>
                            <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <ReactTooltip />
                          </>
                          : ''
                      }</span>
                    </div>
                  )
                })
              }
            </div>
            <div className='p-2'><span><b>Collection</b></span></div>
            <div className="d-flex flex-column justify-content-start statuses-boxex" style={{ border: "none" }}>
              <div className={`d-flex align-items-center filter-item ${!filter?.collectionId && 'active'}`} onClick={() => getColNFTs('all')}>
                <div className="collection-img rounded"></div><span className="ml-2">All</span>
              </div>
              {
                collections && collections.map((collection, index) => {
                  return (
                    <div key={index} className={`d-flex align-items-center filter-item ${collection._id === filter?.collectionId && 'active'}`} onClick={() => getColNFTs(collection._id)}>
                      <div className="filter-img"><img className="rounded" src={ENV.webUrl + collection.logoLink} alt="img" /></div><span className="ml-2">{collection.name}</span>
                    </div>
                  )
                })
              }
            </div>
          </>
        }
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  collection: state.collection,
  category: state.category,
  user: state.user,
  error: state.error
});

export default connect(mapStateToProps, { beforeCollection, getCollections, beforeCategory, getCategories, beforeUser, getCreators })(FilterSearch)
