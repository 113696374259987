import { BEFORE_SELLING_NFTS, GET_SELLING_NFT, FIND_SELLING_NFT_ID, GET_ERRORS } from '../../redux/types';
import { ENV } from './../../config/config';
import { emptyError } from '../../redux/shared/error/error.action';

export const beforeSellingNft = () => {
    return {
        type: BEFORE_SELLING_NFTS
    }
}
export const getSellingNft = (nftId) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}selling-nfts/get/${nftId}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_SELLING_NFT,
                payload: data.sellingNft
            })
        } else {
            data.message = 'getSellingNft ' + data.message
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};
export const getSellingNftId = (nftId) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}selling-nfts/findSellingNftId/${nftId}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: FIND_SELLING_NFT_ID,
                payload: data.sellingNftId
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};



