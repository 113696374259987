import user from '../assets/images/business.png';
import message from '../assets/images/message.png';
import email from '../assets/images/communications.png';
import phone from '../assets/images/telephone.png';
import etheriumIcon from '../assets/images/icon-ethereum.png';
import iconEthereumWhite from '../assets/images/icon-ethereum-white.png';



export const Images = {
    user,
    message,
    email,
    phone,
    etheriumIcon,
    iconEthereumWhite

}