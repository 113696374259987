import React, { Component } from 'react'
import { connect } from 'react-redux';
import { beforeCollection, upsertCollection, getCollection, deleteCollection } from '../collections/collections.actions';
import { beforeCategory, getCategories } from '../categories/categories.action';
import { emptyError } from '../../redux/shared/error/error.action';
import { ENV } from "../../config/config";
import $ from 'jquery'
import SimpleReactValidator from 'simple-react-validator'
import FullPageLoader from '../full-page-loader/full-page-loader';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from 'react-router-dom';
class EditCollection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitted: false,
            formValid: true,
            loader: true,
            errors: '',
            collection: {
                _id: '',
                userId: '',
                categoryId: '',
                logo: '',
                featuredImg: '',
                banner: '',
                name: '',
                url: '',
                description: ''
            },
            categories: null
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            messages: {
                required: 'This field is required.'  // will override all messages
            },
        })
    }

    componentDidMount() {
        if (localStorage.getItem('encuse')) {
            window.scroll(0, 0)
            this.props.getCategories()
            this.props.getCollection(this.props.match.params.id)
        }
        else {
            this.props.history.push('/login')
        }

    }

    deleteCollection = (collectionId) => {
        confirmAlert({
            title: 'Delete Collection',
            message: "Are you sure you want to delete your collection. If yes, then it'd also delete all the NFTs belonging this collection.",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({ loader: true }, () => {
                            this.props.deleteCollection(collectionId)
                        })
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // when collection data is deleted
        if (this.props.collection.deleteAuth) {
            const { collectionId } = this.props.collection.collection;
            if (collectionId) {
                this.props.beforeCollection()
                this.props.history.push("/my-collections")
            }
            this.setState({ loader: false })
        }

        if (this.props.error) {
            this.setState({ loader: false }, () => {
                this.props.emptyError()
            })
        }

        if (this.props.collection.upsertAuth) {
            this.props.beforeCollection()
            this.props.history.push('/my-collections')
        }

        if (this.props.category.getAuth) {
            const { categories } = this.props.category
            this.setState({ categories, loader: false }, () => {
                this.props.beforeCategory()
            })
        }

        if (
            this.state.collection.name === '' &&
            this.state.collection.url === '' &&
            this.state.collection.description === ''
        ) {
            if (this.props.collection.collection) {
                const { _id, name, url, description, category, user, logoLink, featuredImgLink, bannerLink, items } = this.props.collection.collection;
                let collection = { ...this.state.collection };
                collection.name = name
                collection.description = description
                collection.url = url
                collection.categoryId = category._id
                collection.categoryName = category.name
                collection.userId = user._id
                if (logoLink)
                    collection.logoUrl = ENV.webUrl + logoLink
                if (featuredImgLink)
                    collection.featuredImgUrl = ENV.webUrl + featuredImgLink
                if (bannerLink)
                    collection.bannerUrl = ENV.webUrl + bannerLink
                collection._id = _id
                collection.items = items
                delete collection['logo']
                delete collection['featuredImg']
                delete collection['banner']
                this.setState({ collection })
            }
        }
    }

    onFileChange(e) {
        let file = e.target.files[0];
        if (file)
            if (file.type.includes('image')) {
                let { collection } = this.state
                collection = { ...collection, [e.target.name]: file }
                if (e.target.name) {
                    collection[`${e.target.name}Url`] = URL.createObjectURL(e.target.files[0])
                    $(`#collection-${e.target.name}-label`).html('File selected')
                }
                this.setState({ collection })
            }
    }

    onChange(e) {
        let { name, value } = e.target
        let { collection } = this.state
        collection = { ...collection, [name]: value }
        this.setState({ collection })
    }

    reset = () => {
        const collection = {
            userId: '',
            categoryId: '',
            logo: '',
            featuredImg: '',
            banner: '',
            name: '',
            url: '',
            description: '',
        }
        this.setState({ collection, isSubmitted: false })
    }

    submit = (e) => {
        e.preventDefault()

        this.setState({ isSubmitted: true, formValid: this.validator.allValid() ? true : false }, () => {
            const { formValid } = this.state
            if (formValid) {
                this.setState({
                    loader: true,
                }, async () => {
                    const { collection } = this.state
                    var formData = new FormData()
                    for (const key in collection)
                        if (collection[key])
                            formData.append(key, collection[key])

                    this.props.upsertCollection('collection/edit', formData, 'PUT')
                })
            } else {
                this.validator.showMessages()
                this.setState({
                    errors: 'Please fill all required fields in valid format.',
                    formValid: false
                }, () => {
                    $('#create-collection').scrollTop(0, 0)
                })
            }
        })
    }

    render() {
        const { collection, errors, loader, isSubmitted, categories } = this.state
        return (
            <section className="author-area padding-wrapper">
                {loader && <FullPageLoader />}
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-12">
                            <div className="mt-5 mt-lg-0 mb-4 mb-lg-5">
                                <div className="intro">
                                    <div className="intro-content">
                                        <span>Get Started</span>
                                        <h3 className="mt-3 mb-0">Edit Collection</h3>
                                    </div>
                                </div>
                                {
                                    isSubmitted && errors &&
                                    <div className="row">
                                        <div className="col-12">
                                            <span id="create-collection-err" className="text-danger">{errors}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <form id="create-collection" className="item-form card no-hover">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="input-group form-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="logo" accept=".png,.jpeg,.jpg" onChange={(e) => this.onFileChange(e)} name="logo"

                                                />
                                                <label id="collection-logo-label" className="custom-file-label" htmlFor="logo">Choose Logo</label>
                                            </div>
                                            <span className="text-danger">{this.validator.message('logo', collection.logoUrl, 'required')}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 ">
                                        <div className="card no-hover text-center profile">
                                            <div className="image-over">
                                                <img id="logo-placeholder" className={collection.logoUrl ? "card-img-top" : "card-img-top create-collection-placeholder"} src={collection.logoUrl ? collection.logoUrl : "/img/placeholder.png"} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group form-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="featuredImg" accept=".png,.jpeg,.jpg" onChange={(e) => this.onFileChange(e)} name="featuredImg" />
                                                <label id="collection-featuredImg-label" className="custom-file-label" htmlFor="featuredImg"> Choose Featured Image</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 ">
                                        <div className="card no-hover text-center featured">
                                            <div className="image-over">
                                                <img id="featuredImg-placeholder" className={collection.featuredImgUrl ? "card-img-top" : "card-img-top create-collection-placeholder"} src={collection.featuredImgUrl ? collection.featuredImgUrl : "/img/placeholder.png"} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group form-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="banner" accept=".png,.jpeg,.jpg" onChange={(e) => this.onFileChange(e)} name="banner" />
                                                <label id="collection-banner-label" className="custom-file-label" htmlFor="banner">Choose Banner</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="card no-hover text-center banner">
                                            <div className="image-over">
                                                <img id="banner-placeholder" className={collection.bannerUrl ? "card-img-top" : "card-img-top create-collection-placeholder"} src={collection.bannerUrl ? collection.bannerUrl : "/img/placeholder.png"} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="name" placeholder="Name *" onChange={(e) => this.onChange(e)} defaultValue={collection.name} />
                                            <span className="text-danger">{this.validator.message('name', collection.name, 'required')}</span>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="url" placeholder="URL" onChange={(e) => this.onChange(e)} defaultValue={collection.url} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <span className="no-padding">
                                                <select className="form-control" id="category" name="categoryId" onChange={(e) => this.onChange(e)}>
                                                    {
                                                        categories && categories.map((category, index) => {
                                                            return (
                                                                <option key={index} value={category._id} selected={this.state.collection.categoryId === category._id ? true : false}>{category.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </span>
                                            <span className="text-danger">{this.validator.message('category', collection.categoryId, 'required')}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea className="form-control" name="description" placeholder="Description" cols={30} rows={3} onChange={(e) => this.onChange(e)} defaultValue={collection.description} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button disabled={loader} className="btn w-100 mt-3 mt-sm-4" type="button" onClick={(e) => this.submit(e)}>Update Collection</button>
                                    </div>
                                    <div className="col-12">
                                        <Link to="/my-collections" disabled={loader} className="btn btn-grey w-100 mt-3 mt-sm-4">Cancel</Link>
                                    </div>
                                    {
                                        !collection.items &&
                                        <div className="col-12">
                                            <button className="btn btn-danger w-100 mt-3 mt-sm-4" type="button" onClick={() => this.deleteCollection(collection._id)}>Delete</button>
                                        </div>
                                    }
                                    {/*  */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </section >
        )
    }
}

const mapStateToProps = state => {
    return {
        collection: state?.collection,
        error: state.error,
        category: state.category
    }
};

export default connect(mapStateToProps, { beforeCollection, upsertCollection, emptyError, beforeCategory, getCategories, getCollection, deleteCollection })(EditCollection);