import React from 'react';
import aboutLogo from '../../assets/images/about-logo.png';
import aboutContent from '../../assets/images/about-content.png';

function Breadcrumb(props) {
    return (
        <div className={`breadcrumb-area-banner d-flex align-items-center justify-content-center ${props.fullHeader ? "full-background" : ""} ${props.backgroundCover ? "background-cover" : ""}`} style={{ backgroundImage: `url(${(props.banner || props.bannerImg)})`, backgroundPosition: "center", backgroundSize:"contain",backgroundRepeat:"no-repeat" }}>
            {
                props.title === "About" ?
                    <div className='about-banner d-flex flex-column justify-content-center align-items-center'>
                        <div className='about-banner-logo mb-2'><img src={aboutLogo} alt="img" /></div>
                        <div className='about-banner-content'><img src={aboutContent} alt="img" /></div>
                    </div> :
                    props.title === "Artist Details" ?
                        <div className="text-center atrist-detail-h">
                            <h2>Artist Story</h2>
                        </div> : ''
            }
        </div>
    );
}
export default Breadcrumb;