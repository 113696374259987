import React, { useState, useEffect } from 'react';
import FullPageLoader from '../../components/full-page-loader/full-page-loader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { beforeAuction, getLiveAuctions } from './auctions.action';
import { ENV } from '../../config/config';
import Countdown from 'react-countdown';
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
const { countDownRenderer } = ENV

const initData = {
    pre_heading: "Auctions",
    heading: "Live Auctions",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    btn_1: "Load More"
}

const LiveAuctions = (props) => {
    const [pagination, setPagination] = useState(null);
    const [auctions, setAuctions] = useState(null)
    const [loader, setLoader] = useState(true) // auctions loader
    const [loadMoreBtn, setLoadMoreBtn] = useState(false);
    const [change, setChange] = useState(false);

    useEffect(() => {
        window.scroll(0, 0)
        document.querySelectorAll('.nav-link').forEach((item) => {
            item.classList.remove('activeNav')
        })
        document.querySelector(`#link-navv-4`).classList.add('activeNav')
        // get live auctions
        getLiveAuctions()
        setChange(prevState => !prevState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // set live auctions
    useEffect(() => {
        if (props.auction.getAuth) {
            const { auctions, pagination } = props.auction
            if (auctions) {
                setAuctions(auctions)
                setPagination(pagination)
                props.beforeAuction()
                setLoader(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.auction.getAuth, change])

    // handle show load more button state when pagination is set
    useEffect(() => {
        if (pagination)
            setLoadMoreBtn((pagination.total > 0 && pagination.total > auctions.length && auctions.length > 0))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination])

    const loadMore = () => {
        const { page, limit } = pagination
        setLoader(true)

        // get more live auctions
        getLiveAuctions(1, limit * (page + 1))
    }

    const getLiveAuctions = (page = 1, limit = 8) => {
        const qs = ENV.objectToQueryString({ page, limit })
        props.getLiveAuctions(qs)
    }

    return (
        <section className="live-auctions-area load-more">
            {
                loader ?
                    <FullPageLoader /> :
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-8 col-lg-7">
                                <div className="intro text-center">
                                    <h3 className="mt-3 mb-0">{initData.heading}</h3>
                                    <p>{initData.content}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row items">
                            {props.auction.auctions && props.auction.auctions.length > 0 ?
                                props.auction.auctions.map((item, idx) => {
                                    return (
                                        <div className="col-md-6 col-lg-4" key={`auc_${idx}`} id={`#auc-${idx}`}>
                                            <div className="card m-3">
                                                <div className="image-over">
                                                    <Link to={`/item-details?item=${window.btoa(item._id)}&sellingNftId=${item.sellingNftId}`} >
                                                        <img className="card-img-top" src={ENV.webUrl + item.nftImageLink} width="600" height="600" alt="img" />
                                                    </Link>
                                                </div>
                                                <div className="card-caption col-12 p-0">
                                                    <div className="card-body">
                                                        <div className="mb-3">
                                                            <Countdown
                                                                date={Date.parse(item.auctionEndDate) + 10000}
                                                                renderer={countDownRenderer}
                                                            />
                                                        </div>
                                                        <Link to={`/item-details?item=${window.btoa(item._id)}&sellingNftId=${item.sellingNftId}`} >
                                                            <h5 className="mb-0">{item.name}</h5>
                                                        </Link>
                                                        <Link className="seller d-flex align-items-center my-3" to={`/author/${item.owner._id}`} >
                                                            <img className="avatar-sm rounded-circle" src={item.owner.profilePhoto} alt="User Avatar" />
                                                            <span className="ml-2 text-break d-flex">
                                                                {item.owner.username}
                                                                {
                                                                    item.owner && item.owner.verificationCheck ?
                                                                        <>
                                                                            <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                                                                <FontAwesomeIcon icon={faCheck} />
                                                                            </span>
                                                                            <ReactTooltip />
                                                                        </>
                                                                        : ''
                                                                }
                                                            </span>
                                                        </Link>
                                                        <div className="card-bottom d-flex justify-content-between">
                                                            <span>{item.currentPrice} {ENV.currency}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                : <div className="no-data border"><p className="text-center ml-3">No Live Auctions Found</p></div>
                            }
                        </div>
                        {
                            loadMoreBtn &&
                            <div className="row">
                                <div className="col-12 text-center">
                                    <a id="load-btn" className="btn btn-bordered-white mt-5" onClick={() => loadMore()} href>{initData.btn_1}</a>
                                </div>
                            </div>
                        }
                    </div>
            }
        </section>
    );
}

const mapStateToProps = state => ({
    auction: state.auction,
    error: state.error
});

export default connect(mapStateToProps, { beforeAuction, getLiveAuctions })(LiveAuctions);