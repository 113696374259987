// layouts
import layout1 from './layouts/layout1/layout1';
import layout2 from './layouts/layout2/layout2';
import layout4 from './layouts/layout4/layout4';
import layout6 from './layouts/layout6/layout6';
import layout7 from './layouts/layout7/layout7';
import layout8 from './layouts/layout8/layout8';
import layout10 from './layouts/layout10/layout10';

// components
import Template from './components/template/template';
import ExploreAll from './components/explore/explore-all';
import LiveAuctions from './components/auctions/live-auctions-list';
import ItemDetails from './components/item-detail/item-detail';
import Activity from './components/activity/activity';
import Authors from './components/authors-list/authors-list';
import Author from './components/author/author';
import Profile from './components/profile/profile';
import CreateNFT from './components/create-nft/create-nft';
import MyCollections from './components/collections/my-collections';
import AllCollections from './components/collections/all-collections';
import CreateCollection from './components/create-collection/create-collection';
import EidtCollection from './components/create-collection/edit-collection';
import CollectionDetails from './components/collections/collection-details';
import Wallet from './components/wallet/wallet';
import About from './components/about/about';
import SellNFT from './components/nfts/sell-nft';
import NotFound from './components/not-found/not-found';
import PrivacyTerms from './components/privacy-&-terms/privacy-&-terms';
import Investor from './components/home/investor/investor';
import Community from './components/home/community/community';
import Faq from './components/faq/faq'
import ArtistDetails from './components/home/investor/ArtistDetails';
import Gallery from './components/gallery/Gallery'
import Leaderboard from './components/activity/leaderboard';
import viewBidOffer from './components/viewBidOffer/viewBidOffer';

// routes
const routes = [
    { path: '/', access: true, exact: true, title: 'Template', customBg: true, layout: layout4, component: Template },
    { path: '/my-nft-gallery', access: true, exact: true, title: 'Gallery', customBg: true, layout: layout2, component: Gallery },
    { path: '/view-bids-offers', access: true, exact: true, title: 'Gallery', customBg: true, layout: layout2, component: viewBidOffer },
    { path: '/artist-details/:id', access: true, exact: true, title: 'Artist Details', layout: layout1, component: ArtistDetails }, 
    { path: '/explore-all', access: true, exact: true, title: 'Explore', layout: layout7, component: ExploreAll },
    { path: '/community', access: true, exact: true, title: 'Community', layout: layout6, component: Community },
    { path: '/investor', access: true, exact: true, title: 'Investor', layout: layout8, component: Investor },
    { path: '/faqs', access: true, exact: true, title: 'Faq', layout: layout2, component: Faq },
    { path: '/auctions', access: true, exact: true, title: 'Live Auctions', layout: layout2, component: LiveAuctions },
    { path: '/item-details', access: true, exact: true, title: 'Item Details', layout: layout2, component: ItemDetails },
    { path: '/sell-item/:itemId?', access: true, exact: true, title: 'Sell Item', layout: layout2, component: SellNFT },
    { path: '/activity', access: true, exact: true, title: 'Activity', layout: layout2, component: Activity },
    { path: '/activity/leaderboard', access: true, exact: true, title: 'Activity', layout: layout2, component: Leaderboard },
    { path: '/authors', access: true, exact: true, title: 'Authors', layout: layout10, component: Authors },
    { path: '/author/:authorId?', access: true, exact: true, title: 'Author Profile', layout: layout1, component: Author }, 
    { path: '/profile', access: true, exact: true, title: 'Profile', layout: layout1, component: Profile }, 
    { path: '/create', access: true, exact: true, title: 'Create', layout: layout2, component: CreateNFT },
    { path: '/my-collections', access: true, exact: true, title: 'Collections', layout: layout2, component: MyCollections },
    { path: '/collection/create', access: true, exact: true, title: 'Create Collection', layout: layout2, component: CreateCollection },
    { path: '/collections', access: true, exact: true, title: 'Collections', layout: layout2, component: AllCollections },
    { path: '/collection/edit/:id', access: true, exact: true, title: 'Create Collection', layout: layout2, component: EidtCollection },
    { path: '/collection-details/:collectionId?', access: true, exact: true, title: 'Collection Details', layout: layout1, component: CollectionDetails }, 
    { path: '/login', access: true, exact: true, title: 'login', layout: layout2, component: Wallet },
    { path: '/about-us', access: true, exact: true, title: 'About', layout: layout1, component: About }, 
    { path: '/privacy-and-terms', access: true, exact: true, title: 'Privacy & Terms', layout: layout1, component: PrivacyTerms }, 
    { path: '/*', access: true, exact: true, name: 'Not Found', layout: layout2, component: NotFound }
];

export default routes;