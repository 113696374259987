import React, { useEffect } from "react";
import Web3 from "web3";
import { useWeb3React } from '@web3-react/core';
import connectors from './../../components/wallet/connector';
import Footer from "../../components/home/footer/Footer";
import Header from "../../components/home/header/Header";
import Banner from "../../components/home/investor/Banner";
import Scrollup from "../../components/scroll-up/sroll-up";
import Requiry from "../../components/home/contactform/Contact";

const Layout8 = (props) => {
  const { noBanner } = props;
  const { account, activate, library } = useWeb3React();
  useEffect(() => {
    if (localStorage.getItem('connectedWalletName')) {
      window.ethereum.setSelectedProvider(window.ethereum.providers.find(({ isMetaMask }) => isMetaMask))
    }
    const provider = window.localStorage.getItem("provider");
    if (provider) {
      const connector = connectors[provider].provider;
      activate(connector, undefined, true)
        .then(async (res) => {
          const web3Provider = await connector.getProvider();
          const web3 = new Web3(web3Provider);
          window.walletPO = web3
        })
        .catch((error) => {
          console.log(error)
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (account) {
      localStorage.setItem('connectedAddress', account);
    }
  }, [account])

  useEffect(() => {
    if (library) {
      window.library = library;
    }
  }, [library])
  return (
    <div className="main collection">
      <div className="bg-white">
        <Header />
        {!noBanner && <Banner />}
        {props.children}
        <Requiry />
        <Footer />
        <Scrollup />
      </div>
    </div>
  );
}

export default Layout8;
