import React, { useEffect } from 'react';
import { ENV } from './../../config/config';
import { toast } from 'react-toastify';
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const AuthorProfile = (props) => {
    useEffect(() => {

    }, [])

    const copy = () => {
        var copyText = document.getElementById("profile-add-field");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        toast.success("Address Copied To Clipboard.")
    }

    return (
        <div className="card-wrapper">
            <div className="card no-hover text-center">
                <div className="image-over">
                    <img id="nft-image" className="card-img-top" src={props.profileImage ? props.profileImage : ENV.globalPlaceholderImage} alt="User Profile" />
                </div>
                <div className="card-caption col-12 p-0">
                    <div className="card-body mt-4">
                        <h5 className="mb-3 justify-content-center d-flex">{props.username}{props.verificationCheck ?
                            <>
                                <span className="icon-verified d-flex justify-content-center align-items-center ml-2" data-tip={'Verified'}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </span>
                                <ReactTooltip />
                            </>
                            : ''}</h5>
                        <p className="my-3">{props.description}</p>
                        <div className="input-group">
                            <input type="text" className="form-control" id="profile-add-field" placeholder="0x000000000000000000" readOnly value={props.address} />
                            <div className="input-group-append" data-toggle="tooltip" title="Copy">
                                <button onClick={copy}><i className="icon-docs" /></button>
                            </div>
                        </div>
                        <div className="social-icons d-flex justify-content-center my-3">
                            {
                                props.facebookLink && <a className="facebook" href={props.facebookLink} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Facebook">
                                    <i className="fab fa-facebook-f" />
                                </a>
                            }
                            {
                                props.twitterLink && <a className="twitter" href={props.twitterLink} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Twitter">
                                    <i className="fab fa-twitter" />
                                </a>
                            }
                            {
                                props.youTubeLink && <a className="youtube" href={props.youTubeLink} target="_blank" rel="noreferrer" data-toggle="tooltip" title="YouTube">
                                    <i className="fab fa-youtube" />
                                </a>
                            }
                            {
                                props.tiktokLink && <a className="tiktok" href={props.tiktokLink} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Tiktok">
                                    <i className="fab fa-tiktok" />
                                </a>
                            }
                            {
                                props.instagramLink && <a className="instagram" href={props.instagramLink} target="_blank" rel="noreferrer" data-toggle="tooltip" title="Instagram">
                                    <i className="fab fa-instagram" />
                                </a>
                            }
                        </div>
                        {
                            props.followText &&
                            <a className="btn btn-bordered-white btn-smaller" href>{props.followText}</a>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
}
export default AuthorProfile;