import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { beforeAuction, getLiveAuctions } from "./auctions.action";
import FullPageLoader from "../full-page-loader/full-page-loader";
import { ENV } from "../../config/config";
import "slick-carousel";
import "../../assets/slick/slick.min.css";
import "../../assets/slick/slick-theme.min.css";
import $ from "jquery";
import Countdown from "react-countdown";
const { countDownRenderer } = ENV;

const initData = {
  pre_heading: "Auctions",
  heading: "Live Auctions",
  btnText: "View All",
};

function AuctionsOne(props) {
  const [auctions, setAuctions] = useState(null);
  const [loader, setLoader] = useState(true); // auctions loader
  useEffect(() => {
    window.scroll(0, 0);
    // get live auctions
    const qs = ENV.objectToQueryString({ all: true });
    props.getLiveAuctions(qs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // set live auctions
  useEffect(() => {
    if (props.auction.getAuth) {
      const { auctions } = props.auction;
      if (auctions) {
        setAuctions(auctions);
        props.beforeAuction();
        setLoader(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auction.getAuth]);

  // ready slides when auctions data is received
  useEffect(() => {
    if (auctions && auctions.length) {
      $(".swiper-wrapper").slick(ENV.slickSettings);
    }
  }, [auctions]);
  return (
    <section className="live-auctions-area authen-setting">
      {loader ? (
        <FullPageLoader />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Intro */}
              <div className="intro d-flex justify-content-between align-items-end m-0">
                <div className="intro-content">
                  {/* <span>{initData.pre_heading}</span> */}
                  <h3 className="mt-3 mb-3">{initData.heading}</h3>
                </div>
                {auctions && auctions.length > 0 && (
                  <div className="intro-btn">
                    <Link className="btn content-btn" to="/auctions">
                      {initData.btnText}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          {auctions && auctions.length > 0 ? (
            <div className="auctions-slides">
              <div className="swiper-container slider-mid items">
                <div className="swiper-wrapper">
                  {auctions.map((item, idx) => {
                    return (
                      <div
                        key={`auc_${idx}`}
                        className="swiper-slide item"
                        id={`#auc-${idx}`}
                      >
                        <div className="card m-3">
                          <div className="image-over">
                            <a
                              href={`/item-details?item=${window.btoa(item._id)}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}
                            >
                              <img
                                className="card-img-top"
                                src={ENV.webUrl + item.nftImageLink}
                                width="600"
                                height="600"
                                alt="img"
                              />
                            </a>
                          </div>
                          <div className="card-caption col-12 p-0">
                            <div className="card-body">
                              <div className="mb-3">
                                <Countdown
                                  date={Date.parse(item.auctionEndDate) + 10000}
                                  renderer={countDownRenderer}
                                />
                              </div>
                              <a
                                href={`/item-details?item=${window.btoa(
                                  item._id
                                )}${item.sellingNftId ? `&sellingNftId=${item.sellingNftId}` : ''}`}
                              >
                                <div className="item-name-holder">
                                  <h5 className="mb-0">{item.name}</h5>
                                </div>
                              </a>
                              <Link
                                className="seller d-flex align-items-center my-3"
                                to={`/author/${item.owner._id}`}
                              >
                                <img
                                  className="avatar-sm rounded-circle"
                                  src={item.owner.profilePhoto}
                                  alt="User Avatar"
                                />
                                <span className="ml-2 text-break">
                                  {item.owner.username}
                                </span>
                              </Link>
                              <div className="card-bottom d-flex justify-content-between">
                                <span>
                                  {item.currentPrice} {item.currency}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="no-data border">
              <p className="text-center ml-3">No Live Auctions Found</p>
            </div>
          )}
        </div>
      )}
    </section>
  );
}

const mapStateToProps = (state) => ({
  auction: state.auction,
});

export default connect(mapStateToProps, { beforeAuction, getLiveAuctions })(
  AuctionsOne
);
